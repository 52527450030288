<template>
  <v-card-text v-if="editedItem[assetKey]">
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
            :headers="headerAsset"
            :items="editedItem[assetKey]"
            :server-items-length="editedItem[assetKey].length"
            :loading="loadingList"
            :footer-props="{'items-per-page-options': [5, 10, 15]}"
            item-key="_id"
            return-object="false"
            dense
        >
          <template v-slot:item.name="{ item }">
            <td nowrap="true" class="v-list-item--link text-truncate" style="max-width: 150px;">
              <widgets-asset-dialogs :getId="item._id" :caseType="caseType">
                <div>
                  <v-tooltip bottom v-if="item.isLock">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                    </template>
                    <span>设备锁定中</span>
                  </v-tooltip>
                  {{item.name}}
                  <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                    </template>
                    <span>企业自有设备</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                    </template>
                    <span>个人自有设备</span>
                  </v-tooltip>
                </div>
              </widgets-asset-dialogs>
            </td>
          </template>
          <template v-slot:item.serial_number="{ item }">
            <td nowrap="true" v-if="item.product && item.product.product_type && item.product.product_type.noSN">无序列号</td>
            <td nowrap="true" v-else>{{item.serial_number}}</td>
          </template>
          <template v-slot:item.product.name="{ item }">
            <td nowrap="true" @click="openDialog('Product', item.product._id)" class="v-list-item--link text-truncate" style="max-width: 150px;">{{item.product.name}}</td>
          </template>
          <template v-slot:item.product.part_number="{ item }">
            <td nowrap="true">{{item.product.part_number}}</td>
          </template>
          <template v-slot:item.state="{ item }">
            <td nowrap="true">{{item.state ? item.state.name : item.state}}</td>
          </template>
          <template v-slot:item.branch="{ item }">
            <td nowrap="true" class="v-list-item--link text-truncate" style="max-width: 150px;">{{item.branch}}</td>
          </template>
          <template v-slot:item.asset_state="{ item }">
            <td nowrap="true" v-if="item.asset_state && Array.isArray(item.asset_state)">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
<!--            <td nowrap="true" v-else-if="item.asset_state">{{assetState.find(o => o.code === item.asset_state).name}}</td>-->
<!--            <td nowrap="true" v-else>-</td>-->
          </template>
          <template v-slot:item.label="{ item }">
            <td nowrap="true">{{item.label}}</td>
          </template>
          <template v-slot:item.account="{ item }">
            <td nowrap="true">{{item.account ? item.account.personal.name : ''}}</td>
          </template>
          <template v-slot:item.transport.delivery_user="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.delivery_user">
              {{ item.transport.delivery_user }}
            </td>
          </template>
          <template v-slot:item.transport.delivery_phone="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.delivery_phone">
              {{ item.transport.delivery_phone}}
            </td>
          </template>
          <template v-slot:item.transport.delivery_address="{ item, index }">
            <td
                v-if="item.transport && item.transport.delivery_address"
                style="max-width: 150px; cursor: pointer;"
                :nowrap="open[index]?false:true"
                :class="!open[index]?'text-truncate text-decoration-underline':'text-decoration-underline'"
                @click="showText(index)"
            >
              {{ item.transport.delivery_address }}
            </td>
          </template>
          <template v-slot:item.transport.express_number="{ item }">
            <td nowrap="true" v-if="item.transport && item.transport.express_number"><case-express-info :id="item.transport._id" from="asset">{{item.transport.express_number}}</case-express-info></td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="unlinkAsset(item)">remove</v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" align="end">
        <v-btn v-if="caseData.status === 0 && caseData.isEdit" text @click="linkAsset()"><v-icon left>link</v-icon>关联设备</v-btn>
        <v-btn v-else-if="caseData.status === 1 && caseData.isEdit && currentTask.task && currentTask.task.powers.includes('relate_asset')" text @click="linkAsset()"><v-icon left>link</v-icon>关联设备</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgActive" persistent scrollable max-width="600px">
      <widgets-product-dialogs v-if="activeId"  :id="activeId" :key="activeId" v-on:Close="closeActive()"></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgAsset" persistent max-width="750px">
      <widgets-select-asset
        ref="select_asset"
        v-if="caseData.enterprise"
        :enterprise="caseData.enterprise._id"
        :isInWarehouse="isWareHouse"
        :maxLimit="maxLimit"
        key="linkAsset"
        :selectItem="editedItem[assetKey]"
        v-on:Close="closeAsset"
        v-on:Selected="setAssetList"
      ></widgets-select-asset>
    </v-dialog>
  </v-card-text>
</template>
<script>
import store from "@/store";
import {
  FETCH_ASSET, LOCK_ASSET,
  SAVE_CASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    isWareHouse: {
      type: Boolean,
      default: false,
    },
    maxLimit: {
      type: Number,
      default: -1
    },
    caseType: {
      type: String,
      default: 'service'
    },
    assetKey: {
      type: String,
      default: 'link_asset'
    }
  },
  data() {
    return {
      currentTask: {},
      headerAsset: [
        { text: '名称', value: 'name', width: "100px" },
        { text: '序列号', value: 'serial_number', width: "100px" },
        { text: '标签', value: 'label', width: "100px"},
        { text: '状态', value: 'state', width: "100px" },
        { text: '分支机构', value: 'branch', width: "100px" },
        { text: '用户', value: 'account', width: "100px" },
        { text: '产品', value: 'product.name', width: "100px" },
        { text: '产品型号', value: 'product.part_number', width: "100px"},
        { text: '设备情况', value: 'asset_state', width: "100px"},
        { text: '收货人', value: 'transport.delivery_user', width: "100px" },
        { text: '收货电话', value: 'transport.delivery_phone', width: "100px" },
        { text: '收货地址', value: 'transport.delivery_address', width: "150px" },
        { text: '物流信息', value: 'transport.express_number', width: "150px" },
        { text: '操作', value: 'actions', width: "85px" },
      ],
      loadingList: true,
      editedItem: {
        link_asset: [],
        link_asset_new: []
      },
      dlgActive: false,
      activeId: '',
      dlgAsset: false,
      open: {},
    }
  },
  computed: {
    assetState () {
      return Util.categories('assetState')
    },
  },
  mounted() {},
  watch: {
    dlgActive (val) {
      val || this.closeActive()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    showText(i) {
      this.open[i]= true
      this.$forceUpdate()
    },
    initialize() {
      // if (this.caseData[this.assetKey] && this.caseData[this.assetKey].length) {
      this.loadingList = true
      if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
      this.getAssetList(this.caseData[this.assetKey]).then(data => {
        this.editedItem[this.assetKey] = data.concat()
        this.loadingList = false
        this.checkRemoveAsset().then(data => {
          if (data && !this.headerAsset.find(item => item.value === 'actions')) this.headerAsset.push({ text: '取消关联', value: 'actions', sortable: false })
        })
      })

    },
    openActive(openId) {
      this.activeId = openId
      this.dlgActive = true
    },
    closeActive() {
      this.dlgActive = false
      this.$nextTick(() => {
        this.activeId = ''
      })
    },
    getAssetList(ids = []) {
      return Promise.all(ids.map(async (id) => {
        const info = await store.dispatch(FETCH_ASSET, id)
        return info;
      }))
    },
    checkRemoveAsset() {
      return new Promise((resolve) => {
        let hasPower = false
        if (this.caseData.status === 0 && this.caseData.isEdit) {
          hasPower = true
        } else if (this.caseData.isEdit) {
          if (this.currentTask.task && this.currentTask.task.powers.includes('relate_asset')) hasPower = true
        }
        resolve(hasPower)
      })
    },
    async unlinkAsset(asset) {
      let strUpdate = {}
      await this.editedItem[this.assetKey].splice(this.editedItem[this.assetKey].indexOf(asset), 1)
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      strUpdate[this.assetKey] =  this.editedItem[this.assetKey].map(item => item._id)
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          store.dispatch(LOCK_ASSET, {_id: asset._id, reply: false})
        })
        .catch((err) => {
          this.editedItem[this.assetKey].push(asset)
          this.$store.commit(SET_ERROR, {msg: err});
        })
      // await Promise.all([
      //   store.dispatch(SAVE_CASE, strUpdate),
      //   store.dispatch(LOCK_ASSET, {_id: asset._id, reply: false})
      // ]).then(() => {
      //   this.editedItem[this.assetKey].splice(this.editedItem[this.assetKey].indexOf(asset), 1)
      // }).catch((err) => {
      //   this.$store.commit(SET_ERROR, {msg: err});
      // })
    },
    linkAsset() {
      let refsKey = 'select_asset'
      if (this.$refs[refsKey]) this.$refs[refsKey].getAssetList()
      this.dlgAsset = true
    },
    closeAsset() {
      this.dlgAsset = false
    },
    async setAssetList(data) {
      let strUpdate = {}
      strUpdate.form = this.caseType
      strUpdate.caseId = this.caseData._id
      strUpdate[this.assetKey] = data.map(item => item._id)
      strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
      store.dispatch(SAVE_CASE, strUpdate)
        .then(() => {
          Promise.all([
            this.caseData[this.assetKey].map(async (id) => {
              await store.dispatch(LOCK_ASSET, {_id: id, reply: false})
            }),
            strUpdate[this.assetKey].map(async (id) => {
              await store.dispatch(LOCK_ASSET, {_id: id, reply: true})
            })
          ]).then(() => {
            this.editedItem[this.assetKey] = data
            this.checkRemoveAsset().then(data => {
              if (data && !this.headerAsset.find(item => item.value === 'actions')) this.headerAsset.push({ text: '取消关联', value: 'actions', sortable: false })
            })
            if (data.close) {
              this.dlgAsset = false
            }
          })
          // Promise.all(strUpdate[this.assetKey].map(async (id) => {
          //   await store.dispatch(LOCK_ASSET, {_id: id, reply: true})
          // })).then(() => {
          //   this.editedItem[this.assetKey] = data
          //   if (data.close) {
          //     this.dlgAsset = false
          //   }
          // })
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err});
        })
    },
  },
}
</script>
