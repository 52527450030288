<template>
  <v-form v-model="valid" @submit.prevent="editTaskInfo()">
    <v-row v-if="caseData.status === 0">
      <v-col cols="12">
        <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
          <v-subheader>受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。<span class="secondary--text">该服务申请成功受理后，系统将自动创建任务。</span></v-subheader>
        </v-alert>
      </v-col>
      <v-col cols="4" class="text-right">服务团队</v-col>
      <v-col cols="4" class="mt-n2" v-if="caseData.isEdit && caseData.status === 0">
        <v-autocomplete
          v-if="deptList.length"
          v-model="editedItem.deliver_team"
          :items="getDeptList(deptList[0])"
          :rules="[rules.required, rules.selected]"
          item-text="name"
          item-value="value"
          autocomplete="off"
          outlined
          dense
          label="请选择该服务请求的服务团队"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" class="text-right"></v-col>
      <v-col cols="4" class="text-right">服务方式</v-col>
      <v-col cols="4" class="mt-n2" v-if="caseData.isEdit && caseData.status === 0">
        <v-autocomplete
          v-model="editedItem.handover_type"
          :items="handoverType"
          @change="editTaskInfo()"
          clearable
          item-text="name"
          item-value="code"
          autocomplete="off"
          outlined
          dense
          label="请选择该工单服务方式"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" class="text-right"></v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="2">
        <v-list dense>
          <v-list-item-group v-model="selectedTask" color="primary">
            <v-list-item @click="changeTask(-1)">
              <v-list-item-icon>
                <v-icon color="green">check_circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>受理</v-list-item-title>
              <v-list-item-icon><v-icon>keyboard_arrow_right</v-icon></v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <template v-for="(taskinfo, index) in caseData.tasks_list">
              <v-list-item :key="taskinfo._id" @click="changeTask(index)">
                <v-list-item-icon v-if="taskinfo.isActive"><v-icon color="secondary" v-if="caseData.isEdit">build_circle</v-icon><v-icon v-else>hourglass_top</v-icon></v-list-item-icon>
                <v-list-item-icon v-else-if="taskinfo.status === 2"><v-icon color="green">check_circle</v-icon></v-list-item-icon>
                <v-list-item-icon v-else-if="taskinfo.status === 3"><v-icon color="red">highlight_off</v-icon></v-list-item-icon>
                <v-list-item-icon v-else></v-list-item-icon>
                <v-list-item-title>{{ taskinfo.task.name }}</v-list-item-title>
                <v-list-item-icon><v-icon>keyboard_arrow_right</v-icon></v-list-item-icon>
              </v-list-item>
              <v-divider :key="taskinfo._id+'_hr'"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="10" class="mt-n16" v-if="activeList < 0">
        <v-col cols="12">
          <p class="subtitle-1">受理</p>
          <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
            <v-subheader>受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。</v-subheader>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="2" class="text-right">服务团队</v-col>
            <v-col cols="8" class="text--secondary">
              {{caseData.deliver_team ? caseData.deliver_team.name : '未指定'}}
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
            <v-col cols="2" class="text-right">服务方式</v-col>
            <v-col cols="8" class="text--secondary">
              {{caseData.handover_type ? handoverType.find(item => item.code === caseData.handover_type).name : '未指定'}}
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="10" class="mt-n16" v-else>
        <v-col cols="12">
          <p class="subtitle-1">{{activeTask.task.name}}</p>
          <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
            <v-subheader>{{activeTask && activeTask.task.remarks}}</v-subheader>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="activeTask.task.isExpress">
          <v-row align="center">
            <v-col cols="2" class="text-right">收件人电话</v-col>
            <v-col cols="8" class="text--secondary">
              <v-text-field
                v-model="editedItem.express_phone"
                :rules="[rules.required]"
                @change="editTaskInfo()"
                :disabled="!caseData.isEdit || !activeTask.isActive"
                type="text"
                label="请输入收件人手机号码"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
            <v-col cols="2" class="text-right">快递公司</v-col>
            <v-col cols="8" class="text--secondary">
              <v-autocomplete
                v-model="editedItem.express_code"
                :items="expressCorp"
                @change="editTaskInfo()"
                :rules="[rules.required, rules.selected]"
                :disabled="!caseData.isEdit || !activeTask.isActive"
                item-text="name"
                item-value="code"
                autocomplete="off"
                hide-details
                outlined
                dense
                label="请选择该快递承运公司"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
            <v-col cols="2" class="text-right">快递单号</v-col>
            <v-col cols="8" class="text--secondary">
              <v-text-field
                v-model="editedItem.express_number"
                :rules="[rules.required]"
                @change="editTaskInfo()"
                :disabled="!caseData.isEdit || !activeTask.isActive"
                type="text"
                label="请输入快递单号"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
            <v-col cols="10" class="text-right" v-if="activeTask.expressFields">
              <case-express-info :id="activeTask._id" :key="activeTask._id">物流信息</case-express-info>
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="activeTask.task_fields">
          <v-row align="center" v-for="(item, i) in activeTask.task_fields" :key="i+'_task_fields'">
            <v-col cols="2" class="text-right">
              {{item.name}}
            </v-col>
            <v-col cols="8" class="text--secondary" v-if="item.format.type === 'text'">
              <v-text-field
                v-if="item.format.mask"
                v-model="item.value"
                v-mask="item.format.mask"
                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                @change="editTaskInfo()"
                :disabled="!caseData.isEdit || !activeTask.isActive"
                hide-details
                outlined
                dense>
              </v-text-field>
              <v-text-field
                v-else
                v-model="item.value"
                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                @change="editTaskInfo()"
                :disabled="!caseData.isEdit || !activeTask.isActive"
                hide-details
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col cols="8" class="text--secondary" v-if="item.format.type === 'selects'">
              <v-autocomplete
                autocomplete="off"
                v-model="item.value"
                :items="item.format.items"
                :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                @change="editTaskInfo()"
                hide-details
                outlined
                :disabled="!caseData.isEdit || !activeTask.isActive"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-right"></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-col cols="12" v-if="activeTask.task.isWarehouse && activeTask.asset_fields.length">
            <v-row justify="center">
              <v-col cols="12">请对设备进行入库检查，并设置工单完成后该设备状态。</v-col>
              <v-col cols="12">
                <case-edit-asset
                    v-if="caseData.process"
                    :disabled="!caseData.isEdit || !activeTask.isActive"
                    :assetList="caseData.link_asset"
                    :assetField="activeTask.asset_fields"
                    :saveField="activeTask.assetFields"
                    @Update="updateEditAsset">
                </case-edit-asset>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-else-if="activeTask.asset_fields.length">
            <v-row justify="center">
              <v-col cols="12">请确认并更新关联设备信息。</v-col>
              <v-col cols="12" v-if="caseData.link_asset && caseData.link_asset.length <= 30">
                <case-edit-asset
                    v-if="caseData.process"
                    :disabled="!caseData.isEdit || !activeTask.isActive"
                    :assetList="caseData.process.isRecovery ? caseData.link_asset_new : caseData.link_asset"
                    :assetField="activeTask.asset_fields"
                    :saveField="activeTask.assetFields"
                    @Update="updateEditAsset">
                </case-edit-asset>
              </v-col>
              <v-col cols="12" v-else>
                该工单关联设备大于30台，请使用详细信息中关联设备中的批量更新完成设备更新操作。
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import store from "@/store";
import {FETCH_DEPT_LIST, FETCH_FIELDS} from "@/store/actions.type";
import {} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      valid: true,
      deptList: [],
      taskFields: [],
      editedItem: {
        taskId: '',
        deliver_team: '',
        handover_type: '',
        express_code: '',
        express_number: '',
      },
      activeList: -1,
      activeTask: {},
      selectedTask: 0
    }
  },
  computed: {
    ...mapGetters(['currentEmployer']),
    assetFields () {
      return Util.categories('assetFields')
    },
    expressCorp () {
      return Util.categories('expressCorp')
    },
    handoverType() {
      return Util.categories('handoverType')
    }
  },
  mounted() {},
  watch: {
    valid () {
      this.editTaskInfo()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      // 待受理：获取部门清单
      // 处理中：获取任务列表 获取每个任务字段（表单、资产）
      let ownerId = this.currentEmployer.ownerId._id;
      if (this.currentEmployer.ownerType === 'Enterprise') ownerId = this.currentEmployer.ownerId.account_dealer


      const [deptData, taskFields] = await Promise.all([
        store.dispatch(FETCH_DEPT_LIST, 'owner='+ ownerId),
        store.dispatch(FETCH_FIELDS, ownerId + '/Task'),
        this.editedItem.express_code = this.caseData.express_code,
        this.editedItem.express_number = this.caseData.express_number,
      ])


      if (deptData && deptData.length) {
        this.deptList = await deptData.concat()
        this.$forceUpdate()
      }
      if (taskFields && taskFields.length) {
        this.taskFields = await taskFields.concat()
        this.$forceUpdate()
      }

      if (this.caseData.current_task) {
        this.selectedTask = this.caseData.tasks_list.findIndex(item => item._id === this.caseData.current_task._id) + 1
        await this.changeTask(this.caseData.tasks_list.findIndex(item => item._id === this.caseData.current_task._id))
        this.$forceUpdate()
      }

      if (this.editedItem.express_code || this.editedItem.express_number) {
        this.editTaskInfo()
      }

      this.editTaskInfo()

    },
    async changeTask(index) {
      this.activeList = index
      this.activeTask = Object.assign({}, this.caseData.tasks_list[index])
      this.activeTask.task_fields = []
      this.activeTask.asset_fields = []
      this.editedItem.taskId = this.activeTask._id
      if (index >= 0) {
        const [task_fields, asset_fields] = await Promise.all([
          makeTaskFields(this.taskFields, this.activeTask.task.fields, this.activeTask.customFields),
          makeAssetFields(this.assetFields, this.activeTask.task.asset_fields)
        ])
        this.activeTask.task_fields = task_fields
        this.activeTask.asset_fields = asset_fields

        if (this.activeTask.task && this.activeTask.task.isExpress) {
          let initValue = this.caseData.order_id ? this.caseData.express_name : this.caseData.account.personal.name
          this.editedItem.express_name = this.activeTask.expressFields && this.activeTask.expressFields.contact_name
            ? this.activeTask.expressFields.contact_name
            : initValue
          this.editedItem.express_phone = this.activeTask.expressFields && this.activeTask.expressFields.contact_phone
            ? this.activeTask.expressFields.contact_phone
            : ''
          this.editedItem.express_address = this.activeTask.expressFields && this.activeTask.expressFields.express_address
            ? this.activeTask.expressFields.express_address
            : this.caseData.create_address
          initValue = this.activeTask.expressFields && this.activeTask.expressFields.corp_code || this.caseData.express_code
          this.editedItem.express_code = initValue
          initValue = this.activeTask.expressFields && this.activeTask.expressFields.express_number || this.caseData.express_number
          this.editedItem.express_number = initValue
        }

        this.$forceUpdate()
      }
    },
    editTaskInfo(assetFields = []) {
      let taskInfo = ''
      if (this.activeTask.task_fields) {
        taskInfo = this.activeTask.task_fields.map(item => {
          return {
            _id: item._id,
            value: item.value
          }
        })
      }
      this.$emit('Update', Object.assign({ valid: this.valid }, Object.assign(this.editedItem, { customFields: taskInfo, assetFields })))
    },
    updateEditAsset(info) {
      this.editTaskInfo(info)
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
  },
}
function makeTaskFields(allFields = [], taskFields = [], savedFields = []) {
  return new Promise((resolve, reject) => {
    let arrFields = []
    if (!taskFields) reject([])
    Promise.all(taskFields.map(async field => {
      let objField = allFields.find(o => o._id === field)
      let strValue = savedFields.find(o => o._id === field)
      strValue = strValue ? strValue.value : ''

      await arrFields.push(Object.assign(objField, { value: strValue}))
    }))
    resolve(arrFields)
  })
}
function makeAssetFields(allFields = [], assetFields = []) {
  return new Promise((resolve) => {
    let arrFields = []
    Promise.all(assetFields.map(async field => {
      let objField = allFields.find(o => o.field === field)
      await arrFields.push(objField)
    }))
    resolve(arrFields)
  })
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
