import Vue from 'vue'
import router from './router/index.js'
import store from '@/store';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import global from '@/common/global';
import ApiService from '@/common/http';
import VueMask from 'vue-the-mask'
import '@/common/filters';
import './components'
import './router/auth';
import '@/common/css/account.css';

Vue.use(VueMask);

Vue.config.productionTip = false

Vue.prototype.ossURL = process.env.VUE_APP_OSS_URL || 'localhost:9000';
Vue.prototype.Global = global;

ApiService.init();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
