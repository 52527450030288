<template>
  <v-container fluid no-gutters class="px-16">
    <v-sheet :color="editBodyData.bg_color || 'transparent'">
      <v-card-title class="text-center justify-center py-6">
        <h1
          :class="'font-weight-bold display-1 '+ editBodyData.color +'--text'"
        >{{editBodyData.title || '服务内容'}}</h1>
      </v-card-title>
      <v-tabs
        v-if="serviceList && serviceList.length"
        background-color="transparent"
        icons-and-text
        :color="editBodyData.tab_color || 'primary'"
        grow
      >
        <v-tab v-for="item in editBodyData.services" :key="item">
          {{serviceList.find(service => service._id === item).name}}
          <v-icon>{{serviceList.find(service => service._id === item).icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="(item,i) in editBodyData.services" :key="i">
          <v-card flat :color="editBodyData.bg_color || 'transparent'">
            <v-card-text class="text-center">
              <pre width="50%">{{serviceList.find(service => service._id === item).remarks}}</pre>
              <v-btn text class="ma-6" @click="goToEvent(serviceList.find(service => service._id === item).material, item, serviceList.find(service => service._id === item).serviceType)" v-if="serviceList.find(service => service._id === item).material">了解详情 >></v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-sheet>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { FETCH_PAGE, FETCH_CATALOG_LIST } from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      valid: true,
      editBodyData: {
        services: []
      },
      serviceList: [],
      titleColor: ["primary", "secondary", "white", "transparent"],
      tabColor: ["primary", "secondary"],
      bgColor: ["transparent", "white", "#f5f5f5", "primary", "secondary"]
    };
  },
  computed: {
    ...mapGetters(["currentUser", "catalogList"])
  },
  created() {
    this.getPageInfo();
  },
  methods: {
    getPageInfo(component = "body") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          this.$store.dispatch(FETCH_CATALOG_LIST).then(({ catalog }) => {
            this.serviceList = this.flatGroupCatalog(catalog);
          });
          this.editBodyData = Object.assign(
            { _id: data._id },
            data.config[this.index]
          );
          if (!this.editBodyData.services) this.editBodyData.services = [];
          if (!this.editBodyData.type) this.editBodyData.type = "service";
        });
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = [];
      arrGroup.forEach(groupItem => {
        groupItem.children.forEach(catalogItem => {
          arrRoles.push({
            _id: catalogItem._id,
            name: catalogItem.name,
            icon: catalogItem.profileIcon,
            remarks: catalogItem.remarks,
            group: groupItem.name,
            material: catalogItem.material,
            serviceType: groupItem.service_type
          });
        });
      });
      return arrRoles;
    },
    goToEvent(m, item, type) {
      if (m) {
        this.$router.push({ path: '/event/' + m, query: {service: item, type: type}})
      }else {''
        return
      }
    },
  }
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-dialog__content {
  z-index: 203 !important;
}
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
