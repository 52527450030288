<template>
  <v-container fluid no-gutters class="px-16">
    <v-row>
      <v-col cols="3">
            <v-card flat @click="goToMaterial(0)">
              <v-img
                v-if="editBodyData.cover"
                :src="editBodyData.cover[0] ? ossURL + '/'+ editBodyData.cover[0] : '/static/error/empty_state.svg'"
                height="150"
              ></v-img>
              <v-card-title
                v-if="editBodyData.title_color"
                :class="'headline '+ editBodyData.title_color[0] +'--text justify-center'"
                v-text="editBodyData.title[0] || '输入标题文字'"
              ></v-card-title>
              <v-card-subtitle v-if="editBodyData.context">
                <pre class="grey--text mt-4 text-center" v-html="editBodyData.context[0] || '文字区域'"></pre>
              </v-card-subtitle>
            </v-card>
      </v-col>
      <v-col cols="3">
            <v-card flat @click="goToMaterial(1)">
              <v-img
                v-if="editBodyData.cover"
                :src="editBodyData.cover[1] ? ossURL + '/'+ editBodyData.cover[1] : '/static/error/empty_state.svg'"
                height="150"
              ></v-img>
              <v-card-title
                v-if="editBodyData.title_color"
                :class="'headline '+ editBodyData.title_color[1] +'--text justify-center'"
                v-text="editBodyData.title[1] || '输入标题文字'"
              ></v-card-title>
              <v-card-subtitle v-if="editBodyData.context">
                <pre class="grey--text mt-4 text-center" v-html="editBodyData.context[1] || '文字区域'"></pre>
              </v-card-subtitle>
            </v-card>
      </v-col>
      <v-col cols="3">
            <v-card flat @click="goToMaterial(2)">
              <v-img
                v-if="editBodyData.cover"
                :src="editBodyData.cover[2] ? ossURL + '/'+ editBodyData.cover[2] : '/static/error/empty_state.svg'"
                height="150"
              ></v-img>
              <v-card-title
                v-if="editBodyData.title_color"
                :class="'headline '+ editBodyData.title_color[2] +'--text justify-center'"
                v-text="editBodyData.title[2] || '输入标题文字'"
              ></v-card-title>
              <v-card-subtitle v-if="editBodyData.context">
                <pre class="grey--text mt-4 text-center" v-html="editBodyData.context[2] || '文字区域'"></pre>
              </v-card-subtitle>
            </v-card>
      </v-col>
      <v-col cols="3">
            <v-card flat @click="goToMaterial(3)">
              <v-img
                v-if="editBodyData.cover"
                :src="editBodyData.cover[3] ? ossURL + '/'+ editBodyData.cover[3] : '/static/error/empty_state.svg'"
                height="150"
              ></v-img>
              <v-card-title
                v-if="editBodyData.title_color"
                :class="'headline '+ editBodyData.title_color[3] +'--text justify-center'"
                v-text="editBodyData.title[3] || '输入标题文字'"
              ></v-card-title>
              <v-card-subtitle v-if="editBodyData.context">
                <pre class="grey--text mt-4 text-center" v-html="editBodyData.context[3] || '文字区域'"></pre>
              </v-card-subtitle>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_PAGE,
  EDIT_BODY_ELEMENT,
  DELETE_BODY_ELEMENT,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      dlgMaterial: false,
      valid: true,
      editBodyData: {},
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fontColor: ['red', 'secondary', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey', 'primary', 'white'],
      editorInit: {
        language_url: '/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/MDass',
        content_css: '/tinymce/skins/content/MDass/content.css',
        height: '100%',
        branding: false,
        menubar: false,
        statusbar: false,
        link_quicklink: true,
        // inline: true,
        toolbar: false,
        plugins: [
          'lists',
          'link',
          'quickbars'
        ],
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }",
        font_formats:
          "Roboto=roboto;",
        quickbars_insert_toolbar: 'formatselect forecolor',
        quickbars_selection_toolbar: 'bold italic underline strikethrough | fontselect formatselect forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | quicklink unlink ｜ removeformat',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      },
      activeEdit: false,
      editIndex: -1,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign', 'materialList']),
  },
  watch: {
    dlgSetting (val) {
      val || this.close()
    },
  },
  created() {
    this.getPageInfo()
  },
  mounted () {
    this.activeEdit = true
  },
  methods: {
    goToMaterial(n) {
      if (this.editBodyData.material && this.editBodyData.material.length) {
        if (this.editBodyData.material[n]) this.$router.push('/event/'+this.editBodyData.material[n])
      }else {
        return
      }
    },
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component)
        .then(data => {
          // this.$store.dispatch(FETCH_MATERIAL_LIST)
          this.editBodyData = Object.assign({_id: data._id, cover: [], title_color: [], title: [], context: [], material: [] }, data.config[this.index])
          if (!this.editBodyData.type) this.editBodyData.type = 'fourcard'
        });
    },
    close() {
      this.dlgSetting = false
      this.getPageInfo('body')
    },
    editItem(editIndex) {
      this.editIndex = editIndex
      this.dlgSetting = true
    },
    choiceMaterial() {
      this.dlgMaterial = true
    },
    confirmMaterial() {
      if (this.editBodyData.material[this.editIndex]) {
        this.dlgMaterial = false
        console.log(this.editBodyData.material[this.editIndex].profile_image)
        this.editBodyData.cover[this.editIndex] = this.editBodyData.material[this.editIndex].profile_image
        this.editBodyData.title[this.editIndex] = this.editBodyData.material[this.editIndex].title
        this.$forceUpdate()
        this.submit(false)
      }
    },
    deleteItem() {
      this.$store.dispatch(DELETE_BODY_ELEMENT, {_id: this.id, index: this.index})
        .then(() => {
          this.$emit('DeleteItem')
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    uploadImage() {
      this.$store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editBodyData.cover[this.editIndex] = this.currentEmployer.ownerId._id +'/page/home/'+ this.uploadSign.key;
          this.$forceUpdate()
          this.submit(false)
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submit(isClose = true) {
      if (this.editBodyData.material) {
        this.editBodyData.material = this.editBodyData.material.map(item => {
          if (item) {
            return item._id
          } else {
            return null
          }
        })
      }
      this.$store.dispatch(EDIT_BODY_ELEMENT, {_id: this.id, index: this.index, config: [this.editBodyData]})
        .then(() => {
          this.$forceUpdate()
          this.$emit('UpdateItem')
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          if (isClose) this.close();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-dialog__content {z-index: 203 !important;}
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
