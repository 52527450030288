<template>
  <div>
    <v-card-text>
      <v-list dense>
        <template v-for="(item, index) in remarks">
          <v-list-item :key="index+'_remarks_title'" three-line>
            <widgets-profile-dialogs :account="item.account">
              <v-list-item-avatar>
                <v-img :src="item.avatar ? ossURL +'/'+ item.avatar : '/static/grey_silhouette.png'"></v-img>
              </v-list-item-avatar>
            </widgets-profile-dialogs>
            <v-list-item-content>
              <v-list-item-title>{{item.title}} {{ item.createTime | fromDate }} {{item.private ? ' - 仅个人可见' : ''}}</v-list-item-title>
              <v-list-item-subtitle>{{item.context}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon @click="removeRemark(item)">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index+'_remarks_divider'" inset></v-divider>
        </template>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title align="end"><v-btn text @click="createRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-dialog v-model="dlgRemarks" persistent max-width="550px">
      <v-card>
        <v-card-title>
          增加备注
          <v-spacer></v-spacer>
          <v-btn icon @click="closeRemarks"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-switch v-model="editedRemark.private" :label="`仅个人可见: ${editedRemark.private ? '是' : '否'}`" @change="changeRemarkPublic()"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedRemark.context"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemarks()">取消</v-btn>
          <v-btn text color="secondary" :disabled="!editedRemark.context" @click="addRemarks(editedRemark)">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {},
  data() {
    return {
      remarks: [],
      dlgRemarks: false,
      editedRemark: {
        title: '',
        context: '',
        private: false,
        public: true
      },
      defaultRemark: {
        title: '',
        context: '',
        private: false,
        public: true
      }
    }
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    createRemarks() {
      this.dlgRemarks = true
    },
    closeRemarks() {
      this.dlgRemarks = false
      this.$nextTick(() => {
        this.editedRemark = Object.assign({}, this.defaultRemark)
      })
    },
    removeRemark(item) {
      this.remarks.splice(this.remarks.indexOf(item), 2)
    },
    addRemarks(objRemarks) {
      objRemarks['account'] = this.currentUser._id
      objRemarks['avatar'] = this.currentUser.personal.profile || ''
      objRemarks['createTime'] = new Date()
      this.remarks.push(Object.assign({}, objRemarks))
      this.closeRemarks()
    },
    changeRemarkPrivate() {
      if (this.editedRemark.public) this.editedRemark.private = false;
    },
    changeRemarkPublic() {
      if (this.editedRemark.private) this.editedRemark.public = false;
    }
  }
}
</script>
