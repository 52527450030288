<template>
  <v-card-text>
    <v-list dense>
      <template v-for="(file, i) in filesList">
        <v-list-item :key="file.href">
          <v-list-item-avatar>
            <v-icon>text_snippet</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="file.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="d-inline">
            <v-btn icon><v-icon color="grey lighten-1" @click="removeFile(i)">delete</v-icon></v-btn>
            <v-btn icon><v-icon color="grey lighten-1" @click="downloadFiles(file.href)">get_app</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="i+'_files_divider'" inset></v-divider>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
          <input type="file" ref="uploadFile" :multiple="false" @change="detectFiles($event)" />
          <v-list-item-title align="end"><v-btn text @click="uploadFile()"><v-icon left>attach_file</v-icon>上传附件</v-btn></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>
<script>
import store from "@/store";
import {
  FETCH_UPLOAD, FETCH_URL,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  props: {},
  data() {
    return {
      filesList: [],
      Upload: {
        uploading: false,
        progressUpload: 0,
      }
    }
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "uploadSign",
    ]),
  },
  methods: {
    uploadFile() {
      store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadFile.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    removeFile(index) {
      this.filesList.splice(index, 1)
    },
    downloadFiles(key) {
      store.dispatch(FETCH_URL, key)
        .then((fileurl) => {
          window.location.href = fileurl;
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    async upload(file) {
      let fileFullName = file.name,
        fileSuffix = fileFullName.substring(fileFullName.lastIndexOf('.') + 1);
      this.Upload.uploading = true
      let param = new FormData()
      param.append('name', fileFullName);
      param.append('key', 'case/'+ this.uploadSign.key +'/'+ fileFullName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, fileFullName);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadFile.value = '';
          await this.filesList.push({
            suffix: fileSuffix,
            name: fileFullName,
            href: param.get('key'),
            creator: this.currentUser._id,
            createTime: new Date()
          })
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
