var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 my-4",attrs:{"headers":_vm.caseHeaders,"items":_vm.orderData.case_list,"items-per-page":50,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.case_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"v-list-item--link",on:{"click":function($event){return _vm.goToCase(item.case_number)}}},[_vm._v(_vm._s(item.case_number))])]}},{key:"item.current_task_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status === 0 ? "订单处理" : item.current_task_id ? item.current_task_id.name : "无" || "无"))])]}},{key:"item.ordered_quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_count ? item.product_count : "0")+" ")]}},{key:"item.link_asset",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.link_asset.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[(item.status === 0)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("待受理")]):(item.status === 1)?_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v("处理中")]):(item.status === 2)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("已取消")]):(item.status === 3)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("已取消")]):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("已完成")])],1)]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.updateTime))+" ")]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.createTime))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportAsset(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("save_alt")])],1)]}}],null,true)},[_c('span',[_vm._v("导出设备")])])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.orderData.no_order_text ? _vm.orderData.no_order_text : "无工单数据")+" "),(_vm.isEdit)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.readyNoData}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }