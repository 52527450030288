<template>
  <v-form v-model="valid" @submit.prevent="editTaskInfo()">
    <v-row v-if="caseData.status === 0">
      <v-col cols="12">
        <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
          <v-subheader>受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。<span class="secondary--text">该服务申请成功受理后，系统将自动创建任务。</span></v-subheader>
        </v-alert>
      </v-col>
      <v-col cols="2" class="text-right">服务团队</v-col>
      <v-col cols="4" class="mt-n2" v-if="caseData.isEdit && caseData.status === 0">
        <v-autocomplete
          v-if="deptList.length"
          v-model="editedItem.deliver_team"
          :items="getDeptList(deptList[0])"
          :rules="[rules.required, rules.selected]"
          item-text="name"
          item-value="value"
          autocomplete="off"
          outlined
          dense
          label="请选择该服务请求的服务团队"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="2">
        <v-list dense>
          <v-list-item @click="changeTask(-1)">
            <v-list-item-icon>
              <v-icon color="green">check_circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>受理</v-list-item-title>
            <v-list-item-icon><v-icon>keyboard_arrow_right</v-icon></v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <template v-for="(taskinfo, index) in caseData.tasks_list">
            <v-list-item :key="taskinfo._id" @click="changeTask(index)">
              <v-list-item-icon v-if="taskinfo.isActive"><v-icon color="secondary" v-if="caseData.isEdit">build_circle</v-icon><v-icon v-else>hourglass_top</v-icon></v-list-item-icon>
              <v-list-item-icon v-else-if="taskinfo.status > 1"><v-icon color="green">check_circle</v-icon></v-list-item-icon>
              <v-list-item-icon v-else></v-list-item-icon>
              <v-list-item-title>{{ taskinfo.task.name }}</v-list-item-title>
              <v-list-item-icon><v-icon>keyboard_arrow_right</v-icon></v-list-item-icon>
            </v-list-item>
            <v-divider :key="taskinfo._id+'_hr'"></v-divider>
          </template>
        </v-list>
      </v-col>
      <v-col cols="10" class="mt-n16" v-if="activeTask < 0">
        <v-row>
          <v-col cols="12">
            <p class="subtitle-1">受理</p>
            <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
              <v-subheader>受理环节需要确认用户信息、设备信息，并指定服务团队，服务团队可以为内部团队或服务商。</v-subheader>
            </v-alert>
          </v-col>
          <v-col cols="2" class="text-right">服务团队</v-col>
          <v-col cols="4" class="text--secondary">
            {{caseData.deliver_team ? caseData.deliver_team.name : '未指定'}}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" class="mt-n16" v-else>
        <v-row v-if="caseData.tasks_list[activeTask].isActive">
          <v-col cols="12">
            <p class="subtitle-1">{{caseData.tasks_list[activeTask].task.name}}</p>
            <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
              <v-subheader>{{caseData.tasks_list[activeTask] && caseData.tasks_list[activeTask].task.remarks}}</v-subheader>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="caseData.isEdit">
            {{taskField}}
            <v-row align="center" v-for="(item, i) in taskField" :key="i+'_task_fields'">
              <v-col cols="2" class="text-right">
                <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
              </v-col>
              <v-col cols="8" class="text-left" v-if="item.format.type === 'text'">
                <v-text-field
                  class="my-n4"
                  v-if="item.format.mask"
                  v-model="item.value"
                  v-mask="item.format.mask"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  :label="item.name"
                  outlined
                  dense>
                </v-text-field>
                <v-text-field
                  v-else
                  class="my-n4"
                  v-model="item.value"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="8" v-if="item.format.type === 'selects'">
                <v-autocomplete
                  class="my-n4"
                  autocomplete="off"
                  v-model="item.value"
                  :items="item.format.items"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right"></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center" v-if="assetField">
              <v-col cols="12">
<!--                <widgets-asset-edit ref="widgetsAssetEdit" :assetList="editedItem.link_asset" :assetField="assetField"></widgets-asset-edit>-->
              </v-col>
            </v-row>

<!--                          {{assetField}}-->
<!--                          <v-row align="center" v-for="(item, i) in assetField" :key="i+'_asset_fields'">-->
<!--                            <v-col cols="2" class="text-right">{{ item.name }}</v-col>-->
<!--                            <v-col cols="4" class="text--secondary">-->

<!--                            </v-col>-->
<!--                          </v-row>-->
          </v-col>
          <v-col cols="12" v-else class="text--secondary">
            <v-icon class="ma-2">hourglass_top</v-icon>任务处理中...
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <p class="subtitle-1">{{caseData.tasks_list[activeTask].task.name}}</p>
            <v-alert dense color="grey lighten-3" class="ma-2" dismissible>
              <v-subheader>完成前置任务后，该任务将可进行处理。</v-subheader>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import store from "@/store";
import {FETCH_DEPT_LIST, FETCH_FIELDS} from "@/store/actions.type";
import {} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      valid: true,
      deptList: [],
      editedItem: {
        deliver_team: '',
      },
      activeTask: -1,
      taskField: [],
      assetField: [],
    }
  },
  computed: {
    ...mapGetters(['currentEmployer']),
    assetFields () {
      return Util.categories('assetFields')
    },
  },
  mounted() {},
  watch: {
    valid () {
      this.editTaskInfo()
    },
    taskField () {
      this.editTaskInfo()
    },
    assetField () {
      this.editTaskInfo()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      let ownerId = this.currentEmployer.ownerId._id;
      if (this.currentEmployer.ownerType === 'Enterprise') ownerId = this.currentEmployer.ownerId.account_dealer

      const [deptData, fieldsData] = await Promise.all([
        store.dispatch(FETCH_DEPT_LIST, 'owner='+ ownerId),
        store.dispatch(FETCH_FIELDS, ownerId + '/Task')
      ])

      this.deptList = deptData.concat()


      if (this.caseData.tasks_list && this.caseData.tasks_list.length) {
        this.activeTask = this.caseData.tasks_list.findIndex(item => item.isActive)

        if (fieldsData && fieldsData.length) {
          if (this.caseData.tasks_list[this.activeTask].task && this.caseData.tasks_list[this.activeTask].task.fields.length) {
            this.taskField = this.caseData.tasks_list[this.activeTask].task.fields.map(field => fieldsData.find(d => d._id === field))
          }
        }
        if (this.caseData.tasks_list[this.activeTask].task && this.caseData.tasks_list[this.activeTask].task.asset_fields.length) {
          this.assetField = this.caseData.tasks_list[this.activeTask].task.asset_fields.map(item => this.assetFields.find(o => o.field === item))
        }
      }
    },
    changeTask (index) {
      this.activeTask = index
    },
    editTaskInfo() {
      this.$emit('Update', Object.assign({ valid: this.valid }, Object.assign(this.editedItem, { taskField: this.taskField, assetField: this.assetField})))
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
