<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_item')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card v-if="reportData" :loading="reportLoading ? 'secondary' : false">
        <v-card-title>
          详细报告
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels v-model="openPanel" multiple accordion flat hover>
            <v-expansion-panel>
              <v-expansion-panel-header>统计图表</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div  class="d-flex flex-column justify-space-between align-center">
                  <v-img :src="require('@/assets/report/0.png')" contain max-width="750"></v-img>
                  <span><h4>图示分析：</h4>待维修设备共计440台：其中air2：200台、iPhone6s：10台，iPhone6sp：2台，iPhone7:15台，iPhone8:4台，共计231台暂不维修；
                    iPad7:8台、iPad6：108台、iPad5:93台，共计209台本月暂不安排维修；待报废设备共计264台：
                    其中激活锁设备29台：iPadAir1:5台、iPadAir2:14台、iPad5:6台、iPad6:4台；
                  </span>
                </div>
                <v-divider class="mx-2"></v-divider>
                <div  class="d-flex flex-column justify-space-between align-center">
                  <v-img :src="require('@/assets/report/1.png')" contain max-width="750"></v-img>
                </div>
                <v-divider class="mx-2"></v-divider>
                <div  class="d-flex flex-column justify-space-between align-center">
                  <v-img :src="require('@/assets/report/2.png')" contain max-width="750"></v-img>
                </div>
                <v-divider class="mx-2"></v-divider>
                <div  class="d-flex flex-column justify-space-between align-center">
                  <v-img :src="require('@/assets/report/3.png')" contain max-width="750"></v-img>
                  <span><h4>图示分析：</h4>设备发放、回收单量较前三个月呈现下降趋势；更换、激活单量较前三个月略有上升；</span>
                </div>
                <v-divider class="mx-2"></v-divider>
                <div  class="d-flex flex-column justify-space-between align-center">
                  <v-img :src="require('@/assets/report/4.png')" contain max-width="750"></v-img>
                  <span><h4>图示分析：</h4>现场发放1小时以上：14单12单由于服务器加载慢问题，注册后无法推出VPN和邮箱且拜助理、CRM等软件下载缓慢；
                    1单由于用户CWID密码未提前更改导致；1单由于工程师操作流程不熟练导致-湖北荆州；远程发放1小时以上：3单由于服务器问题，注册后无法推出VPN和邮箱导致；
                  </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>解决方案</v-expansion-panel-header>
              <v-expansion-panel-content>
                <span>目前存在10%的用户设备在注册成功后，Hub软件里的最终界面还是老版的，非新版的“应用程序”“通知”“support”三个选项卡的界面；</span>
                <h4>目前未有无法使用等相关问题发生，跟进中；</h4>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      reportData: {},
      openPanel: [],
      reportLoading: true,
    }
  },
  computed: {},
  mounted() { },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    closeDialog () {
      this.dialog = false
      this.$nextTick(() => {
        this.reportData = Object.assign({}, {})
        this.openPanel = []
      })
    },
    getReport(id) {
      console.log('get report =>'+ id)
      let data = {}
      this.reportData = Object.assign({}, data)

      this.openPanel.push(0)
      this.openPanel.push(1)

      this.reportLoading = false
    },
    doAction(action) {
      switch (action) {
        case 'open_item': {
          this.getReport(this.getId)
          this.dialog = true
          break
        }
      }
    },
  }
}
</script>