<template>
  <div>
    <v-card-actions v-if="caseData.isEdit">
      <v-subheader>更新时间：{{ caseData.updateTime | formatTime }} </v-subheader>
      <v-subheader v-if="caseData.status === 0 && caseData.accept_sla">
        当前环节：<widgets-baseTimer :endTime="caseData.accept_sla.expectTime" :alertThreshold="caseData.accept_sla.sla_limit / 2" :warningThreshold="caseData.accept_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-subheader v-if="caseData.total_sla">
        整体流程：<widgets-baseTimer :endTime="caseData.total_sla.expectTime" :alertThreshold="caseData.total_sla.sla_limit / 2" :warningThreshold="caseData.total_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-spacer></v-spacer>
      <v-btn text v-if="caseData.out_req_type === '保内送修' || caseData.out_req_type === '三包服务' || caseData.out_req_type === '保内维修'" :disabled="caseData.status <= 0 || caseData.status >= 3 || caseData.confirm_price" @click="doAction('confirm', caseData._id)"><v-icon left>check</v-icon>确认维修</v-btn>
      <v-btn text v-if="caseData.out_req_type === '保外送修' || caseData.out_req_type === '保外维修'" :disabled="caseData.status <= 0 || caseData.status >= 3 || caseData.confirm_price || !caseData.repair_price" @click="doAction('confirm', caseData._id)"><v-icon left>check</v-icon>确认维修</v-btn>
      <v-btn text v-if="caseData.status < 2" @click="doAction('cancel', caseData._id)"><v-icon left>cancel</v-icon>取消维修</v-btn>
      <v-btn text v-if="caseData.status === 0 || caseData.status === 3" color="secondary" @click="doAction('submit', caseData._id)"><v-icon left>check_circle</v-icon>提交</v-btn>
      <widgets-service-report :caseData="caseData">
        <v-btn v-if="caseData.status > 0" text><v-icon left>assignment</v-icon>服务报告</v-btn>
      </widgets-service-report>
      <v-btn text @click="closeDialog()">关闭</v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-subheader>更新时间：{{ caseData.updateTime | formatTime }}</v-subheader>
      <v-subheader>维修用时：{{durationTime(caseData.createTime, caseData.repaired_time)}}</v-subheader>
      <v-subheader v-if="caseData.status === 0 && caseData.accept_sla">
        当前环节：<widgets-baseTimer :endTime="caseData.accept_sla.expectTime" :alertThreshold="caseData.accept_sla.sla_limit / 2" :warningThreshold="caseData.accept_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-subheader v-if="caseData.total_sla">
        整体流程：<widgets-baseTimer :endTime="caseData.total_sla.expectTime" :alertThreshold="caseData.total_sla.sla_limit / 2" :warningThreshold="caseData.total_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-spacer></v-spacer>
      <v-btn v-if="caseData.status < 2" text @click="doAction('cancel', caseData._id)"><v-icon left>cancel</v-icon>取消维修</v-btn>
      <v-btn text v-if="caseData.out_req_type === '保内送修' || caseData.out_req_type === '三包服务' || caseData.out_req_type === '保内维修'" :disabled="caseData.status <= 0 || caseData.status >= 3 || caseData.confirm_price || (currentUser._id !== caseData.creator._id && !currentUser.hasAdmin.includes('enterprise'))" @click="doAction('confirm', caseData._id)"><v-icon left>check</v-icon>确认维修</v-btn>
      <v-btn text v-if="caseData.out_req_type === '保外送修' || caseData.out_req_type === '保外维修'" :disabled="caseData.status <= 0 || caseData.status >= 3 || caseData.confirm_price || !caseData.repair_price || (currentUser._id !== caseData.creator._id && !currentUser.hasAdmin.includes('enterprise'))" @click="doAction('confirm', caseData._id)"><v-icon left>check</v-icon>确认维修</v-btn>
      <v-btn text v-if="caseData.status === 3" @click="doAction('complete', caseData._id)"><v-icon left>check</v-icon>确认完成</v-btn>
      <widgets-service-report :caseData="caseData">
        <v-btn v-if="caseData.status > 0" text><v-icon left>assignment</v-icon>服务报告</v-btn>
      </widgets-service-report>
      <v-btn text @click="closeDialog()">关闭</v-btn>
    </v-card-actions>
    <v-dialog v-model="dlgAction" persistent max-width="400px">
      <v-card>
        <v-card-title v-if="actionTitle !== '确认维修'">确认<span class="font-weight-medium">{{ actionTitle }}</span>该服务请求？</v-card-title>
        <v-card-title v-else style="width: 100%;">
          <div style="width: 100%;" class="text-center">本次服务费用 RMB <span class="secondary--text">{{ caseData.repair_price || '0' }}</span> 元</div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-4" v-if="actionTitle === '确认维修'">
            <div class="text-center black--text mb-4">请确认维修价格，是否同意进行维修</div>
            <div v-if="caseData.out_req_type === '保外送修' || caseData.out_req_type === '保外维修'">&lowast;如您是<span class="text-decoration-underline">个人付款</span>，请联系经销商，获取付款途径。</div>
            <div class="mb-4" v-if="caseData.out_req_type === '保外送修' || caseData.out_req_type === '保外维修'">&lowast;如果是<span class="text-decoration-underline">企业付款</span>，请联系企业IT管理人员。</div>
            <div class="caption">
              <v-icon small color="warning">info</v-icon>
              请务必在来之前备份 Mac 上的数据，以确保数据安全无虑。
              要检修您的 Mac，需要暂时关闭“查找我的 Mac”。要关闭“查找我的 Mac”，您必须知道自己的 Apple ID 和密码*。
            </div>
          </div>
          {{actionLabel}}
<!--          <v-textarea-->
<!--            v-if="caseData.accept_sla && (new Date() > new Date(caseData.accept_sla.expectTime))"-->
<!--            outlined-->
<!--            v-model="action_remarks"-->
<!--            class="mt-4"-->
<!--            label="超时原因说明"-->
<!--          ></v-textarea>-->
          <div class="text-center" v-if="actionTitle === '完成'">
            <v-rating
                v-model="satisfaction.rating"
                background-color="secondary lighten-3"
                color="secondary"
                hover
            ></v-rating>
            <v-textarea
                outlined
                v-model="satisfaction.remarks"
                class="mt-4"
                label="服务建议"
                rows="3"
            ></v-textarea>
          </div>

        </v-card-text>
        <v-divider v-if="actionTitle === '确认维修'"></v-divider>
        <v-card-actions>
          <v-spacer v-if="actionTitle !== '确认维修'"></v-spacer>
          <v-btn text @click="doAction('cancel_action')">取消</v-btn>
          <v-spacer v-if="actionTitle === '确认维修'"></v-spacer>
          <v-btn text color="secondary" @click="doAction(actionKey, actionID)">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import moment from 'moment'
import {CANCEL_CASE, SUBMIT_CASE} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    editedTask: {
      type: Object,
    }
  },
  data() {
    return {
      dlgAction: false,
      actionKey: '',
      actionTitle: '',
      actionID: '',
      actionLabel: '',
      action_remarks: '',
      satisfaction: {
        rating: 0,
        remarks: ''
      }
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    initialize() {
    },
    closeDialog() {
      this.$emit('Close')
    },
    doAction(action, caseId) {
      switch (action) {
        case 'cancel': {
          this.dlgAction = true;
          this.actionTitle = '取消';
          this.actionLabel = '取消后的服务请求将被关闭，不能再次执行。同时释放该服务请求关联的资产设备。';
          this.actionID = caseId
          this.actionKey = 'goto_cancel'
          break
        }
        case 'cancel_action': {
          this.dlgAction = false;
          break
        }
        case 'goto_cancel': {
          store.dispatch(CANCEL_CASE, {form: 'repair', caseId: this.actionID, remarks: this.action_remarks})
            .then(() => {
              store.commit(SET_ERROR, {msg: '工单已被取消', color: 'primary'});
              this.dlgAction = false;
              this.$emit('Update', this.actionID)
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'complete': {
          this.dlgAction = true
          this.actionTitle = '完成'
          this.actionLabel = '请检查设备是否已经完成维修，并填写服务满意度。'
          this.actionID = caseId
          this.actionKey = 'goto_complete'
          break
        }
        case 'goto_complete': {
          let strUpdate = {}
          strUpdate.form = 'repair'
          strUpdate.caseId = this.caseData._id
          strUpdate.satisfaction = this.satisfaction
          strUpdate.status = 4
          store.dispatch(SUBMIT_CASE, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
              this.dlgAction = false;
              this.closeDialog();
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'confirm': {
          this.dlgAction = true
          this.actionTitle = '确认维修'
          this.actionID = caseId
          this.actionKey = 'goto_confirm'
          break
        }
        case 'goto_confirm': {
          let strUpdate = {}
          strUpdate.form = 'repair'
          strUpdate.caseId = this.caseData._id
          strUpdate.confirm_price = true
          strUpdate.status = this.caseData.status
          store.dispatch(SUBMIT_CASE, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
              this.dlgAction = false;
              this.closeDialog();
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'submit': {
          this.dlgAction = true;
          this.actionTitle = '提交';
          this.actionLabel = '提交后的服务请求将进入下一环节处理。';
          this.actionID = caseId
          this.actionKey = 'goto_submit'
          break
        }
        case 'goto_submit': {
          let strUpdate = {}
          strUpdate.form = 'repair'
          strUpdate.caseId = this.caseData._id
          strUpdate.remark = this.action_remarks
          strUpdate.status = this.caseData.status
          if (this.editedTask.out_req_id) strUpdate.out_req_id = this.editedTask.out_req_id
          if (this.editedTask.repair_price) strUpdate.repair_price = this.editedTask.repair_price
          if (this.editedTask.express_corp) strUpdate.express_corp = this.editedTask.express_corp
          if (this.editedTask.express_number) strUpdate.express_number = this.editedTask.express_number
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.verdict) strUpdate.diagnosisVerdict = this.editedTask.diagnosis.verdict
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.state) strUpdate.diagnosisState = this.editedTask.diagnosis.state
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.class) strUpdate.diagnosisClass = this.editedTask.diagnosis.class
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.reason) strUpdate.diagnosisReason = this.editedTask.diagnosis.reason
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.repair) strUpdate.diagnosisRepair = this.editedTask.diagnosis.repair
          if (this.editedTask.diagnosis && this.editedTask.diagnosis.report) strUpdate.diagnosisReport = this.editedTask.diagnosis.report
          store.dispatch(SUBMIT_CASE, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
              this.dlgAction = false;
              this.closeDialog();
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
      }
    },
    durationTime(startTime, endTime = Date.now()) {
      let duration = moment.duration(moment(endTime).diff(moment(startTime)))
      return duration.humanize()
    },
  }
}
</script>
