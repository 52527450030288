import { EnterpriseService } from "@/common/http";
import {
  FETCH_SERVICE_LIST,
  PUBLISH_SERVICE,
  EDIT_SERVICE,
  REMOVE_SERVICE,
} from "./actions.type";
import {
  SET_ERROR,
  SET_SERVICE_LIST,
} from "./mutations.type"

const state = {
  serviceList: [],
};

const getters = {
  serviceList: state => state.serviceList,
};

const actions = {
  [PUBLISH_SERVICE](context, service) {
    return new Promise((resolve, reject) => {
      EnterpriseService.create('/service/'+ service.enterprise, service)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
  [FETCH_SERVICE_LIST]({ commit }, eid) {
    return new Promise((resolve, reject) => {
      EnterpriseService.get('/service', eid)
          .then(({data}) => {
            commit(SET_SERVICE_LIST, data);
            resolve(data);
          })
          .catch(error => {
            commit(SET_ERROR, {msg: error});
            reject(error);
          })
    })
  },
  [EDIT_SERVICE](context, service) {
    return new Promise((resolve, reject) => {
      EnterpriseService.update('service/'+ service._id, service)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
  [REMOVE_SERVICE](context, service) {
    return new Promise((resolve, reject) => {
      EnterpriseService.destroy('service/'+ service._id)
        .then(({data}) => {
          resolve(data)
        })
        .catch(({response}) => {
          reject(response)
        })
    })
  }
};

const mutations = {
  [SET_SERVICE_LIST](state, list) {
    state.serviceList = list;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
