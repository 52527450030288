<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-form v-model="valid" @submit.prevent="submitCase()">
        <v-card v-if="caseData && caseData.group && caseData.creator && caseData.enterprise" :loading="caseLoading ? 'secondary' : false">
          <v-card-title>
            {{caseData.group ? caseData.group.name +'-' : ''}}{{caseData.catalog ? caseData.catalog.name : ''}}: {{caseData.case_number}}
            <v-chip class="ma-2" label small v-if="caseData.status === 0" color="secondary">待受理</v-chip>
            <v-chip class="ma-2" label small v-else-if="caseData.status === 1" color="primary">已回复</v-chip>
            <v-chip class="ma-2" label small v-else-if="caseData.status === 2">已解决</v-chip>
            <v-chip class="ma-2" label small v-else-if="caseData.status === 3">已解决</v-chip>
            <v-chip class="ma-2" label small v-else-if="caseData.status === 4">已解决</v-chip>
            <v-chip class="ma-2" label small v-else-if="caseData.status === 8">已删除</v-chip>
            <v-chip class="ma-2" label small v-else>已删除</v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-card class="mx-auto" outlined max-width="1280">
                <v-card-title>
                  <div class="subtitle-1 ml-7">{{caseData.group.name}} {{caseData.catalog ? ' - '+ caseData.catalog.name : ''}}</div>
                  <v-spacer></v-spacer>
                  <widgets-profile-dialogs :account="caseData.creator._id">
                    <v-list-item two-line v-if="caseData.creator">
                      <v-list-item-avatar v-if="caseData.creator.personal" size="48">
                        <img :src="caseData.creator.personal.profile ? ossURL +'/'+ caseData.creator.personal.profile : '/static/grey_silhouette.png'">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ caseData.creator.personal.name ? caseData.creator.personal.name : caseData.creator.username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ caseData.enterprise ? caseData.enterprise.name : '' }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </widgets-profile-dialogs>
                </v-card-title>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-avatar size="48" class="mr-0"><v-icon large>help</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h4">{{ caseData.subject }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <pre class="ml-16 body-1">{{caseData.context}}</pre>
                  <v-row justify="center">
                    <v-col cols="8">
                      <v-list-item v-for="(file) in caseData.files" :key="file.href">
                        <v-list-item-avatar>
                          <v-avatar v-if="file.suffix === 'jpg' || file.suffix === 'jpeg' || file.suffix === 'png' || file.suffix === 'gif'">
                            <v-img :src="fileImgSrc[file._id]"></v-img>
                          </v-avatar>
                          <v-icon v-else>text_snippet</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="file.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="d-inline">
                          <v-btn icon v-if="file.suffix === 'jpg' || file.suffix === 'jpeg' || file.suffix === 'png' || file.suffix === 'gif'"><v-icon color="grey lighten-1" @click="checkImg(file.href)">search</v-icon></v-btn>
                          <v-btn icon><v-icon color="grey lighten-1" @click="downloadFile(file.href)">get_app</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="caption">
                    咨询时间：{{ caseData.createTime | formatTime }}
                  </div>
                </v-card-actions>
              </v-card>
              <div v-for="reply in caseData.reply" :key="reply._id">
                <v-card class="mx-auto my-4" max-width="1280" :key="reply._id" elevation="0">
                  <v-card-text>
                    <v-row>
                      <v-col v-if="reply.form !== 'pump'" cols="1">
                        <widgets-profile-dialogs :account="reply.author._id">
                          <v-list-item v-if="reply.author">
                            <v-list-item-avatar v-if="reply.author.personal" size="48">
                              <img :src="reply.author.personal.profile ? ossURL +'/'+ reply.author.personal.profile : '/static/grey_silhouette.png'">
                            </v-list-item-avatar>
                            <!-- <v-list-item-title>{{ reply.author.personal.name ? reply.author.personal.name : reply.author.username }}</v-list-item-title> -->
                          </v-list-item>
                        </widgets-profile-dialogs>
                      </v-col>
                      <v-col cols="1" v-if="reply.form === 'pump'"></v-col>
                      <v-col cols="9">
                        <v-card :dark="reply.form !== 'pump'">
                          <v-card-text>
                            <div class="mx-6 mb-2 caption"> 
                              {{reply.form === 'reply' ? '回复' : '追问'}}时间：{{ reply.replyTime | formatTime }}
                            </div>
                            <pre class="body-1"><v-icon class="ml-6 mr-2">{{reply.form === 'reply' ? 'wb_incandescent' : 'help'}}</v-icon>{{reply.context}}</pre>
                              <v-row justify="center">
                                <v-col cols="8">
                                  <v-list-item v-for="(file) in reply.attach" :key="file.href">
                                    <v-list-item-avatar>
                                      <v-avatar v-if="isImg(file.name)">
                                        <v-img :src="fileImgSrc[file._id]"></v-img>
                                      </v-avatar>
                                      <v-icon v-else>{{file.form === 'Knowledge' ? 'school' : 'text_snippet'}}</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="file.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="d-inline">
                                      <v-btn icon v-if="isImg(file.name)"><v-icon color="grey lighten-1" @click="checkImg(file.href)">search</v-icon></v-btn>
                                      <v-btn icon v-if="file.form === 'File'"><v-icon color="grey lighten-1" @click="downloadFile(file.href)">get_app</v-icon></v-btn>
                                      <span class="v-list-item--link" v-else @click="$router.push('/detail/' + file.href)">
                                        <v-btn icon><v-icon color="grey lighten-1">launch</v-icon></v-btn>
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="1" v-if="reply.form !== 'pump'"></v-col>
                      <v-col v-if="reply.form === 'pump'" cols="1">
                        <widgets-profile-dialogs :account="reply.author._id">
                          <v-list-item v-if="reply.author">
                            <v-list-item-avatar v-if="reply.author.personal" size="48">
                              <img :src="reply.author.personal.profile ? ossURL +'/'+ reply.author.personal.profile : '/static/grey_silhouette.png'">
                            </v-list-item-avatar>
                            <!-- <v-list-item-title>{{ reply.author.personal.name ? reply.author.personal.name : reply.author.username }}</v-list-item-title> -->
                          </v-list-item>
                        </widgets-profile-dialogs>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn text color="secondary" v-if="(currentUser.hasAdmin.includes('enterprise') || checkCreatePower(caseData.group.editor, currentUser)) && caseData.status < 4" @click="reply('reply', caseData._id, '回复咨询内容')">回复</v-btn> -->
            <v-btn text color="secondary" v-if="caseData.creator._id === currentUser._id && caseData.status < 4" @click="reply('pump', caseData._id, '追加补充咨询内容')">追问</v-btn>
            <v-btn text v-if="caseData.creator._id === currentUser._id && caseData.status < 4" @click="resolvedCase(caseData._id)">已解决</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog
        v-model="imgDialog"
        width="800px"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="imgDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-img width="100%" :src="imgSrc"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-dialog v-model="dlgReply" persistent max-width="500px">
      <v-card>
        <v-card-title>{{dlgTitle}}</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editedItem.context"
            :rules="[rules.required]"
            outlined
            label="内容"
            hint="请输入详细内容"
          ></v-textarea>
          <v-autocomplete
            v-model="editedItem.knowledge"
            :items="knowledgeList"
            v-if="editedItem.form === 'reply'"
            item-text="title"
            item-value="_id"
            autocomplete="off"
            return-object
            label="请选择所含知识"
            outlined
            dense
            chips
            small-chips
            deletable-chips
            multiple
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-action>
                  <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.title"></v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.editor.personal ? data.item.editor.personal.name : data.item.editor.username }}创建于{{data.item.createTime | formatDate}} | 字数：{{data.item.word_count}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <case-create-upload-files v-if="caseData.enterprise" ref="upload_files" :enterprise="caseData.enterprise._id"></case-create-upload-files>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeReply">取消</v-btn>
          <v-btn text @click="submitReply">{{editedItem.form === 'reply' ? '回复' : '追问'}}</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </div>
</template>
<script>
import {
  FETCH_CASE, FETCH_KNOWLEDGE_LIST, FETCH_URL, PUBLISH_CASE_REPLY, RESOLVED_CASE
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      caseData: {},
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      editedItem: {
        id: '',
        context: '',
        knowledge: [],
        link_case_number: '',
        files: ''
      },
      defaultItem: {
        id: '',
        context: '',
        knowledge: [],
        link_case_number: '',
        files: ''
      },
      valid: true,
      dlgReply: false,
      dlgTitle: '',
      knowledgeList: [],
      imgDialog: false,
      imgSrc: '',
      fileImgSrc: {},
    }
  },
  computed: {
    ...mapGetters(['currentEmployer', 'currentUser', 'caseLoading']),
  },
  mounted() { },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    checkImg(additional) {
      store.dispatch(FETCH_URL, additional)
        .then((fileurl) => {
          this.imgSrc = fileurl;
          this.imgDialog = true
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    closeDialog () {
      this.dialog = false
      this.$emit('Close')
      this.$nextTick(() => {
        this.caseData = Object.assign({}, {})
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeReply () {
      this.dlgReply = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    updateDialog() {
      this.getCase(this.getId)
    },
    getCase(id) {
      store.dispatch(FETCH_CASE, {form: 'consult', query: id})
        .then((data) => {
          this.caseData = Object.assign({}, data)
          this.getFileList(this.caseData)
        })
    },
    async getFileList(d) {
      let arr = []
      if (d.files.length) {
        d.files.forEach( item => {
          if (item.suffix === 'jpg' || item.suffix === 'jpeg' || item.suffix === 'png' || item.suffix === 'gif') {
            arr.push(item)
          }
        })
      }
      if (d.reply.length) {
        d.reply.forEach( r => {
          r.attach.forEach( a => {
            if (a.form === 'File') {
              if (this.isImg(a.name)) arr.push(a)
            }
          })
        })
      }
      makeSrcList(arr).then( res => {
        this.fileImgSrc = res
      })
    },
    isTypeImg(ext) {
      return ['png', 'jpg', 'jpeg', 'gif'].
      indexOf(ext.toLowerCase()) !== -1;
    },
    isImg(test_str) {
      var index = test_str.lastIndexOf('.')
      var str = test_str.substr(index + 1)
      return this.isTypeImg(str)
    },
    doAction(action) {
      switch (action) {
        case 'open_case': {
          this.getCase(this.getId)
          this.dialog = true
          break
        }
      }
    },
    reply(form = '', caseId, title = '') {
      store.dispatch(FETCH_KNOWLEDGE_LIST, 'page=1&limit=100&group='+ this.caseData.group._id)
        .then((data) => {
          this.knowledgeList = data.knowledge
          this.editedItem.form = form
          this.editedItem.id = caseId
          this.dlgTitle = title
          this.dlgReply = true
        })

    },
    checkCreatePower(editor = [], account = {}) {
      let power = false
      let account_depts = account.employer.department.map(item => item._id)
      if (account_depts.includes(editor)) power = true
      return power
    },
    submitReply() {
      this.editedItem.files = this.$refs.upload_files ? this.$refs.upload_files.filesList : []

      let objReply = {}
      objReply.id = this.editedItem.id
      objReply.form = this.editedItem.form
      objReply.context = this.editedItem.context
      objReply.attach = this.editedItem.files.map(item => {
        return {
          form: 'File',
          name: item.name,
          href: item.href
        }
      }).concat(this.editedItem.knowledge.map(item => {
        return {
          form: 'Knowledge',
          name: item.title,
          href: item
        }
      }))

      store.dispatch(PUBLISH_CASE_REPLY, {form: 'consult', data: objReply})
        .then(() => {
          store.commit(SET_ERROR, {msg: '回复成功', color: 'primary'});
          this.updateDialog()
          this.closeReply()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    resolvedCase(caseId) {
      store.dispatch(RESOLVED_CASE, {form: 'consult', caseId})
        .then(() => {
          store.commit(SET_ERROR, {msg: '咨询已标记解决完成。', color: 'primary'});
          this.updateDialog()
          this.closeReply()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })

    },
    async getFileUrl(additional) {
      let url = await this.getRealFileUrl(additional)
      return url
    },
    getRealFileUrl(additional) {
      return new Promise(resolve => {
        store.dispatch(FETCH_URL, additional).then(res => {
          resolve(res)
        }) 
      })
    },
    downloadFile(additional) {
      store.dispatch(FETCH_URL, additional)
        .then((fileurl) => {
          window.location.href = fileurl;
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    submitCase() {
      console.log('editedItem =>'+ JSON.stringify(this.editedItem))
    }
  }
}
function makeSrcList(list) {
  return new Promise((resolve) => {
    let obj = {}
    Promise.all(list.map( async (item) => {
      await store.dispatch(FETCH_URL, item.href).then(res => {
        obj[item._id] = res
      }) 
    }))
    resolve(obj)
  })
}
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
