<template>
  <v-data-table
    :headers="caseHeaders"
    :items="orderData.case_list"
    :items-per-page="50"
    class="elevation-0 my-4"
    hide-default-footer
    dense
  >
    <template v-slot:item.case_number="{ item }">
      <span class="v-list-item--link" @click="goToCase(item.case_number)">{{
        item.case_number
      }}</span>
    </template>
    <template v-slot:item.current_task_id="{ item }">
      <span>{{
        item.status === 0
          ? "订单处理"
          : item.current_task_id
          ? item.current_task_id.name
          : "无" || "无"
      }}</span>
    </template>
    <template v-slot:item.ordered_quantity="{ item }">
      {{ item.product_count ? item.product_count : "0" }}
    </template>
    <template v-slot:item.link_asset="{ item }">
      {{ item.link_asset.length }}
    </template>
    <template v-slot:item.status="{ item }">
      <td nowrap="true">
        <v-chip label small v-if="item.status === 0">待受理</v-chip>
        <v-chip label small v-else-if="item.status === 1" color="primary"
          >处理中</v-chip
        >
        <v-chip label small v-else-if="item.status === 2">已取消</v-chip>
        <v-chip label small v-else-if="item.status === 3">已取消</v-chip>
        <v-chip label small v-else>已完成</v-chip>
      </td>
    </template>
    <template v-slot:item.updateTime="{ item }">
      {{ item.updateTime | formatTime }}
    </template>
    <template v-slot:item.createTime="{ item }">
      {{ item.createTime | formatTime }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            @click.stop="exportAsset(item)"
          >
            <v-icon small>save_alt</v-icon>
          </v-btn>
        </template>
        <span>导出设备</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      {{ orderData.no_order_text ? orderData.no_order_text : "无工单数据" }}
      <v-btn
        v-if="isEdit"
        icon
        small
        @click="readyNoData"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "@/common/http";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      caseHeaders: [
        { text: "工单编号", value: "case_number" },
        { text: "当前任务", value: "current_task_id" },
        { text: "开单设备数量", value: "product_count" },
        { text: "已出库数量", value: "link_asset" },
        { text: "状态", value: "status" },
        { text: "更新时间", value: "updateTime" },
        { text: "创建时间", value: "createTime" },
        { text: "操作", value: "actions", sortable: false },
      ],
    }
  },
  methods: {
    readyNoData() {
      this.$emit('setNoData')
    },
    exportAsset(caseInfo) {
      ApiService.download('/asset/export?page=1&limit=1000&enterprise='+ this.orderData.enterprise._id +'&caseId='+ caseInfo._id, {
        responseType:'blob'
      })
        .then(resp => {
          let fileName = caseInfo.case_number +'_资产设备清单.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          store.commit(SET_ERROR, {msg: err});
        });
    },
    goToCase(num) {
      let newWin = this.$router.resolve({
        path: "/workbench/equip?key=" + num,
      });
      window.open(newWin.href, "_blank");
    },
  },
}
</script>
