import { MaterialService } from "@/common/http";
import {
  FETCH_MATERIAL_LIST,
  FETCH_MATERIAL,
} from "./actions.type";
import {
  SET_MATERIAL_LIST,
  SET_MATERIAL,
  SET_ERROR
} from "./mutations.type"

const state = {
  material: {},
};

const getters = {
  material: state => state.material,
};

const actions = {
  [FETCH_MATERIAL_LIST]({ commit }, slug) {
    // commit(LOAD_MATERIALS);
    return new Promise((resolve, reject) => {
      MaterialService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_MATERIAL_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_MATERIAL]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      MaterialService.get('', slug)
        .then(({ data }) => {
          commit(SET_MATERIAL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_MATERIAL_LIST](state, list) {
    state.materialList = list.material;
    state.materialCount = list.query.count;
    state.materialLoading = false;
  },
  [SET_MATERIAL](state, material) {
    state.material = material;
    state.materialLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
