import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#424242',
        secondary: '#d41746',
        accent: '#9b0020',
        error: '#EF5350',
        info: '#000000',
        success: '#424242',
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans',
  },
});

Vue.use(Vuetify);

Vue.use({
  vuetify
});

export default vuetify;
