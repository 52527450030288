const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const kebab =  (str) => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

const toggleFullScreen = () => {
  let doc = window.document;
  let docEl = doc.documentElement;

  let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  }
  else {
    cancelFullScreen.call(doc);
  }
};

// ["2020/2/26", "2020/2/27", "2020/2/28", "2020/2/29", "2020/3/1", "2020/3/2", "2020/3/3"]
const getWeekTime = () => {
  return [...new Array(7)].map((j,i)=> new Date(Date.now()+i*8.64e7).toLocaleDateString())
};
//
// isType([], 'Array') // true
// isType(/\d/, 'RegExp') // true
// isType(new Date(), 'Date') // true
// isType(function(){}, 'Function') // true
// isType(Symbol(1), 'Symbol') // true
/**
 * @param {any} target
 * @param {string} type
 * @return {boolean}
 */
const isType = (target, type) => {
  let targetType = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
  return targetType === type.toLowerCase()
};

// let data = {
//   id: 1,
//   title: 'xxx',
//   comment: []}omit(data, ['id']) // {title: 'xxx', comment: []}
/**
 * @param {object} object
 * @param {string[]} props
 * @return {object}
 */
const omit = (object, props=[]) => {
  let res = {}
  Object.keys(object).forEach(key=>{
    if(props.includes(key) === false){
      res[key] = typeof object[key] === 'object' && object[key] !== null ?
        JSON.parse(JSON.stringify(object[key])):
        object[key]
    }})
  return res
};

const categories = (target) => {
  let objTarget = {}, dataTime = new Date()
  switch (target) {
    case 'permission':
      objTarget = [
        { title: '工作台 > 设备服务', name: 'Service_Edit', icon: 'assignment' },
        { title: '工作台 > 维修服务', name: 'Maintain_Edit', icon: 'assignment' },
        { title: '工作台 > 咨询服务', name: 'Advisory_Edit', icon: 'assignment' },
        { title: '报表 > 库存', name: 'Report_Stock', icon: 'poll' },
        { title: '报表 > 服务水平', name: 'Report_SLA', icon: 'poll'},
        { title: '报表 > 租赁', name: 'Report_Lease', icon: 'poll' },
        { title: '企业管理', name: 'Enterprise_Edit', icon: 'apartment' },
        { title: '企业员工', name: 'Enterprise_Employee', icon: 'supervisor_account' },
        { title: '设备管理', name: 'Asset_Manage', icon: 'devices' },
        { title: '租赁管理', name: 'Lease', icon: 'cast_connected' },
        { title: '网站首页', name: 'CMS_Page', icon: 'line_style' },
        { title: '素材管理', name: 'CMS_Material', icon: 'layers' },
        { title: '流程管理 > 流程配置', name: 'System_Workflow', icon: 'settings' },
        { title: '系统设置 > 组织权限', name: 'System_Organize', icon: 'settings' },
        { title: '系统设置 > 分支机构', name: 'System_Branch', icon: 'settings' },
        { title: '系统设置 > 服务目录', name: 'System_Catalog', icon: 'settings' },
        { title: '系统设置 > 知识目录', name: 'System_Knowledge', icon: 'settings' },
        { title: '系统设置 > 设备管理', name: 'System_Asset', icon: 'settings' },
        { title: '系统设置 > 操作日志', name: 'System_Logs', icon: 'settings' },
        { title: '接口集成 > 维修工单', name: 'API_Repair', icon: 'api' },
        // { title: '页面测试', name: 'test', icon: 'settings' }
      ]
      break
    case 'deliver':
      objTarget = [
        { name: "服务门店", code: 1 },
        { name: "内部团队", code: 2 },
        { name: "下属单位", code: 3 },
        { name: "外包团队", code: 4 },
      ]
      break
    case 'corpType':
      objTarget = [
        { name: "民营", code: 1 },
        { name: "外资", code: 2 },
        { name: "合资", code: 3 },
        { name: "事业单位", code: 4 },
      ]
      break
    case 'SLAExpiry': {
      objTarget = [
        { name: '已过期', code: dataTime.setDate(dataTime.getDate()) },
        { name: '2小时内', code: dataTime.setTime(dataTime.getTime() + (2 * 3600 * 1000)) },
        { name: '8小时内', code: dataTime.setTime(dataTime.getTime() + (8 * 3600 * 1000)) },
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() + 1) },
        { name: '2天内', code: dataTime.setDate(dataTime.getDate() + 2) },
      ]
      break
    }
    case 'expiry': {
      objTarget = [
        { name: '已过期', code: dataTime.setDate(dataTime.getDate()) },
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() + 1) },
        { name: '1周内', code: dataTime.setDate(dataTime.getDate() + 7) },
        { name: '2周内', code: dataTime.setDate(dataTime.getDate() + 14) },
        { name: '1个月内', code: dataTime.setDate(dataTime.getDate() + 30) },
      ]
      break
    }
    case 'updateTime': {
      objTarget = [
        { name: '1天内', code: dataTime.setDate(dataTime.getDate() - 1) },
        { name: '1周内', code: dataTime.setDate(dataTime.getDate() - 7) },
        { name: '2周内', code: dataTime.setDate(dataTime.getDate() - 14) },
        { name: '1个月内', code: dataTime.setDate(dataTime.getDate() - 30) },
      ]
      break
    }
    case 'inputRules':
      objTarget = [
        { name: '必须填写', code: 'required' },
        { name: '必须选择', code: 'selected' },
        { name: '电子邮件', code: 'email' },
        { name: '手机号码', code: 'phonenum' },
      ]
      break
    case 'materialType':
      objTarget = [
        { name: '文章', code: 1 },
        // { name: '图片', code: 2 },
        // { name: '音乐', code: 3 },
        // { name: '视频', code: 4 },
        { name: '外部链接', code: 5 },
      ]
      break
    case 'action':
      objTarget = [
        { name: '注册', code: 'Register' },
        { name: '登录', code: 'Login' },
        { name: '注销', code: 'Logout' },
        // { name: '搜索', code: 'Search' },
        // { name: '获取', code: 'Read' },
        { name: '创建', code: 'Create' },
        { name: '编辑', code: 'Update' },
        { name: '批量', code: 'Batch' },
        { name: '删除', code: 'Delete' },
      ]
      break
    case 'objType':
      objTarget = [
        { name: '账户', code: 'Account' },
        { name: '经销商', code: 'Dealer' },
        { name: '企业', code: 'Enterprise' },
        { name: '部门', code: 'Department' },
        { name: '自定义字段', code: 'Field' },
        { name: '角色分组', code: 'Group' },
        { name: '角色', code: 'Roles' },
        { name: '分支机构', code: 'Branch' },
        { name: '服务目录', code: 'Catalog' },
        { name: '服务协议', code: 'Service' },
        { name: '素材', code: 'Material' },
        { name: '仓库', code: 'Warehouse' },
        { name: '供应商', code: 'Vendor' },
        { name: '产品分类', code: 'ProductType' },
        { name: '产品', code: 'Product' },
      ]
      break
    case 'ramSize':
      objTarget = [
        { name: '无', code: 0 },
        { name: '4G', code: 4 },
        { name: '8G', code: 8 },
        { name: '16G', code: 16 },
        { name: '32G', code: 32 },
        { name: '64G', code: 64 },
        { name: '128G', code: 128 },
        { name: '256G', code: 256 },
        { name: '512G', code: 512 },
        { name: '1T', code: 1024 },
        { name: '2T', code: 2048 },
      ]
      break
    case 'networkModel':
      objTarget = [
        { name: '蜂窝网络', code: '4G' },
        { name: '无线局域网', code: 'WIFI' },
      ]
      break
    case 'assetType':
      objTarget = [
        { name: '服务部署', code: 1 },
        { name: '礼品赠送', code: 2 },
      ]
      break
    case 'assetState':
      objTarget = [
        { name: '良好', code: 1 },
        { name: '损坏', code: 2 },
        { name: '磕碰', code: 3 },
        { name: '屏幕划痕', code: 4 },
        { name: '碎屏', code: 5 },
        { name: '花屏', code: 6 },
        { name: '机身变形', code: 7 },
        { name: '按键失灵', code: 8 },
        { name: '无法开机', code: 9 },
        { name: '私拆', code: 91 },
        { name: '无法激活', code: 92 },
        { name: '激活锁', code: 93 },
        { name: '浸液', code: 94 },
        { name: '其他功能性故障', code: 95 },
      ]
      break
    case 'guaranteedType':
      objTarget = [
        { name: 'Apple Care', code: 1 },
        { name: '倍升保险', code: 2 },
        { name: '保外', code: 3 },
        { name: '其它', code: 4 },
      ]
      break
    case 'taskRelation':
      objTarget = [
        { name: '串行', code: 'Sequence', text: '所有任务依次进行，一个任务完成后触发后续任务。' },
        // { name: '并行', code: 'Synchronization', text: '所有任务同时进行，全部完成后进入结束环节。' },
        // { name: '完成其一', code: 'Choice', text: '完成任务清单中任何一项后进入结束环节。' },
      ]
      break
    case 'serviceType':
      objTarget = [
        { name: '设备管理服务', code: 'asset_service', tip: '设备管理服务是ESP核心服务，企业与经销商签订服务协议后可以选择使用具体的服务流程。'},
        { name: '设备维修服务', code: 'asset_repair', tip: '设备维修服务是技术部门为企业提供远程及进厂维修更换备件的服务。'},
        { name: '问题咨询服务', code: 'consulting_service', tip: '问题咨询服务是一个远程服务，由企业方人员发起问题咨询，经销商方进行解答的过程。'},
        { name: '租赁服务', code: 'hire_service', tip: '租赁服务是一种协助企业快速完成办公条件的服务，可以降低企业运营成本提高工作效率。'},
      ]
      break
    case 'expressCorp':
      objTarget = [
        { name: '顺丰速运', code: 'shunfeng' },
        { name: '圆通速递', code: 'yuantong' },
        { name: '韵达快递', code: 'yunda' },
        { name: '中通快递', code: 'zhongtong' },
        { name: 'EMS', code: 'ems' },
        { name: '邮政快递包裹', code: 'youzhengguonei' },
        { name: '邮政标准快递', code: 'youzhengbk' },
        { name: '京东物流', code: 'jd' },
        { name: '申通快递', code: 'shentong' },
        { name: '百世快递', code: 'huitongkuaidi' },
        { name: '极兔速递', code: 'jtexpress' },
      ]
      break
    case 'expressRepair':
      objTarget = [
        { name: 'OSC 送回', code: 'osc' },
        { name: '顺丰速运', code: 'shunfeng' },
        { name: 'EMS', code: 'ems' },
        { name: '自提', code: 'self' },
      ]
      break
    case 'handoverType':
      objTarget = [
        { name: '快递', code: 'express'},
        { name: '上门', code: 'onsite'},
        { name: '网点', code: 'store'},
      ]
      break
    case 'recoveryType':
      objTarget = [
        { name: '送修', code: 'self'},
        // { name: '上门', code: 'store'},
        { name: '寄修', code: 'express'},
      ]
      break
    case 'returnType':
      objTarget = [
        {name: '快递寄回', code: 'express'},
        {name: '自提', code: 'self'}
      ]
      break
    case 'caseStatus':
      objTarget = [
        { name: '待受理', code: '0'},
        { name: '处理中', code: '1'},
        { name: '已取消', code: '2'},
        { name: '已完成', code: '4'},
      ]
      break
    case 'repairStatus':
      objTarget = [
        { name: '待受理', code: '0'},
        { name: '检测中/维修中', code: '1'},
        { name: '维修完成', code: '2'},
        { name: '已返回/已取机', code: '3'},
        { name: '服务结束', code: '4'},
        { name: '取消维修', code: '8'},
      ]
      break
    case 'orderStatus':
      objTarget = [
        { name: '待执行', code: '0'},
        { name: '处理中', code: '1'},
        { name: '已取消', code: '2'},
        { name: '已拒绝', code: '3'},
        { name: '已完成', code: '4'},
      ]
      break
    case 'assetFields':
      objTarget = [
        // { name: '设备名称', field: 'name', model: 'asset', format: { type: 'text', source: '', isMultiple: false  } },
        // { name: '设备状态', field: 'state', model: 'asset', format: { type: 'select', source: 'AssetState', isMultiple: false } },
        { name: '设备序列号', field: 'serial_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '设备标签', field: 'label', model: 'asset', format: { type: 'label', source: '', isMultiple: true } },
        { name: '外部编号', field: 'out_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        // { name: '产品品牌、类型...', field: 'vendor', model: 'asset', format: { type: 'select', source: 'Vendor', isMultiple: false } },
        // { name: '产品类型', field: 'product_type', model: 'asset', format: { type: 'select', source: 'ProductType', isMultiple: false } },
        { name: '产品', field: 'product', model: 'asset', format: { type: 'select', source: 'Product', isMultiple: false } },
        { name: '购买日期', field: 'purchase_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '厂商质保期', field: 'warranty_time', model: 'asset', format: { type: 'number', source: '', isMultiple: false } },
        { name: '质保到期', field: 'warranty_deadline', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '所在仓库', field: 'warehouse', model: 'asset', format: { type: 'text', source: 'Warehouse', isMultiple: false } },
        { name: '入库日期', field: 'warehouse_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        // { name: '使用人员', field: 'user', model: 'asset', format: { type: 'object', source: '', isMultiple: false } },
        { name: '设备来源', field: 'asset_from', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '设备情况', field: 'asset_state', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '延保类型', field: 'guaranteed_type', model: 'asset', format: { type: 'number', source: '', isMultiple: true } },
        { name: '延保开始时间', field: 'guaranteed_time', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: '延保期限', field: 'guaranteed_limit', model: 'asset', format: { type: 'number', source: '', isMultiple: false } },
        { name: '延保到期', field: 'guaranteed_deadline', model: 'asset', format: { type: 'datetime', source: '', isMultiple: false } },
        { name: 'Apple ID', field: 'apple_id', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'Apple 密码', field: 'apple_password', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: '是否ABM', field: 'isABM', model: 'asset', format: { type: 'boolean', source: '', isMultiple: false } },
        { name: 'SIM卡号', field: 'sim_code', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MAC地址', field: 'mac_address', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'ICCID串号', field: 'iccid_code', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'IMEI编号', field: 'imei_number', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM Token', field: 'mdm_token', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM 账号', field: 'mdm_account', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
        { name: 'MDM 密码', field: 'mdm_password', model: 'asset', format: { type: 'text', source: '', isMultiple: false } },
      ]
      break
    case 'diagnosisState':
      objTarget = [
        { name: '正常', code: 'normal' },
        { name: '异常', code: 'exception' }
      ]
      break
    case 'diagnosisClass':
      objTarget = [
        { name: '电脑', code: 'CPU', child: ['音频', '电池状况', '显示屏', '风扇', '显卡', '内存', '主板', '电源接口', '传感器', '存储'] },
        { name: '电话', code: 'phone', child: ['电池状况', '蓝牙', '摄像头', '传感器', 'Touch ID', 'Face ID', 'WIFI', '服务', '软件', '无线充电', 'Apple Pay'] }
      ]
      break
    case 'diagnosisRepair':
      objTarget = [
        { name: '整机', code: 'overall' },
        { name: '部件', code: 'unit' }
      ]
      break
    case 'repairType':
      objTarget = [
        { name: '保内送修', code: '保内送修' },
        { name: '保外送修', code: '保外送修' },
        { name: '三包服务', code: '三包服务' },
      ]
      break
    case 'repairPolicy':
      objTarget = [
        { name: '7天无理由退机', code: 'returnIn7' },
        { name: '7天质量问题退机', code: 'exchangeIn7' },
        { name: '15天质量问题换机', code: 'exchangeQuality' },
        { name: '其它', code: 'other' },
      ]
      break
    case 'expressState':
      objTarget = [
        { name: '揽收', code: '1' },
        { name: '在途', code: '0' },
        { name: '派件', code: '5' },
        // { name: '签收', code: '3' },
        { name: '退签', code: '4' },
        { name: '拒签', code: '14' }
      ]
      break
    case 'assetOwner':
      objTarget = [
        { name: '经销商服务', code: 'dealer' },
        { name: '经销商租赁', code: 'rental' },
        { name: '企业自有', code: 'enterprise' },
        { name: '个人自有', code: 'personal' }
      ]
      break
    case 'rentType':
      objTarget = [
        { name: '融资租赁', code: '0' },
        { name: '经营性租赁', code: '1' }
      ]
      break
    case 'leaseTerm':
      objTarget = [
        { name: '1年', code: 365 },
        { name: '2年', code: 730 },
        { name: '3年', code: 1095 }
      ]
      break
    case 'payment_cycle':
      objTarget = [
        { name: '月付', code: '0' },
        { name: '季付', code: '1' },
        { name: '半年付', code: '2' }
      ]
      break
    case 'preSoftware':
      objTarget = [
        { name: '微软-8XA-00020', code: 'ms8x20' },
        { name: '微软-8XA-00040', code: 'ms8x40' },
        { name: '微软-8XA-00080', code: 'ms8x80' },
        { name: '微软-FPS-00012', code: 'msfps12' },
        { name: '微软-FPS-00044', code: 'msfps44' },
        { name: '微软-FPS-00052', code: 'msfps52' },
        { name: '微软-Office365-E1许可', code: 'msofficee1' },
        { name: '微软-office彩盒家庭版', code: 'msofficehome' },
        { name: '微软软件-79G-05368', code: 'ms79g05368' },
        { name: '微软-软件-w10英文企业版', code: 'mswin10en' },
      ]
      break
    case 'proService':
      objTarget = [
        { name: '部署服务', code: 'deploy' },
        { name: '上门服务', code: 'delivery' },
        { name: '项目管理服务', code: 'project' },
      ]
      break
    case 'rentalDeadline':
      objTarget = [
        { name: '已过期', code: '0' },
        { name: '7天内', code: '7' },
        { name: '7-30天内', code: '30' },
        { name: '30-60天内', code: '60' },
        { name: '60-180天内', code: '180' },
      ]
      break
    case 'BusinessType':
      objTarget = [
        { name: '订单服务', code: 'order' },
        { name: '设备服务', code: 'service' },
        { name: '设备维修', code: 'maintain' }
      ]
      break
  }
  return objTarget
};

const IN_BROWSER = typeof window !== 'undefined';

const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
};

export default {
  IN_BROWSER,
  randomElement,
  toggleFullScreen,
  kebab,
  getWeekTime,
  isType,
  omit,
  categories,
  wait
};
