<template>
  <div>
    <v-img
      dark
      :src="editBannerData.image ? ossURL + '/'+ editBannerData.image : '/static/error/empty_banner.png'"
      :height="getHeight(editBannerData.image_height)"
    >
      <v-row :align="editBannerData.alignment" class="fill-height">
        <v-col :class="'text-'+ editBannerData.justify+' pa-12'" cols="12" class="justify-center">
          <h1
            v-if="editBannerData.title_class"
            :class="editBannerData.title_class[0]+' font-weight-thin mb-4 '+ editBannerData.title_class[1] +'--text'"
          >{{editBannerData.title}}</h1>
          <h4
            v-if="editBannerData.sub_title_class"
            :class="editBannerData.sub_title_class[0]+' '+ editBannerData.sub_title_class[1] +'--text'"
          >{{editBannerData.sub_title}}</h4>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_PAGE } from "@/store/actions.type";
export default {
  components: {},
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      Upload: {
        fileType: "logo",
        uploading: false,
        progressUpload: 0
      },
      editComponent: "head",
      editHeadData: {},
      editAlertsData: {},
      editBannerData: {},
      barColor: ["white", "primary", "transparent"],
      fontSize: [
        "display-4",
        "display-3",
        "display-2",
        "display-1",
        "headline",
        "title",
        "subheading"
      ],
      fontColor: [
        "red",
        "secondary",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
        "grey",
        "primary",
        "white"
      ],
      alignmentsAvailable: ["start", "center", "end"],
      justifyAvailable: ["start", "center", "end"]
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  watch: {
    dlgSetting(val) {
      val || this.close();
    }
  },
  created() {
    this.getPageInfo("banner");
  },
  methods: {
    getPageInfo(component = "banner") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          if (component === "head")
            this.editHeadData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
          if (component === "banner")
            this.editBannerData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
          if (component === "alerts")
            this.editAlertsData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
        });
    },
    getHeight(size) {
      let numSize = "520px";
      switch (size) {
        case "full": {
          const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
          numSize = `calc(${height} - ${this.$vuetify.application.top}px - 64px)`;
          break;
        }
        case "large": {
          numSize = "520px";
          break;
        }
        case "banner": {
          numSize = "340px";
          break;
        }
        default: {
          numSize = "520px";
          break;
        }
      }
      return numSize;
    },
  }
};
</script>