import { CatalogService } from "@/common/http";

import {
  FETCH_CATALOG_LIST,
  // ORDER_CATALOG_LIST,
  // ORDER_CATALOG_CHILDREN,
  // PUBLISH_CATALOG,
  // EDIT_CATALOG,
  // DELETE_CATALOG,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_CATALOG_LIST,
  SET_CATALOG_LIST,
} from "./mutations.type"

const state = {
  catalogLoading: true,
  catalogList: [],
};

const getters = {
  catalogList: state => state.catalogList,
  catalogLoading: state => state.catalogLoading,
};

const actions = {
  [FETCH_CATALOG_LIST]({ commit }) {
    commit(LOAD_CATALOG_LIST);
    return new Promise((resolve, reject) => {
      CatalogService.get('/list/default')
        .then(({ data }) => {
          commit(SET_CATALOG_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  // [ORDER_CATALOG_LIST]({ commit }, catalogList) {
  //   commit(SET_CATALOG_LIST, catalogList);
  //   return CatalogService.order(catalogList);
  // },
  // [ORDER_CATALOG_CHILDREN]({ commit }, catalogList) {
  //   return CatalogService.order(catalogList);
  // },
  // [PUBLISH_CATALOG](context, catalog) {
  //   return CatalogService.create('', catalog);
  // },
  // [EDIT_CATALOG](context, catalog) {
  //   return CatalogService.update(catalog._id, catalog);
  // },
  // [DELETE_CATALOG](context, catalog) {
  //   return CatalogService.destroy(catalog);
  // },
};

const mutations = {
  [LOAD_CATALOG_LIST] (state) {
    state.catalogLoading = true;
  },
  [SET_CATALOG_LIST](state, { catalog }) {
    state.catalogList = catalog;
    state.catalogLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
