import { GroupService } from "@/common/http";

import {
  FETCH_GROUP_LIST,
  CREATE_GROUP,
  EDIT_GROUP,
  REMOVE_GROUP,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_GROUP_LIST,
  SET_GROUP_LIST,
  SET_GROUP
} from "./mutations.type"

const state = {
  groupLoading: true,
  groupList: [],
  group: {},
};

const getters = {
  groupList: state => state.groupList,
  group: state => state.group,
  groupLoading: state => state.groupLoading,
};

const actions = {
  [FETCH_GROUP_LIST]({ commit }) {
    commit(LOAD_GROUP_LIST);
    return new Promise((resolve, reject) => {
      GroupService.get('/list')
        .then(({ data }) => {
          commit(SET_GROUP_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CREATE_GROUP](context, credentials) {
    return new Promise((resolve, reject) => {
      GroupService.create('', credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [EDIT_GROUP](context, credentials) {
    return new Promise((resolve, reject) => {
      GroupService.update('', credentials._id, credentials)
        .then(({ data }) => {
          context.commit(SET_GROUP, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REMOVE_GROUP](context, credentials) {
    return new Promise((resolve, reject) => {
      GroupService.destroy(credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const mutations = {
  [LOAD_GROUP_LIST] (state) {
    state.groupLoading = true;
  },
  [SET_GROUP_LIST](state, data) {
    state.groupList = data;
    state.groupLoading = false;
  },
  [SET_GROUP](state, data) {
    state.group = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
