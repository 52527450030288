import { AssetService } from "@/common/http";

import {
  FETCH_ASSET_LIST,
  PUBLISH_ASSET,
  EDIT_ASSET,
  BATCH_ASSET,
  ACTIV_ASSET,
  FETCH_ASSETSTATE_LIST,
  PUBLISH_ASSETSTATE,
  EDIT_ASSETSTATE,
  ACTIV_ASSETSTATE,
  FETCH_ASSET,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_ASSET_LIST,
  SET_ASSET_LIST,
  LOAD_ASSETSTATE_LIST,
  SET_ASSETSTATE_LIST,
  SET_ASSET,
} from "./mutations.type"

const state = {
  stateLoading: true,
  stateList: [],
  stateCount: 0,
  assetLoading: true,
  asset: {},
  assetList: [],
  assetCount: 0,
  hasEnterpriseList: [],
};

const getters = {
  asset: state => state.asset,
  stateLoading: state => state.stateLoading,
  stateList: state => state.stateList,
  stateCount: state => state.stateCount,
  assetLoading: state => state.assetLoading,
  assetList: state => state.assetList,
  assetCount: state => state.assetCount,
  hasEnterpriseList: state => state.hasEnterpriseList,
};

const actions = {
  [FETCH_ASSET_LIST]({ commit }, slug) {
    commit(LOAD_ASSET_LIST);
    return new Promise((resolve, reject) => {
      AssetService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_ASSET_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_ASSET](context, data) {
    return AssetService.create('', data);
  },
  [EDIT_ASSET](context, data) {
    return AssetService.update('', data._id, data);
  },
  [BATCH_ASSET](context, data) {
    return AssetService.batch(data);
  },
  [ACTIV_ASSET](context, data) {
    return AssetService.enable('/', data._id +'/'+ data.reply);
  },
  [FETCH_ASSETSTATE_LIST]({ commit }, slug) {
    commit(LOAD_ASSETSTATE_LIST);
    return new Promise((resolve, reject) => {
      AssetService.get('/state/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_ASSETSTATE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_ASSETSTATE](context, data) {
    return AssetService.create('/state', data);
  },
  [EDIT_ASSETSTATE](context, data) {
    return AssetService.update('/state', data._id, data);
  },
  [ACTIV_ASSETSTATE](context, data) {
    return AssetService.enable('/state/', data._id +'/'+ data.reply);
  },
  [FETCH_ASSET]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      AssetService.get('', slug)
        .then(({ data }) => {
          commit(SET_ASSET, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [LOAD_ASSETSTATE_LIST] (state) {
    state.stateLoading = true;
  },
  [SET_ASSETSTATE_LIST](state, list) {
    state.stateCount = list.query.count;
    state.stateList = list.state;
    state.stateLoading = false;
  },
  [LOAD_ASSET_LIST] (state) {
    state.assetLoading = true;
  },
  // [SET_ASSET_LIST](state, list) {
  //   state.assetCount = list.query.count;
  //   state.hasEnterpriseList = list.arrEnterprise;
  //   state.assetList = list.asset;
  //   state.assetLoading = false;
  // },
  [SET_ASSET_LIST](state, list) {
    state.assetCount = list.query.count;
    state.hasEnterpriseList = list.arrEnterprise;
    list.asset.forEach(obj => {
      if (obj.isLock) obj.isSelectable = false
    })
    state.assetList = list.asset;
    state.assetLoading = false;
  },
  [SET_ASSET](state, asset) {
    state.asset = asset;
    state.assetLoading = false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

