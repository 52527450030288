<template>
  <v-container fluid no-gutters class="pa-0 text-center">
    <v-img
      :contain="editBodyData.contain || false"
      :max-height="editBodyData.height || 150"
      :max-width="editBodyData.width || 200"
      class="align-center"
      src="https://picsum.photos/id/11/500/300"
    >
    </v-img>
  </v-container>
</template>
<script>
import { FETCH_PAGE } from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      alignmentsAvailable: ["start", "center", "end"],
      justifyAvailable: ["start", "center", "end"]
    };
  },
  computed: {},
  watch: {
    dlgSetting(val) {
      val || this.close();
    }
  },
  created() {
    this.getPageInfo();
  },
  mounted() {
    this.activeEdit = true;
  },
  methods: {
    getPageInfo(component = "body") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          this.editBodyData = Object.assign(
            { _id: data._id },
            data.config[this.index]
          );
          if (!this.editBodyData.height) this.editBodyData.height = "100%";
          if (!this.editBodyData.type) this.editBodyData.type = "text";
          if (!this.editBodyData.color) this.editBodyData.color = "transparent";
          if (!this.editBodyData.alignment)
            this.editBodyData.alignment = "center";
          if (!this.editBodyData.justify) this.editBodyData.justify = "center";
        });
    }
  }
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-dialog__content {
  z-index: 203 !important;
}
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
