<template>
  <v-row>
    <v-col cols="2" class="text-right">PO号</v-col>
    <v-col cols="4" class="text--secondary">
      {{ orderData.po_number }}
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 0">DT号</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 0">
      {{ orderData.out_number || '无' }}
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 1 && orderData.parentId && orderData.parentId.order_number">上级单号</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 1 && orderData.parentId && orderData.parentId.order_number">
      <span class="v-list-item--link" @click="openParentOrder">
        {{ orderData.parentId.order_number }}<v-icon class="ml-2">read_more</v-icon>
      </span>
    </v-col>
    <v-col cols="2" class="text-right" v-if="orderData.type === 1 && !orderData.parentId"></v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.type === 1 && !orderData.parentId"></v-col>
    <v-col cols="2" class="text-right">订单编号</v-col>
    <v-col cols="4" class="text--secondary">{{ orderData.order_number }}</v-col>
    <v-col cols="2" class="text-right">客户</v-col>
    <v-col cols="4" class="text--secondary" v-if="orderData.enterprise">
      <span class="v-list-item--link" @click="openEnterInfo(orderData.enterprise._id)">
        {{ orderData.enterprise.name }}<v-icon class="ml-2">read_more</v-icon>
      </span>
      <p v-if="orderData.branch">{{ orderData.branch }}</p>
    </v-col>
    <v-col cols="2" class="text-right">创建人</v-col>
    <v-col
      cols="4"
      class="text--secondary"
      v-if="orderData.creator && orderData.creator.personal"
    >
      <widgets-profile-dialogs
        :account="orderData.creator._id"
        class="v-list-item--link"
      >
        {{
          orderData.creator.personal
            ? orderData.creator.personal.name
            : "*未设置姓名"
        }}
        <v-icon class="ml-2">read_more</v-icon>
      </widgets-profile-dialogs>
    </v-col>
    <v-col cols="2" class="text-right">创建时间</v-col>
    <v-col cols="4" class="text--secondary">{{
      orderData.createTime | formatTime
    }}</v-col>
    <v-col cols="2" class="text-right">发票信息</v-col>
    <v-col cols="4" class="text--secondary">
      <div v-if="orderData.invoice_info">
        <div>{{ orderData.invoice_info.title }}</div>
        <div>{{ orderData.invoice_info.license_code }}</div>
        <div>
          {{
            orderData.invoice_info.invoice_type === 1
              ? "增值税专用发票"
              : "增值税普通发票"
          }}
        </div>
        <div>
          <span
            class="v-list-item--link"
            @click="editOrder('invoice_info', '发票信息')"
          >
            <v-icon>read_more</v-icon>
          </span>
        </div>
      </div>
      <div v-else>
        暂无
      </div>
    </v-col>
    <v-col cols="2" class="text-right">收货信息</v-col>
    <v-col cols="4" class="text--secondary">
      <div v-if="orderData.delivery_info">
        <div>{{ orderData.delivery_info.address }}</div>
        <div>{{ orderData.delivery_info.phone }}</div>
        <div>{{ orderData.delivery_info.name }}</div>
        <div>
          <span
            class="v-list-item--link"
            @click="editOrder('delivery_info', '收货信息')"
          >
            <v-icon>read_more</v-icon>
          </span>
        </div>
      </div>
      <div v-else>暂无</div>
    </v-col>
    <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
    <v-dialog v-model="editDlg" max-width="600px" persistent>
      <v-form v-model="editValid">
        <v-card>
          <v-card-title>{{ this.editDlgTitle }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-if="editDlgTitle === 'PO号'"
              v-model="editOrderData.po_number"
              label="PO号"
              :rules="[rules.required]"
              autocomplete="off"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === 'DT号'"
              v-model="editOrderData.out_number"
              label="DT号"
              :rules="[rules.required]"
              autocomplete="off"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-if="editDlgTitle === '客户'"
              v-model="editOrderData.enterprise"
              label="客户"
              autocomplete="off"
              :items="enterpriseList"
              :loading="isEnterpriseLoading"
              :search-input.sync="searchEnterprise"
              item-text="name"
              item-value="_id"
              :rules="[rules.required]"
              hide-no-data
              outlined
              dense
            ></v-autocomplete>
            <v-text-field
              v-if="editDlgTitle === '数量'"
              v-model="editProduct.quantity"
              label="数量"
              type="number"
              autocomplete="off"
              :rules="[rules.required, rules.negative]"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '单价'"
              v-model="editProduct.sales_price"
              label="单价"
              type="number"
              autocomplete="off"
              :rules="[rules.required, rules.negative]"
              clearable
              outlined
              dense
            ></v-text-field>
            <v-row v-if="editDlgTitle === '发票信息'">
              <v-col cols="12">
                <v-radio-group
                  v-model="editInvoice.invoice_type"
                  row
                  :disabled="orderData.status !== 1573"
                >
                  <v-radio label="增值税普通发票" :value="0"></v-radio>
                  <v-radio label="增值税专用发票" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="抬头名称"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.title"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="统一社会信用代码"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_code"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="开户行"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.bank_name"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="银行账号"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.bank_account"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业地址"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_address"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业电话"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.license_telephone"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="发票接收人"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_name"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="接收人电话"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_phone"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="发票邮寄地址"
                  dense
                  hide-details
                  v-model="editOrderData.invoice_info.delivery_address"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="editDlgTitle === '收货信息'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人姓名"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.name"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人电话"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.phone"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人地址"
                  dense
                  hide-details
                  v-model="editOrderData.delivery_info.address"
                  :disabled="orderData.status !== 1573"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEdit">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="submitEdit"
              :disabled="!editValid"
              v-if="orderData.status === 1573"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  FETCH_ENTERPRISE_LIST,
} from "@/store/actions.type";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeId: '',
      enterInfoDlg: false,
      editDlg: false,
      editValid: true,
      editDlgTitle: "",
      editInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      defaultInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      delivery_info: {
        name: "",
        phone: "",
        address: "",
      },
      defaultDelivery: {
        name: "",
        phone: "",
        address: "",
      },
    }
  },
  methods: {
    openParentOrder() {
      let newWin = this.$router.resolve({
        path: "/workbench/order?key=" + this.orderData.parentId.order_number,
      })
      window.open(newWin.href, "_blank")
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    editOrder(action, title, item) {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData));
      this.editOrderData.enterprise = this.editOrderData.enterprise._id;
      this.editOrderData.sales = this.editOrderData.sales ? this.editOrderData.sales._id : '';
      if (action === 'enterprise') {
        this.isEnterpriseLoading = true;
        store.dispatch(FETCH_ENTERPRISE_LIST, "&key=" + this.orderData.enterprise.name || '')
          .then((data) => {
            this.enterpriseList = data.enterprise;
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          })
      }
      if (item) {
        this.editIndex = this.linkedProduct.indexOf(item);
        this.editProduct = JSON.parse(JSON.stringify(item));
      }
      this.activeAction = action;
      this.editDlgTitle = title;
      this.editDlg = true;
    },
    closeEdit() {
      this.editOrderData = JSON.parse(JSON.stringify(this.orderData));
      this.editIndex = -1;
      this.activeAction = "";
      this.editDlgTitle = "";
      this.editDlg = false;
    },
  },
}
</script>