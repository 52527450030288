<template>
  <v-app>
    <div class="myCon">
      <element-app-bar v-if="!$route.meta.overview"></element-app-bar>
      <router-view />
      <!-- <web-footer></web-footer> -->
      <widgets-error />
      <element-app-footer v-if="!$route.meta.overview"></element-app-footer>
    </div>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        style: {
          height: '',
        },
      }
    },
    components: {
    },
  }
</script>
<style lang="scss">
html,body,#app{
  min-height: 100%;
}
.myCon {
  min-height: 100%;
  background-color: #fff;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}
#nprogress .bar {
  background: #d41746 !important;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
</style>
