<template>
  <div>
    <span dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span>{{ orderData.order_number }}</span>
          <v-chip
            class="ml-2"
            label
            small
            color="secondary"
            v-if="orderData.status == '0'"
            >待执行</v-chip
          >
          <v-chip
            class="ml-2"
            label
            small
            color="primary"
            v-if="orderData.status == '1'"
            >处理中</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '2'"
            >已取消</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '3'"
            >已拒绝</v-chip
          >
          <v-chip class="ml-2" label small v-if="orderData.status == '4'"
            >已完成</v-chip
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-expansion-panels multiple accordion flat v-model="offerPanels">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>基本信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-basic-info ref="orderBasic" :orderData="orderData"></case-order-basic-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="orderData.type === 1">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>租赁信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-rent-info :orderData="orderData" :orderId="getId" :isEdit="isEdit"></case-order-rent-info>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>详细信息</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-detail :orderData="orderData" :orderId="getId" :isEdit="isEdit"></case-order-detail>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <span>相关工单</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <case-order-cases :orderData="orderData" :isEdit="isEdit"></case-order-cases>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-subheader
            >最后更新时间： {{ orderData.updateTime | formatTime }}</v-subheader
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  FETCH_ORDER_PURCHASE,
  FETCH_URL,
} from "@/store/actions.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import ApiService from "@/common/http";

export default {
  props: {
    getId: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    divider: {
      type: String,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      dialog: false,
      offerPanels: [0, 1, 2, 3],

      query: { key: "", page: 1, limit: 10, count: 2 },
      orderData: {},
      overlay: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  created() {
    if (this.isOpen) {
      this.doAction('open_case')
    }
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    goToCase(num) {
      let newWin = this.$router.resolve({
        path: "/workbench/equip?key=" + num,
      });
      window.open(newWin.href, "_blank");
    },
    doAction(action) {
      switch (action) {
        case "open_case": {
          this.getOrder(this.getId);
          this.dialog = true;
          break;
        }
      }
    },
    getOrder(id) {
      this.overlay = true;
      store
        .dispatch(FETCH_ORDER_PURCHASE, { query: id })
        .then((data) => {
          this.orderData = data;
          this.orderData.product_list.forEach((item) => {
            item.ordered_quantity = 0;
          });
          this.initialize();
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
          store.commit(SET_ERROR, { msg: err });
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("Update");
    },
    initialize() {
      if (this.orderData.files && this.orderData.files.length) {
        this.orderData.files.forEach((file) => {
          if (
            file.suffix.toLowerCase() === "jpeg" ||
            file.suffix.toLowerCase() === "jpg" ||
            file.suffix.toLowerCase() === "png" ||
            file.suffix.toLowerCase() === "gif"
          ) {
            store.dispatch(FETCH_URL, file.href).then((fileurl) => {
              file.preUrl = fileurl;
              this.$forceUpdate();
            });
          }
        });
      }
    },
    exportAsset(caseInfo) {
      ApiService.download('/asset/export?page=1&limit=1000&enterprise='+ this.orderData.enterprise._id +'&caseId='+ caseInfo._id, {
        responseType:'blob'
      })
          .then(resp => {
            let fileName = caseInfo.case_number +'_资产设备清单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            store.commit(SET_ERROR, {msg: err});
          });
    },
  },
};
</script>

<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
