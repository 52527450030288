import {OrderService} from "@/common/http";

import {
  PUBLISH_ORDER_PURCHASE,
  FETCH_ORDER_PURCHASE_LIST,
  FETCH_ORDER_PURCHASE,
  EDIT_ORDER_PURCHASE
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_ORDER_PURCHASE_LIST,
  SET_ORDER_PURCHASE_LIST,
  SET_ORDER_PURCHASE,
} from "./mutations.type";

const state = {
  orderPurchaseLoading: true,
  orderPurchaseList: [],
  orderPurchaseCount: 0,
  orderPurchaseInfo: {},
};

const getters = {
  orderPurchaseLoading: state => state.orderPurchaseLoading,
  orderPurchaseList: state => state.orderPurchaseList,
  orderPurchaseCount: state => state.orderPurchaseCount,
  orderPurchaseInfo: state => state.orderPurchaseInfo,
};

const actions = {
  [PUBLISH_ORDER_PURCHASE](context, params) {
    return new Promise((resolve, reject) => {
      OrderService.create('/purchase', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_ORDER_PURCHASE]({ commit }, params) {
    commit(LOAD_ORDER_PURCHASE_LIST);
    return new Promise((resolve, reject) => {
      OrderService.get('/purchase', params.query)
        .then(({ data }) => {
          commit(SET_ORDER_PURCHASE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_ORDER_PURCHASE](context, params) {
    return OrderService.update('/purchase', params.id, params.item);
  },
  [FETCH_ORDER_PURCHASE_LIST]({ commit }, params) {
    commit(LOAD_ORDER_PURCHASE_LIST);
    return new Promise((resolve, reject) => {
      OrderService.get('/purchase/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_ORDER_PURCHASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [LOAD_ORDER_PURCHASE_LIST] (state) {
    state.orderPurchaseLoading = true;
  },
  [SET_ORDER_PURCHASE_LIST](state, list) {
    state.orderPurchaseCount = list.query.count;
    state.orderPurchaseList = list.orders;
    state.orderPurchaseLoading = false;
  },
  [SET_ORDER_PURCHASE](state, order) {
    state.orderPurchaseInfo = order;
    state.orderPurchaseLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
