<template>
  <v-container fluid no-gutters class="px-16">
    <v-card flat @click="goToMaterial">
      <div class="d-flex flex-no-wrap">
          <v-img
            :src="editBodyData.cover ? ossURL + '/'+ editBodyData.cover : '/static/error/empty_state.svg'"
            max-height="300"
            max-width="500"
          ></v-img>
        <div class="ma-6">
          <v-card-title
            :class="'headline '+ editBodyData.title_color +'--text'"
            v-text="editBodyData.title || '输入标题文字'"
          ></v-card-title>
          <v-card-subtitle>
            <pre class="grey--text" v-html="editBodyData.context || '文字区域'"></pre>
          </v-card-subtitle>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {
  FETCH_PAGE,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      editBodyData: {},
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer', 'uploadSign', 'materialList']),
  },
  created() {
    this.getPageInfo()
  },
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component)
        .then(data => {
          // this.$store.dispatch(FETCH_MATERIAL_LIST)
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
          if (!this.editBodyData.type) this.editBodyData.type = 'onerowcard'
        });
    },
    goToMaterial() {
      if (this.editBodyData.material) {
        this.$router.push('/event/'+this.editBodyData.material)
      }else {
        return
      }
    },
  },
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  .v-dialog__content {z-index: 203 !important;}
  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
</style>
