<template>
  <v-container fluid no-gutters class="pa-0" v-if="editHeadData">
    <v-app-bar
      elevate-on-scroll
      :color="editHeadData.bar_color"
      :src="ossURL +'/'+ editHeadData.bar_image"
      :dark="editHeadData.bar_color === 'primary' || editHeadData.bar_image !== ''"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-if="editHeadData.bar_image"
          v-bind="props"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        ></v-img>
      </template>
      <v-img
        alt="ESP平台"
        class="shrink mr-2"
        contain
        :src="enterprise.logo_image ? ossURL + '/' + enterprise.logo_image : (editHeadData.logo ? ossURL +'/'+ editHeadData.logo +'?x-oss-process=image/auto-orient,1/resize,m_lfit,w_125/quality,q_90' : '/static/logo2x.svg')"
        transition="scale-transition"
        width="25"
        @click="$router.push('/')"
        style="cursor: pointer;"
      ></v-img>
      <v-toolbar-title class="pl-2 mr-6" @click="$router.push('/')" style="cursor: pointer;">{{editHeadData.title}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="!$vuetify.breakpoint.xs">
        <div v-for="(item, i) in serviceList" :key="i">
          <v-menu
            bottom
            close-delay="100"
            content-class="rounded"
            left
            max-height="500"
            offset-y
            open-delay="60"
            open-on-hover
            transition="slide-y-transition"
            v-if="!item.isHidden && item.children && item.children.length"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn large text v-bind="attrs" v-on="on">
                <span class="nav-btn">{{ item.name }}</span>
                <v-icon>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list nav dense expand>
                <v-list-item v-for="(menu, j) in item.children" :key="i+'_'+j" link @click="goToEvent(menu.material, menu.id, item.service_type)">
                  <v-list-item-icon>
                    <v-icon>{{menu.profileIcon ? menu.profileIcon : 'settings'}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="menu.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-btn large text v-else-if="!item.isHidden">
            <span class="nav-btn" @click="goToEvent(item.material, item.id, item.service_type)">{{ item.name }}</span>
          </v-btn>
        </div>
      </template>


      <v-btn text large @click="$router.push('/support')">
        <span class="nav-btn">支持</span>
      </v-btn>

      <v-btn text large @click="$router.push('/account')" v-if="isAuthenticated">
        <span class="nav-btn">控制台</span>
      </v-btn>

      <v-menu
        bottom
        content-class="rounded"
        left
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
        v-if="isAuthenticated"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>account_circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar v-if="currentUser && currentUser.personal">
                <v-img :src="currentUser.personal.profile ? ossURL +'/'+ currentUser.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-if="currentUser && currentUser.personal">{{currentUser.personal.name || '*未填写名称'}} - {{currentUser.employer && currentUser.employer.roles && currentUser.employer.roles.length && currentUser.employer.roles.map(item => item.name)}}</v-list-item-title>
                <v-list-item-subtitle v-if="currentUser && currentUser.employer">{{currentUser.employer.ownerId.name || '无企业信息'}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list v-if="currentUser && currentUser.notice">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>textsms</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>短信通知</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="currentUser.notice">
                <v-switch dense v-model="currentUser.notice.phone" @change="editNotice()"></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>邮件通知</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch dense v-model="currentUser.notice.email" @change="editNotice()"></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions  v-if="currentUser">
            <v-spacer></v-spacer>
            <widgets-profile-dialogs :account="currentUser._id"><v-btn text>个人资料</v-btn></widgets-profile-dialogs>
            <v-btn color="secondary" text @click="logout">退出登陆</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn text large @click="$router.push('/login')" v-else>
        <v-icon left>login</v-icon>
        <span class="nav-btn">注册/登陆</span>
      </v-btn>
    </v-app-bar>

    <v-alert
      v-if="editHeadData.alerts"
      dense
      class="mb-n1"
      :color="editAlertsData.color || 'secondary'"
      type="info"
      dismissible
      style="z-index: 4"
    >{{editAlertsData.title}}</v-alert>

    <!-- <v-img
      dark
      :src="editBannerData.image ? ossURL + '/'+ editBannerData.image : '/static/error/empty_banner.png'"
      :height="getHeight(editBannerData.image_height)"
    >
      <v-row :align="editBannerData.alignment" class="fill-height">
        <v-col :class="'text-'+ editBannerData.justify+' pa-12'" cols="12" class="justify-center">
          <h1
            v-if="editBannerData.title_class"
            :class="editBannerData.title_class[0]+' font-weight-thin mb-4 '+ editBannerData.title_class[1] +'--text'"
          >{{editBannerData.title}}</h1>
          <h4
            v-if="editBannerData.sub_title_class"
            :class="editBannerData.sub_title_class[0]+' '+ editBannerData.sub_title_class[1] +'--text'"
          >{{editBannerData.sub_title}}</h4>
        </v-col>
      </v-row>
    </v-img>-->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { LOGOUT, FETCH_PAGE, FETCH_CATALOG_LIST, CHECK_AUTH, EDIT_ACCOUNT, FETCH_ENTERPRISE } from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  components: {},
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      Upload: {
        fileType: "logo",
        uploading: false,
        progressUpload: 0
      },
      editComponent: "head",
      editHeadData: {},
      editAlertsData: {},
      editBannerData: {},
      barColor: ["white", "primary", "transparent"],
      fontSize: [
        "display-4",
        "display-3",
        "display-2",
        "display-1",
        "headline",
        "title",
        "subheading"
      ],
      fontColor: [
        "red",
        "secondary",
        "purple",
        "deep-purple",
        "indigo",
        "blue",
        "light-blue",
        "cyan",
        "teal",
        "green",
        "light-green",
        "lime",
        "yellow",
        "amber",
        "orange",
        "deep-orange",
        "brown",
        "blue-grey",
        "grey",
        "primary",
        "white"
      ],
      alignmentsAvailable: ["start", "center", "end"],
      justifyAvailable: ["start", "center", "end"],
      model: null,
      serviceList: [],
      userInfo: '',
      service_menu: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "catalogList", "currentUser", "enterprise"])
  },
  watch: {
    dlgSetting(val) {
      val || this.close();
    },
    menuValue(val) {
      console.log('menu =>'+ val);
    },
  },
  created() {
    this.getPageInfo("head")
    this.getPageInfo("banner")
    this.getPageInfo("alerts")
    this.getCatalogList()
    this.getUserInfo()
  },
  methods: {
    getPageInfo(component = "head") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          if (component === "head")
            this.editHeadData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
          if (component === "banner")
            this.editBannerData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
          if (component === "alerts")
            this.editAlertsData = Object.assign(
              { _id: data._id },
              data.config[0]
            );
        });
    },
    getUserInfo() {
      this.$store.dispatch(CHECK_AUTH).then(res=>{
        this.userInfo = res
        this.getEnterprise(this.userInfo.employer.ownerId._id)
      })
    },
    getEnterprise(id) {
      this.$store.dispatch(FETCH_ENTERPRISE, id)
    },
    getCatalogList() {
      this.$store.dispatch(FETCH_CATALOG_LIST).then(({ catalog }) => {
        this.serviceList = this.flatServiceList(catalog)
      });
    },
    flatServiceList(cl) {
      let arrCatalog = []
      cl.forEach( gItem => {
        if (!gItem.isHidden) {
          let arrService = []
          gItem.children.forEach( cItem => {
            if (!cItem.isHidden) {
              arrService.push(cItem)
            }
          })
          gItem.children = arrService
          arrCatalog.push(gItem)
        }
      })
      return arrCatalog
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = [];
      arrGroup.forEach(groupItem => {
        groupItem.children.forEach(catalogItem => {
          arrRoles.push({
            _id: catalogItem._id,
            name: catalogItem.name,
            icon: catalogItem.profileIcon,
            remarks: catalogItem.remarks,
            group: groupItem.name,
            material: catalogItem.material
          });
        });
      });
      return arrRoles;
    },
    getHeight(size) {
      let numSize = "520px";
      switch (size) {
        case "full": {
          const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
          numSize = `calc(${height} - ${this.$vuetify.application.top}px - 48px - 64px - 36px)`;
          break;
        }
        case "large": {
          numSize = "520px";
          break;
        }
        case "banner": {
          numSize = "340px";
          break;
        }
        default: {
          numSize = "520px";
          break;
        }
      }
      return numSize;
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push('/login');
      });
    },
    changeMenuValue() {
      this.menuValue = !this.menuValue;
    },
    goToEvent(m, id, type) {
      if (m && id && type) {
        this.$router.push({ path: '/event/' + m, query: {service: id, type: type}})
      }else {
        return
      }
    },
    editNotice() {
      this.$store.dispatch(EDIT_ACCOUNT, {noticePhone: this.currentUser.notice.phone, noticeEmail: this.currentUser.notice.email})
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
            this.getUserInfo()
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
    }
  }
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
}
</style>
