<template>
    <v-row no-gutters style="position: relative" class="ma-2">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

</template>
<script>
import {
  FETCH_PAGE,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      editBodyData: {}
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getPageInfo()
  },
  mounted () {},
  methods: {
    getPageInfo(component = 'body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component=' + component)
        .then(data => {
          this.editBodyData = Object.assign({_id: data._id}, data.config[this.index])
        });
    },
  },
  destroyed () {}
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
