<template>
  <v-card outlined class="mb-8 mx-auto">
    <v-list-item :to="path">
      <v-list-item-avatar color="secondary">
        <v-icon dark v-text="icon"></v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="title font-weight-light mb-2"
          v-text="count"
        />
        <v-list-item-subtitle
          class="subheading font-weight-light grey--text"
          v-text="label"
        ></v-list-item-subtitle
        >
      </v-list-item-content>
      <v-list-item-action>
        <v-icon class="subheading font-weight-light grey--text"
        >arrow_forward</v-icon
        >
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-text class="py-1">
      <v-icon class="mr-2 grey--text" small>schedule</v-icon>
      <span class="caption grey--text font-weight-light">更新时间：{{updateTime | fromDate}}</span>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'flag'
    },
    count: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
    },
    path: {
      type: String,
      default: '/'
    },
    updateTime: {
      type: Number
    }
  },
}
</script>
