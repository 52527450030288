<template>
  <v-row v-if="caseData">
    <v-col cols="2" class="text-right">序列号</v-col>
    <v-col cols="4" class="text--secondary">{{ caseData.asset_sn }}</v-col>
    <v-col cols="2" class="text-right">服务类型</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.out_req_type || '-'}}</v-col>
    <v-col cols="2" class="text-right">申请人</v-col>
    <v-col cols="4" class="text--secondary"><widgets-profile-dialogs :account="caseData.creator._id" class="v-list-item--link">{{caseData.creator.personal ? caseData.creator.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-profile-dialogs></v-col>
    <v-col cols="2" class="text-right">使用人</v-col>
    <v-col cols="4" class="text--secondary"><widgets-profile-dialogs :account="caseData.account._id" class="v-list-item--link">{{caseData.account.personal ? caseData.account.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-profile-dialogs></v-col>
    <v-col cols="2" class="text-right">报修故障</v-col>
    <v-col cols="10" class="text--secondary"><pre v-html="caseData.detail_fault"></pre></v-col>
    <v-col cols="2" class="text-right">服务门店</v-col>
    <v-col cols="4" class="text--secondary">
      <widgets-branch-dialogs v-if="caseData.service_branch" :id="caseData.service_branch._id">{{caseData.service_branch.name}}
        <v-icon class="mx-2">read_more</v-icon>
      </widgets-branch-dialogs>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      
    }
  },
  mounted() {},
  
  created() {},
  
  methods: {
    
  }
}
</script>

<style scoped>
pre {
  white-space: pre-line;

  white-space: -moz-pre-line;

  white-space: -pre-line;

  white-space: -o-pre-line;

  word-wrap: break-word;

  word-break: break-all;

  overflow: hidden;

  font-family: inherit;

}
</style>
