import {ProductService} from "@/common/http";

import {
  FETCH_PRODUCT_LIST,
  PUBLISH_PRODUCT,
  FETCH_PRODUCT,
  EDIT_PRODUCT,
  CHECK_PRODUCT,
  ACTIV_PRODUCT,
  FETCH_PRODUCTTYPE_LIST,
  PUBLISH_PRODUCTTYPE,
  EDIT_PRODUCTTYPE,
  ACTIV_PRODUCTTYPE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_PRODUCT_LIST,
  SET_PRODUCT_LIST,
  LOAD_PRODUCTTYPE_LIST,
  SET_PRODUCTTYPE_LIST,
  SET_PRODUCT,
} from "./mutations.type"

const state = {
  typeLoading: true,
  typeList: [],
  typeCount: 0,
  productLoading: true,
  productList: [],
  productCount: 0,
  product: {},
};

const getters = {
  typeLoading: state => state.typeLoading,
  typeList: state => state.typeList,
  typeCount: state => state.typeCount,
  productLoading: state => state.productLoading,
  productList: state => state.productList,
  productCount: state => state.productCount,
  product: state => state.product,
};

const actions = {
  [FETCH_PRODUCT_LIST]({ commit }, slug) {
    commit(LOAD_PRODUCT_LIST);
    return new Promise((resolve, reject) => {
      ProductService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_PRODUCT_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CHECK_PRODUCT](context, slug) {
    return new Promise((resolve, reject) => {
      ProductService.get('/list', '?'+slug)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          })
    });
  },
  [FETCH_PRODUCT]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ProductService.get('', slug)
        .then(({ data }) => {
          commit(SET_PRODUCT, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_PRODUCT](context, data) {
    return ProductService.create('', data);
  },
  [EDIT_PRODUCT](context, data) {
    return ProductService.update('', data._id, data);
  },
  [ACTIV_PRODUCT](context, data) {
    return ProductService.enable('/', data._id +'/'+ data.reply);
  },
  [FETCH_PRODUCTTYPE_LIST]({ commit }, slug) {
    commit(LOAD_PRODUCTTYPE_LIST);
    return new Promise((resolve, reject) => {
      ProductService.get('/type/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_PRODUCTTYPE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_PRODUCTTYPE](context, data) {
    return ProductService.create('/type', data);
  },
  [EDIT_PRODUCTTYPE](context, data) {
    return ProductService.update('/type', data._id, data);
  },
  [ACTIV_PRODUCTTYPE](context, data) {
    return ProductService.enable('/type/', data._id +'/'+ data.reply);
  },
};

const mutations = {
  [LOAD_PRODUCTTYPE_LIST] (state) {
    state.typeLoading = true;
  },
  [SET_PRODUCTTYPE_LIST](state, list) {
    state.typeCount = list.query.count;
    state.typeList = list.types;
    state.typeLoading = false;
  },
  [LOAD_PRODUCT_LIST] (state) {
    state.productLoading = true;
  },
  [SET_PRODUCT_LIST](state, list) {
    state.productCount = list.query.count;
    state.productList = list.product;
    state.productLoading = false;
  },
  [SET_PRODUCT](state, product) {
    state.product = product;
    state.productLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

