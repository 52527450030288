<template>
  <v-row v-if="caseData">
    <v-col cols="2" class="text-right">服务流程</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.catalog.name}}</v-col>
    <v-col cols="2" class="text-right">{{caseData.order_id ? '采购订单' : '服务类型'}}</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.order_id"><span class="v-list-item--link" @click="goToOrder(caseData.order_id.order_number)">{{caseData.order_id.order_number || '-'}}</span><v-icon class="mx-2">read_more</v-icon></v-col>
    <v-col cols="4" class="text--secondary" v-else>{{caseData.process_type || '-'}}</v-col>
    <v-col cols="2" class="text-right">服务单号</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.case_number}}</v-col>
    <v-col cols="2" class="text-right">外部单号</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.out_number || '-'}}</v-col>
    <v-col cols="2" class="text-right" v-if="caseData.order_id">商务单号</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.order_id">{{caseData.erp_number || '-'}}</v-col>
    <v-col cols="2" class="text-right">申请人</v-col>
    <v-col cols="4" class="text--secondary"><widgets-profile-dialogs :account="caseData.creator._id" class="v-list-item--link">{{caseData.creator.personal ? caseData.creator.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-profile-dialogs></v-col>
    <v-col cols="2" class="text-right">申请日期</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.createTime | formatTime}}</v-col>
    <v-col cols="2" class="text-right">所属公司</v-col>
    <v-col :cols="caseData.order_id ? 4 : 10" class="text--secondary v-list-item--link" @click="openEnterprise(caseData.enterprise._id)">{{caseData.enterprise.name}}<v-icon class="mx-2">read_more</v-icon><p v-if="caseData.branch">{{ caseData.branch }}</p></v-col>
    <v-col cols="2" class="text-right">设备用户</v-col>
    <v-col cols="10" class="text--secondary" v-if="caseData.account"><widgets-profile-dialogs :account="caseData.account._id" class="v-list-item--link">{{caseData.account.personal ? caseData.account.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-profile-dialogs></v-col>
    <v-col cols="10" class="text--secondary" v-else>--</v-col>
    <v-col cols="2" class="text-right">交付地址</v-col>
    <v-col cols="4" class="text--secondary" v-if="!editCreateAddress">
      {{caseData.create_address}}<v-icon class="mx-2" small v-if="caseData.status === 0 && caseData.isEdit" @click="editCreateAddress = true; editedItem.create_address = caseData.create_address">edit</v-icon>
    </v-col>
    <v-col cols="4" class="text--secondary" v-else>
      <v-text-field
        v-model="editedItem.create_address"
        type="text"
        label="修改地址"
        placeholder="请输入新的地址"
        hide-details
        append-outer-icon="close"
        append-icon="save"
        @click:append="submit(caseData._id)"
        @click:append-outer="editCreateAddress = false; editedItem.create_address = ''"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="text-right" v-if="caseData.order_id">发票信息</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.order_id">
      {{caseData.order_id && caseData.order_id.invoice_info ? caseData.order_id.invoice_info.title : '--'}}<v-icon class="mx-2" small @click="showInvoiceInfo = !showInvoiceInfo">{{showInvoiceInfo ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
    </v-col>
    <v-col cols="12" v-if="showInvoiceInfo">
      <v-row no-gutters>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-card outlined v-if="caseData.order_id && caseData.order_id.invoice_info">
            <v-list class="grey lighten-3" dense>
              <v-list-item>
                <v-list-item-title>发票类型</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.invoice_type === 0 ? '增值税普通发票' : '增值税专用发票'}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>抬头名称</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.title}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>统一社会信用代码</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_code}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>开户行</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.bank_name}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>银行账号</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.bank_account}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>企业地址</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_address}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>企业电话</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.license_telephone}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>发票接收人</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_name}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>接收人电话</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_phone}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>发票邮寄地址</v-list-item-title>
                <v-list-item-subtitle>{{caseData.order_id.invoice_info.delivery_address}}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

    </v-col>
    <v-dialog v-model="dlgEnterprise" persistent scrollable width="700">
      <widgets-enterprise-dialogs v-if="activeId" :id="activeId" :key="activeId" v-on:Close="closeEnterprise()"></widgets-enterprise-dialogs>
    </v-dialog>
  </v-row>
</template>
<script>
import store from "@/store";
import {
  SAVE_CASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dlgEnterprise: false,
      activeId: '',
      editCreateAddress: false,
      editedItem: {
        create_address: ''
      },
      showInvoiceInfo: false,
    }
  },
  mounted() {},
  watch: {
    dlgEnterprise (val) {
      val || this.closeEnterprise()
    },
  },
  created() {},
  computed: {},
  methods: {
    openEnterprise(corpId) {
      this.activeId = corpId
      this.dlgEnterprise = true
    },
    closeEnterprise() {
      this.dlgEnterprise = false
      this.$nextTick(() => {
        this.activeId = ''
      })
    },
    goToOrder(po_number) {
      let newWin = this.$router.resolve({path: '/workbench/order?key='+ po_number})
      window.open(newWin.href, '_blank')
    },
    submit(caseId) {
      if (this.editedItem.create_address) {
        let strUpdate = {}
        strUpdate.form = 'service'
        strUpdate.caseId = caseId
        strUpdate.create_address = this.editedItem.create_address
        store.dispatch(SAVE_CASE, strUpdate)
          .then(() => {
            this.editCreateAddress = false
            Object.assign(this.caseData, {create_address: strUpdate.create_address})
            store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    }
  }
}
</script>
