<template>
  <v-container fluid no-gutters class="pa-0" v-if="editFooterData">
    <v-footer :color="editFooterData.bgColor || 'primary'" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, i) in editFooterData.links"
          :key="i+'_link'"
          :href="link.url"
          color="white"
          text
          rounded
          class="my-2"
        >{{ link.text }}</v-btn>
        <v-col class="py-4 text-center" cols="12">
          <pre v-html="editFooterData.context || '文字区域'"></pre>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
// eslint-disable-next-line

import { SET_ERROR } from "@/store/mutations.type";
import { FETCH_PAGE, EDIT_PAGE } from "@/store/actions.type";
export default {
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editFooterData: {},
      barColor: ["secondary", "info", "primary", "grey", "#f5f5f5"],
      editorInit: {
        language_url: "/tinymce/zh_CN.js",
        language: "zh_CN",
        height: "100",
        menubar: false,
        link_quicklink: true,
        inline: true,
        toolbar: false,
        plugins: ["lists", "link", "quickbars"],
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif}; ",
        font_formats: "Roboto=roboto;",
        quickbars_insert_toolbar: "formatselect forecolor",
        quickbars_selection_toolbar:
          "bold italic underline strikethrough | fontselect formatselect forecolor | alignleft aligncenter alignright alignjustify | quicklink unlink ｜ removeformat",
        powerpaste_word_import: "clean",
        powerpaste_html_import: "clean"
      },
      activeEdit: false
    };
  },
  computed: {},
  watch: {
    dlgSetting(val) {
      val || this.close();
    }
  },
  created() {
    this.getPageInfo("footer");
  },
  mounted() {
    this.activeEdit = true;
  },
  methods: {
    getPageInfo(component = "footer") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          this.editFooterData = Object.assign(
            {
              _id: data._id,
              context: "&copy; 2019-2021 倍升互联（北京）科技有限公司",
              links: []
            },
            data.config[0]
          );
        });
    },
    close() {
      this.dlgSetting = false;
      this.getPageInfo("footer");
    },
    editItem() {
      this.dlgSetting = true;
    },
    addLink() {
      if (this.editFooterData.links.length > 4) {
        this.$store.commit(SET_ERROR, { msg: "尾部链接最多5个。" });
        return;
      } else {
        this.editFooterData.links.push({ text: "", url: "" });
      }
    },
    removeLink(index) {
      this.editFooterData.links.splice(index, 1);
    },
    submit() {
      if (this.editFooterData._id) {
        this.$store
          .dispatch(EDIT_PAGE, {
            _id: this.editFooterData._id,
            config: this.editFooterData
          })
          .then(() => {
            this.$store.commit(SET_ERROR, {
              msg: "修改成功",
              color: "primary"
            });
            this.close();
          })
          .catch(err => {
            this.$store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      }
    }
  },
  destroyed() {
    this.activeEdit = false;
  }
};
</script>
<style scoped>
.v-dialog__content {
  z-index: 203 !important;
}
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.edit-inline *[contentEditable="true"]:focus,
.edit-inline *[contentEditable="true"]:hover {
  outline: 2px solid #d41746;
}
</style>
