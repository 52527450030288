import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const webrouter = [
  {
    path: '/',
    hidden: false,
    name: 'home',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/index'
    ),
    meta: {
      login_required: false
    }
  },
  {
    path: '/support',
    hidden: false,
    name: 'support',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/support'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/account',
    hidden: false,
    name: 'account',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/account'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/event/:id',
    hidden: false,
    name: 'event',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/event'
    ),
    meta: {
      login_required: false
    }
  },
  {
    path: '/apply',
    hidden: false,
    name: 'apply',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/apply'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/consult',
    hidden: false,
    name: 'consult',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/consult'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/answer',
    hidden: false,
    name: 'answer',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/answer'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/detail/:id',
    hidden: false,
    name: 'detail',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/kbdetail'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/diagnose',
    hidden: false,
    name: 'diagnose',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/diagnose'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/asset',
    hidden: false,
    name: 'asset',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/asset/index'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/equip',
    hidden: false,
    name: 'equip',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/equipment'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/equip/edit/:id',
    hidden: false,
    name: 'equip_edit',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/equipment_edit'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench',
    hidden: false,
    name: 'workbench',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/index'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/equip',
    hidden: false,
    name: 'equipapplies',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/applies'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/repair',
    hidden: false,
    name: 'repairlist',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/repair/list'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/repair/edit',
    hidden: false,
    name: 'repairedit',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/repair/edit'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/advisory',
    hidden: false,
    name: 'advisorylist',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/advisory/list'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/order',
    hidden: false,
    name: 'orderlist',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/order/list'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/advisory/edit',
    hidden: false,
    name: 'advisoryedit',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/advisory/edit'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/workbench/service/overview/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/service/overview.vue'
    ),
    name: 'overviewservice',
    meta: {
      overview: true
    }
  },
  {
    path: '/workbench/repair/overview/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/workbench/repair/overview.vue'
    ),
    name: 'overviewrepair',
    meta: {
      overview: true
    }
  },
  {
    path: '/analysis',
    hidden: false,
    name: 'analysis',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/analysis'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/info',
    hidden: false,
    name: 'info',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/info'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/sheet',
    hidden: false,
    name: 'sheet',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/worksheet'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/diaDetail',
    hidden: false,
    name: 'diaDetail',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/diaDetail'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/staff',
    hidden: false,
    name: 'staff',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/staff'
    ),
    meta: {
      login_required: true
    }
  },
  {
    path: '/knowledge',
    hidden: false,
    name: 'knowledge',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/knowledgeBase'
    ),
    meta: {
      login_required: false
    }
  },
  {
    path: '/register',
    hidden: false,
    name: 'register',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/register'
    ),
    meta: {
      login_required: false
    }
  },
  {
    path: '/login',
    hidden: false,
    name: 'login',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/login'
    ),
    meta: {
      login_required: false
    }
  },
]

export default new Router({
  base: "/",
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes: webrouter
})
