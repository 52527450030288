// AUTH
export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CREATE_ROUTER = 'getRouter';
//ACCOUNT
export const CREATE_ACCOUNT = 'register';
export const FETCH_ACCOUNT_LIST = 'getAccountList';
export const CHECK_USERNAME = 'checkUserName';
export const CHECK_USERPHONE = 'checkUserPhone';
export const EDIT_ACCOUNT = 'updateMineAccount';
// Dealer
export const FETCH_DEALER_LIST = 'getDealerList';
export const FETCH_DEALER_ENTERPRISE = 'getDealerEnterpriseList'
export const CHECK_DEALER_CODE = 'checkDealerInviteCode';
export const AUTH_DEALER = 'authDealer';
export const BLOCK_DEALER = 'blockDealer';
export const DELETE_DEALER = 'deleteDealer';
export const FETCH_DEALER = 'getDealer';
export const PUBLISH_DEALER = 'createDealer';
export const EDIT_DEALER = 'updateDealer';
export const BOOKMARK_DEALER = 'setDefaultDealer'
// Branch
export const FETCH_BRANCH_LIST = 'getBranchList';
export const ORDER_BRANCH_LIST = 'orderBranchList';
export const PUBLISH_BRANCH = 'createBranch';
export const FETCH_BRANCH = 'getBranch';
export const EDIT_BRANCH = 'updateBranch';
export const BLOCK_BRANCH = 'blockBranch';
export const DELETE_BRANCH = 'deleteBranch';
// Catalog
export const FETCH_CATALOG_LIST = 'getCatalogList';
export const ORDER_CATALOG_LIST = 'orderCatalogList';
export const ORDER_CATALOG_CHILDREN = 'orderCatalogChildren';
export const PUBLISH_CATALOG = 'createCatalog';
export const FETCH_CATALOG = 'getCatalog';
export const EDIT_CATALOG = 'updateCatalog';
export const BLOCK_CATALOG = 'blockCatalog';
export const DELETE_CATALOG = 'deleteCatalog';
// Department
export const FETCH_DEPT_LIST = 'getDeptList';
export const CREATE_DEPT = 'createDept';
export const EDIT_DEPT = 'updateDept';
export const REMOVE_DEPT = 'deleteDept';
// Group
export const FETCH_GROUP_LIST = 'getGroupList';
export const CREATE_GROUP = 'createGroup';
export const EDIT_GROUP = 'updateGroup';
export const REMOVE_GROUP = 'deleteGroup';
// Role
export const FETCH_ROLE_LIST = 'getRoleList';
export const CREATE_ROLE = 'createRole';
export const EDIT_ROLE = 'updateRole';
export const REMOVE_ROLE = 'deleteRole';
// Employee
export const FETCH_EMPLOYEE_LIST = 'getEmployeeList';
export const FETCH_ADMIN_LIST = 'getAdminList';
export const CREATE_ADMIN = 'createAdmin';
export const REMOVE_ADMIN = 'deleteAdmin';
export const FETCH_EMPLOYEE = 'getEmployee';
export const CREATE_EMPLOYEE = 'createEmployee';
export const EDIT_EMPLOYEE = 'updateEmployee';
export const BLOCK_EMPLOYEE = 'blockEmployee';
export const REMOVE_EMPLOYEE = 'deleteEmployee';
// Enterprise
export const CHECK_ENTERPRISE_CODE = 'checkEnterpriseInviteCode';
export const FETCH_ENTERPRISE_LIST = 'getEnterpriseList';
export const FETCH_ENTERPRISE = 'getEnterprise';
export const PUBLISH_ENTERPRISE = 'createEnterprise';
export const EDIT_ENTERPRISE = 'updateEnterprise';
export const AUTH_ENTERPRISE = 'authEnterprise';
export const BLOCK_ENTERPRISE = 'blockEnterprise';
export const DELETE_ENTERPRISE = 'deleteEnterprise';
// Service
export const FETCH_SERVICE_LIST = 'getServiceList';
export const PUBLISH_SERVICE = 'createService';
export const EDIT_SERVICE = 'updateService';
export const REMOVE_SERVICE = 'deleteService';
// Tools
export const FETCH_UPLOAD = 'getUploadSign';
export const CHECK_ADDR = 'checkAddress';
export const DELETE_FILE = 'delFile';
export const FETCH_URL = 'getUrlSign';
export const SEND_SMS_CODE = 'sendSMSValidCode';
export const CHECK_SMS_CODE = 'checkSMSValidCode';
export const SEND_EMAIL_CODE = 'sendEmailValidCode';
export const FETCH_EXPRESS = 'getExpressInfo';
// Setting
export const FETCH_FIELDS = 'getFieldList';
export const CREATE_FIELD = 'createField';

// Setting - Warehouse
export const FETCH_WAREHOUSE_LIST = 'getWarehouseList';
export const PUBLISH_WAREHOUSE = 'createWarehouse';
export const FETCH_WAREHOUSE = 'getWarehouse';
export const EDIT_WAREHOUSE = 'updateWarehouse';
export const ACTIV_WAREHOUSE = 'enableWarehouse';

// Setting - Vendor
export const FETCH_VENDOR_LIST = 'getVendorList';
export const PUBLISH_VENDOR = 'createVendor';
export const FETCH_VENDOR = 'getVendor';
export const EDIT_VENDOR = 'updateVendor';
export const ACTIV_VENDOR = 'enableVendor';

// Setting - Logs
export const FETCH_LOGS_LIST = 'getLogsList';

// Setting - Product
export const FETCH_PRODUCT_LIST = 'getProductList';
export const PUBLISH_PRODUCT = 'createProduct';
export const FETCH_PRODUCT = 'getProduct';
export const EDIT_PRODUCT = 'updateProduct';
export const ACTIV_PRODUCT = 'enableProduct';
export const FETCH_PRODUCTTYPE_LIST = 'getProductTypeList';
export const PUBLISH_PRODUCTTYPE = 'createProductType';
export const EDIT_PRODUCTTYPE = 'updateProductType';
export const ACTIV_PRODUCTTYPE = 'enableProductType';
export const CHECK_PRODUCT = 'checkProduct';
// Page
export const FETCH_PAGE = 'getPage';
export const EDIT_PAGE = 'updatePage';
export const EDIT_BODY_ELEMENT = 'updateOneElement';
export const DELETE_BODY_ELEMENT = 'deleteOneElement';
// Material
export const FETCH_MATERIAL_LIST = 'getMaterialList';
export const FETCH_MATERIAL = 'getMaterial';
export const PUBLISH_MATERIAL = 'createMaterial';
export const EDIT_MATERIAL = 'updateMaterial';
export const AUTH_MATERIAL = 'authMaterial';
export const BLOCK_MATERIAL = 'blockMaterial';
export const DELETE_MATERIAL = 'deleteMaterial';

// Asset
export const FETCH_ASSET_LIST = 'getAssetList';
export const PUBLISH_ASSET = 'createAsset';
export const FETCH_ASSET = 'getAsset';
export const EDIT_ASSET = 'updateAsset';
export const BATCH_ASSET = 'batchUpdateAsset';
export const ACTIV_ASSET = 'enableAsset';
export const FETCH_ASSETSTATE_LIST = 'getAssetStateList';
export const PUBLISH_ASSETSTATE = 'createAssetState';
export const EDIT_ASSETSTATE = 'updateAssetState';
export const ACTIV_ASSETSTATE = 'enableAssetState';
export const LOCK_ASSET = 'lockAsset';
//REPORT
export const FETCH_REPORT_ASSET_COUNT = 'getAssetCount';
export const FETCH_REPORT_CASEGROUP_COUNT = 'getCaseGroupCount';
export const FETCH_REPORT_ALL_COUNT = 'getAllCount';
export const FETCH_REPORT_CASE_HISTORY = 'getHistoryCase';
export const FETCH_REPORT_ASSET_HISTORY = 'getHistoryAsset';
export const FETCH_REPORT_ENTERPRISE_HISTORY = 'getHistoryEnterprise';
export const FETCH_REPORT_ACCOUNT_HISTORY = 'getHistoryAccount';
export const FETCH_REPORT_CASE_COUNT_YEAR = 'getCaseCountYear';
export const FETCH_REPORT_CASE_STATUS = 'getCaseStatus';
export const FETCH_REPORT_RENT_OFFER_STATUS = 'getRentOfferStatus';
export const FETCH_REPORT_ORDER_PURCHASE_STATUS = 'getOrderPurchaseStatus';

// Case
export const INIT_CASE = 'initCase';
export const PUBLISH_CASE = 'createCase';
export const FETCH_CASE = 'getCase';
export const FETCH_CASE_LIST = 'getCaseList';
export const ASYNC_CASE_LIST = 'asyncCaseList';
export const REJECT_CASE = 'rejectCase';
export const SUBMIT_CASE = 'submitCase';
export const SAVE_CASE = 'saveCase';
export const CANCEL_CASE = 'cancelCase';
export const ASSIST_CASE = 'assistCase';
export const PUBLISH_CASE_REPLY = 'replyConsultCase'
export const RESOLVED_CASE = 'resolvedConsultCase'
export const REMOVE_CASE_ITEM = 'deleteItemInCase'

export const OVERVIEW_SERVICE_CASE = 'overviewServiceCase'
export const OVERVIEW_REPAIR_CASE = 'overviewRepairCase'

//Workflow
export const FETCH_TASK_LIST = 'getTaskList';
export const PUBLISH_TASK = 'createTask';
export const EDIT_TASK = 'updateTask';
export const ACTIV_TASK = 'enableTask';
export const FETCH_PROCESS_LIST = 'getProcessList';
export const PUBLISH_PROCESS = 'createProcess';
export const EDIT_PROCESS = 'updateProcess';
export const ACTIV_PROCESS = 'enableProcess';

// KnowledgeCatalog
export const FETCH_KNOWLEDGE_CATALOG_LIST = 'getKnowledgeCatalogList';
export const ORDER_KNOWLEDGE_CATALOG_LIST = 'orderKnowledgeCatalogList';
export const ORDER_KNOWLEDGE_CATALOG_CHILDREN = 'orderKnowledgeCatalogChildren';
export const PUBLISH_KNOWLEDGE_CATALOG = 'createKnowledgeCatalog';
export const FETCH_KNOWLEDGE_CATALOG = 'getKnowledgeCatalog';
export const EDIT_KNOWLEDGE_CATALOG = 'updateKnowledgeCatalog';
export const DELETE_KNOWLEDGE_CATALOG = 'deleteKnowledgeCatalog';

// KNOWLEDGE
export const FETCH_KNOWLEDGE_LIST = 'getKnowledgeList';
export const FETCH_KNOWLEDGE = 'getKnowledge';
export const PUBLISH_KNOWLEDGE = 'createKnowledge';
export const EDIT_KNOWLEDGE = 'updateKnowledge';
export const AUTH_KNOWLEDGE = 'authKnowledge';
export const TOP_KNOWLEDGE = 'topKnowledge';
export const DELETE_KNOWLEDGE = 'deleteKnowledge';
export const CREATE_USEFUL_KNOWLEDGE = 'createUsefulKnowledge';
export const CANCEL_USEFUL_KNOWLEDGE = 'cancelUsefulKnowledge';

//MONTHLY
export const FETCH_MONTHLY_REPORT_LIST = 'getMonthlyReportList'
export const EDIT_MONTHLY_REPORT = 'updateMonthlyReport';
export const FETCH_MONTHLY_REPORT = 'getMonthlyReport';
export const PUBLISH_MONTHLY_REPORT = 'publishMonthlyReport';

//ORDER
export const PUBLISH_ORDER_PURCHASE = 'createPurchaseOrder';
export const FETCH_ORDER_PURCHASE_LIST = 'getPurchaseOrderList';
export const FETCH_ORDER_PURCHASE = 'getPurchaseOrder';
export const EDIT_ORDER_PURCHASE = 'editPurchaseOrder';
//NOTES
export const PUBLISH_NOTES = 'createNotes';
export const FETCH_NOTES_LIST = 'getNotesList';
export const FETCH_NOTES = 'getNotes';
export const EDIT_NOTES = 'editNotes';
export const REMOVE_NOTES = 'deleteNotes';
