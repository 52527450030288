<template>
  <v-btn icon @click="copy">
    <v-fade-transition hide-on-leave>
      <v-icon>{{clicked ? 'done' : 'content_copy'}}</v-icon>
    </v-fade-transition>
  </v-btn>
</template>

<script>
// Utilities
import Util from '@/common/util';
export default {
  name: 'AppCopyBtn',
  props: {
    target: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    clicked: false,
    wait: 2000,
  }),
  methods: {
    async copy () {
      if (!Util.IN_BROWSER) return
      const el = this.target()
      el.setAttribute('contenteditable', 'true')
      el.focus()
      document.execCommand('selectAll', false, null)
      document.execCommand('copy')
      el.removeAttribute('contenteditable')
      this.clicked = true
      await Util.wait(this.wait)
      this.clicked = false
    },
  },
}
</script>
