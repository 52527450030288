<template>
  <v-container fluid no-gutters class="px-16">
    <v-carousel
      height="275px"
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      delimiter-icon="remove"
    >
      <v-carousel-item
        v-for="(item,i) in editBodyData.images"
        :key="i"
        :src="item.src ? ossURL + '/'+ item.src : '/static/error/empty_banner.png'"
        :href="item.href"
        target="_blank"
      ></v-carousel-item>
      <v-sheet v-if="editBodyData.images.length === 0" color="grey lighten-2" height="100%" tile>
        <v-row class="fill-height" align="center" justify="center">
          <div class="grey--text">轮播区域</div>
        </v-row>
      </v-sheet>
    </v-carousel>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { FETCH_PAGE } from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      rules: {
        required: value => value === 0 || !!value || "请输入内容.",
        selected: value => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        }
      },
      dlgSetting: false,
      valid: true,
      editBodyData: {
        images: []
      },
      alignmentsAvailable: ["start", "center", "end"],
      justifyAvailable: ["start", "center", "end"]
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentEmployer", "uploadSign"])
  },
  watch: {
    dlgSetting(val) {
      val || this.close();
    }
  },
  created() {
    this.getPageInfo();
  },
  mounted() {},
  methods: {
    getPageInfo(component = "body") {
      this.$store
        .dispatch(FETCH_PAGE, "?name=home&component=" + component)
        .then(data => {
          this.editBodyData = Object.assign(
            { _id: data._id },
            data.config[this.index]
          );
          if (!this.editBodyData.images) this.editBodyData.images = [];
          if (!this.editBodyData.type) this.editBodyData.type = "carousel";
        });
    }
  },
  destroyed() {}
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
