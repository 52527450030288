import {CaseService} from "@/common/http";
import {
  PUBLISH_CASE,
  INIT_CASE,
  FETCH_CASE_LIST,
  ASYNC_CASE_LIST,
  FETCH_CASE,
  OVERVIEW_SERVICE_CASE,
  OVERVIEW_REPAIR_CASE,
  REJECT_CASE,
  SUBMIT_CASE,
  SAVE_CASE,
  CANCEL_CASE,
  ASSIST_CASE,
  PUBLISH_CASE_REPLY,
  RESOLVED_CASE,
  REMOVE_CASE_ITEM,
} from "./actions.type";
import {
  SET_ERROR,
  SET_CASE_INIT,
  LOAD_CASE_LIST,
  SET_CASE_LIST,
  SET_CASE,
  SET_REPAIR_STEP,
} from "./mutations.type"
const state = {
  process_info: '',
  caseLoading: true,
  caseList: [],
  caseCount: 0,
  caseInfo: {},
  repairPhaseStep: '',
};
const getters = {
  caseInfo: state => state.caseInfo,
  process_info: state => state.process_info,
  caseLoading: state => state.caseLoading,
  caseList: state => state.caseList,
  caseCount: state => state.caseCount,
  repairPhaseStep: state => state.repairPhaseStep,
};
const actions = {
  [OVERVIEW_SERVICE_CASE]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/service/overview/'+ params.id)
          .then(({ data }) => {
            commit(SET_CASE, data);
            resolve(data);
          })
          .catch(error => {
            commit(SET_ERROR, { msg: error });
            reject(error);
          });
    })
  },
  [OVERVIEW_REPAIR_CASE]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/repair/overview/'+ params.id)
          .then(({ data }) => {
            commit(SET_CASE, data);
            resolve(data);
          })
          .catch(error => {
            commit(SET_ERROR, { msg: error });
            reject(error);
          });
    })
  },
  [FETCH_CASE]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/'+params.form, params.query)
        .then(({ data }) => {
          commit(SET_CASE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [INIT_CASE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      CaseService.get('/'+params.form+'/initialize', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CASE_INIT, data);
          resolve(data);
        })
        .catch(({response}) => {
          commit(SET_ERROR, { msg: response.data.message });
          reject(response.data.message);
        })
    });
  },
  [PUBLISH_CASE](context, params) {
    return new Promise((resolve, reject) => {
      CaseService.create('/'+params.form, params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [PUBLISH_CASE_REPLY](context, params) {
    return new Promise((resolve, reject) => {
      CaseService.create('/'+params.form +'/reply', params.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_CASE_LIST]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/'+params.form+'/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ASYNC_CASE_LIST](context, params) {
    return new Promise((resolve, reject) => {
      CaseService.get('/'+params.form+'/list', '?'+params.query)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          })
    });
  },
  [REJECT_CASE](context, params) {
    return CaseService.update('/'+params.form +'/reject', params.caseId, params);
  },
  [RESOLVED_CASE](context, params) {
    return CaseService.update('/'+params.form +'/resolved', params.caseId);
  },
  [SUBMIT_CASE](context, params) {
    return CaseService.update('/'+params.form +'/submit', params.caseId, params)
  },
  [SAVE_CASE](context, params) {
    return CaseService.update('/'+params.form +'/save', params.caseId, params)
  },
  [ASSIST_CASE](context, params) {
    return CaseService.update('/'+params.form +'/assist', params.caseId, params)
  },
  [CANCEL_CASE](context, params) {
    return CaseService.update('/'+params.form +'/cancel', params.caseId, params)
  },
  [REMOVE_CASE_ITEM](context, params) {
    return CaseService.destroy('/'+ params.form)
  }
};
const mutations = {
  [SET_CASE_INIT](state, process) {
    state.process_info = process;
  },
  [LOAD_CASE_LIST] (state) {
    state.caseLoading = true;
  },
  [SET_CASE_LIST](state, list) {
    state.caseCount = list.query.count;
    state.caseList = list.cases;
    state.caseLoading = false;
  },
  [SET_CASE](state, cases) {
    state.caseInfo = cases.caseInfo;
    state.caseLoading = false;
  },
  [SET_REPAIR_STEP](state, step) {
    state.repairPhaseStep = step;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
