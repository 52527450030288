<template>
  <div>
    <v-stepper
      v-model="steps"
      alt-labels
      class="mt-12 elevation-0 phaseStepper"
      style="max-width: 980px;"
    >
      <div class="step-con">
        <!-- <div class="step-wrap"></div> -->
        <v-stepper-header>
          <template v-for="(phase, i) in phaseSteps">
            <v-stepper-step :step="phase.step_num + 1" :key="i" :color="phase.isEnd?'secondary':'grey'" :complete="phase.isEnd" :rules="[() => phase.step_icon !== 'cancel']" :editable="phase.isEnd" edit-icon="done">
              <span style="margin: 0 auto;">
                {{ phase.step_info }}
                <v-tooltip bottom v-if="phase.step_tips && phase.isCurrent" max-width="220" nudge-left="24">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="grey lighten-1" v-bind="attrs" v-on="on" small>info</v-icon>
                  </template>
                  <span v-html="phase.step_tips"></span>
                </v-tooltip>
              </span>
              <small class="mt-5 font-weight-regular" v-if="phase.step_time">{{ phase.step_time | formatDate }}</small>
            </v-stepper-step>
            <v-divider v-if="i < phaseSteps.length - 1" class="mt-13 mx-n14" :key="i+999"></v-divider>
          </template>
        </v-stepper-header>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {SET_REPAIR_STEP} from "@/store/mutations.type";

export default {
  props: {
    phaseSteps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      steps: '',
    }
  },
  computed: {
    ...mapGetters(['repairPhaseStep']),
  },
  watch: {
    steps(val) {
      store.commit(SET_REPAIR_STEP, val)
    },
  },
  created() {
    this.steps = this.phaseSteps.find( i => i.isCurrent).step_num + 1
  },
  methods: {
    
  },
}
</script>

<style scoped>
.phaseStepper ::v-deep .v-stepper__step__step { 
  width: 52px;
  height: 52px;
}
.phaseStepper ::v-deep .v-stepper__step--editable:hover {
  background-color: transparent;
}
.step-con {
  position: relative;
}
.step-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
