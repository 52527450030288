<template>
  <div>
    <span dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="980"
    >
      <v-card>
        <v-card-title>
          <span>服务报告</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="pa-12 pt-0" style="font-family: Helvetica Neue;" ref="report">
            <v-row class="pt-10">
              <v-col cols="6" class="d-flex justify-start">
                <v-img class="gray-img" position="left center" :src="ossURL + (caseData.imgLogo ? '/'+ caseData.imgLogo: '/default/logo2x.png')" aspect-ratio="1" max-height="50px" contain></v-img>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <div class="pt-2">
                  <span class="black--text">编号：</span><span class="secondary--text">{{ (caseData.case_number).substr(-12,12) }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col align="center" :cols="12">
                <p class="title font-weight-bold black--text mb-0">
                  服务报告书
                </p>
              </v-col>
              <v-col :cols="12">
                <p class="black--text mb-0">
                  服务商名称：<span v-if="caseData.service_branch">{{ caseData.service_branch.name || '-'}}</span>
                </p>
                <v-row>
                  <v-col :cols="6" class="pt-0">
                    <p class="black--text mb-0">
                      <span style="letter-spacing: 2.6rem;">地</span>
                      <span>址：</span>
                      <span v-if="caseData.service_branch">{{ caseData.service_branch.address || '-'}}</span>
                    </p>
                  </v-col>
                  <v-col :cols="6" align="end" class="pt-0">
                    <p class="black--text mb-0">
                      电话：<span v-if="caseData.service_branch">{{ caseData.service_branch.contactPhone || '-'}}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <table class="reportTable caption" cellspacing="0">
              <tbody>
                <tr>
                  <td align="center" class="subtitle-2 font-weight-bold" colspan="2">用户信息</td>
                  <td align="center" class="subtitle-2 font-weight-bold" colspan="2">产品信息</td>
                </tr>
                <tr>
                  <td class="noRightBorder">用户：{{ caseData.account.personal.name || ''}}</td>
                  <td class="noLeftBorder">电话：{{ caseData.account.phone || ''}}</td>
                  <td colspan="2">产品类型：{{ caseData.asset_product }}</td>
                </tr>
                <tr>
                  <td colspan="2">电子邮件：{{caseData.account.username}}</td>
                  <td colspan="2">序列号：{{ caseData.asset_sn }}</td>
                </tr>
                <tr>
                  <td class="noRightBorder">送修人：{{ caseData.creator.personal.name || ''}}</td>
                  <td class="noLeftBorder">电话：{{ caseData.creator.phone || ''}}</td>
                  <td colspan="2">IMEI/MEID：{{ caseData.asset_imei }}</td>
                </tr>
                <tr>
                  <td colspan="2" v-if="caseData.enterprise">公司：{{ caseData.enterprise.name }}</td>
                  <td>购买日期：</td>
                  <td>激活日期：</td>
                </tr>
                <tr>
                  <td class="noRightBorder">随机附件：</td>
                  <td class="noLeftBorder">其他：</td>
                  <td colspan="2">购机凭证/发票：</td>
                </tr>
                <tr>
                  <td colspan="4">
                    用户故障描述：{{ caseData.detail_fault || ''}}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    服务类型：
                    <span class="mr-8">退机<v-icon x-small color="transparent">check</v-icon></span>
                    <span class="mr-8">换机<v-icon x-small color="transparent">check</v-icon></span>
                    <span class="mr-8">保内<v-icon x-small v-if="caseData.out_req_type && (caseData.out_req_type.includes('保内') || caseData.out_req_type.includes('三包'))">check</v-icon></span>
                    <span>保外<v-icon x-small v-if="caseData.out_req_type && caseData.out_req_type.includes('保外')">check</v-icon></span>
                  </td>
                  <td>
                    是否运行诊断：是/否
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="py-1">
                    <div>
                      说明：请您核实并确认上述用户信息和产品信息部分填写内容的准确性，该信息将被用于下列情况：
                    </div>
                    <ul class="pl-14">
                      <li>
                        以便我们能够提供给您维修状态等相关服务
                      </li>
                      <li>
                        我们可能通过电子邮件的形式向您了解您对本次服务的满意度情况
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">送修时间：{{ new Date(caseData.createTime).getFullYear() }} 年 {{ new Date(caseData.createTime).getMonth() + 1 }} 月 {{ new Date(caseData.createTime).getDate() }} 日 {{ new Date(caseData.createTime).getHours() }} 时 {{ new Date(caseData.createTime).getMinutes() }} 分</td>
                  <td>客户签字：</td>
                  <td>受理人：</td>
                </tr>
              </tbody>
              <div class="subtitle-2 black--text">
                提醒事项：
              </div>
              <tbody>
                <tr>
                  <td colspan="4" class="py-1">
                    <ul class="tipsTableList">
                      <li>
                        为了保护您的重要数据，建议您定期备份产品存储媒介中的信息和数据。在您产品的维修期间，存储媒介中的内容可能会因软件安装或硬件更改而消除。请您在送修前事先做好备份并移除所有您希望保护的数据和个人信息，取消所有安全密码，关闭“设备账号”等选项。
                      </li>
                      <li>
                        本服务报告是您维修的重要凭据，请妥善保管。
                      </li>
                      <li>
                        如您的设备侵液，检测后会造成设备无法开机等其它故障，请您随时备份数据和个人信息。
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="productTable caption black--text mt-2" cellspacing="0">
              <tbody>
                <tr>
                  <td colspan="6" class="subtitle-2 font-weight-bold" align="center">
                    产品服务信息
                  </td>
                </tr>
                <tr>
                  <td>故障诊断及
                    <br>
                    分析过程
                  </td>
                  <td colspan="5">{{ caseData.detail_problem || ''}}｜{{ caseData.detail_solve || ''}}</td>
                </tr>
                <tr>
                  <td rowspan="2">诊断结果：</td>
                  <td>间歇性故障</td>
                  <td colspan="2"></td>
                  <td colspan="2" v-if="caseData.status < 2">工程师：{{caseData.staff_check_code}}</td>
                  <td colspan="2" v-else>工程师：{{ caseData.staff_repair_code}}</td>
                </tr>
                <tr>
                  <td class="noLeftBorder">持续性故障</td>
                  <td colspan="2"></td>
                  <td colspan="2" v-if="caseData.repaired_time">完成日期：{{caseData.repaired_time | formatTime}}</td>
                  <td colspan="2" v-else>完成日期：</td>
                </tr>
                <tr>
                  <td align="center">项目</td>
                  <td align="center">序列号</td>
                  <td align="center" colspan="2">零件名称</td>
                  <td align="center" colspan="2">数量</td>
                </tr>
                <tr>
                  <td :rowspan="productParts.length>5?productParts.length+1:'6'" align="center">
                    零件
                  </td>
                </tr>
                <tr v-for="(item, i) in productParts" :key="i">
                  <td align="center" class="noLeftBorder">{{ item.num }}</td>
                  <td align="center" colspan="2">{{ item.name }}</td>
                  <td align="center" colspan="2">{{ item.quantity }}</td>
                </tr>
                <tr>
                  <td colspan="2" v-if="caseData.repair_price">维修费：{{caseData.repair_price | formatMoney}}元</td>
                  <td colspan="2" v-else>维修费：价格估算中...</td>
                  <td colspan="4">同意维修，客户签字：</td>
                </tr>
                <tr>
                  <td class="noborder"></td>
                  <td class="noborder"></td>
                  <td class="noborder"></td>
                  <td class="noborder"></td>
                  <td class="noborder"></td>
                  <td class="noborder"></td>
                </tr>
              </tbody>
            </table>
            <table class="confirmTable caption black--text mt-n5" cellspacing="0">
              <tbody>
                <tr>
                  <td class="subtitle-2 font-weight-bold" align="center">客户取机确认</td>
                  <td class="subtitle-2 font-weight-bold" align="center">服务商</td>
                </tr>
                <tr>
                  <td>您的机器已验机并能正常工作，确认机器外观及部件</td>
                  <td rowspan="3" align="center">
                    <img :src="require('../../assets/sign_service.png')" width="180px" class="py-8" />
                  </td>
                </tr>
                <tr>
                  <td>取机人签字：</td>
                </tr>
                <tr>
                  <td>取机日期：{{caseData.repaired_time | formatDate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="downloadReport">下载</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PDF from "@/common/pdf";
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      productParts: [
        {
          num: 1,
          name: 'CH661-00471',
          quantity: 1,
        },
        {
          num: 2,
          name: 'CH661-83844',
          quantity: 1,
        },
        // {
        //   num: 3,
        //   name: 'CH661-45832',
        //   quantity: 1,
        // },
        // {
        //   num: 4,
        //   name: 'CH661-01939',
        //   quantity: 1,
        // },
        // {
        //   num: 5,
        //   name: 'CH661-34837',
        //   quantity: 1,
        // },
      ],
    }
  },
  created() {
    this.countPartsList()
  },
  methods: {
    downloadReport() {
      this.$nextTick(() => {
       this.$vuetify.goTo(0)
        setTimeout(() => {
          PDF.downloadPDF(this.$refs.report, '服务报告')
        }, 500)
      })
    },
    countPartsList() {
      this.productParts = this.caseData.part_name || []
      this.productParts = this.productParts.map((part, index) => {
        // let name = this.caseData.part_describe[index] ? this.caseData.part_describe[index] : ''
        return {
          num: index + 1,
          name: part,
          quantity: 1
        }
      })
      let l = this.productParts.length
      if (l < 5) {
        let arr = []
        for (let i=0; i<=(5-l-1); i++) {
          arr.push({
            num: l+i+1,
            name: '',
            quantity: '',
          })
        }
        this.productParts = this.productParts.concat(arr)
      }
    },
    doAction(action) {
      switch (action) {
        case "open_case": {
          this.dialog = true
          break;
        }
      }
    },
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.gray-img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.reportTable,
.productTable,
.confirmTable {
  width: 100%;
  border-collapse: collapse;
}
.reportTable td,
.productTable td,
.confirmTable td {
  padding: 0 4px;
  height: 30px;
  border: 1px solid rgb(0, 0, 0);
  color: #000 !important;
}
.reportTable td {
  width: 25%;
}
.productTable td {
  width: 16.66%;
}
.confirmTable td {
  width: 50%;
}
.tipsTableList {
  list-style: decimal;
}
.productTable .noborder {
  border: 0;
}
.reportTable>tbody>tr>td+td,
.productTable>tbody>tr>td+td,
.confirmTable>tbody>tr>td+td {
  border-left-width: 0;
}
.reportTable>tbody>tr+tr>td,
.productTable>tbody>tr+tr>td,
.confirmTable>tbody>tr+tr>td {
  border-top-width: 0;
}
.reportTable .noLeftBorder,
.productTable .noLeftBorder{
  border-left-width: 0;
}
.reportTable .noRightBorder,
.productTable .noRightBorder {
  border-right-width: 0;
}
</style>
