<template>
  <v-app-bar
    elevation="0"
    v-scroll="onScroll"
    dense
    style="margin: 0 auto;"
    dark
    :app="app"
  >
    <div class="sec-bar">
      <v-row>
        <div class="page-title">
          <span class="btn-text">{{ title }}</span>
        </div>
        <div class="flex-grow-1"></div>
        <v-btn text v-if="barType === 'kb'" @click="goBackToKb"><v-icon left>arrow_back</v-icon>返回</v-btn>
        <v-menu offset-y v-if="barType == 'menu'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>assignment</v-icon>
              <span class="btn-text">提交申请</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in barData"
              :key="index"
              @click="goToPage(item.link)"
            > 
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              <v-list-item-title>
                <span class="menu-text">{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="barType == 'button'"
          text
        >
          <v-icon>assignment</v-icon>
          <span class="btn-text" @click="$router.push(buttonLink)">{{ buttonText }}</span>
        </v-btn>
      </v-row>
    </div>
  </v-app-bar>
  <!-- <v-app-bar
      elevation="0"
      v-scroll="onScroll"
      dense
      style="margin: 0 auto;"
      dark
      :app="app"
    >
      <div class="sec-bar">
        <v-row>
          <div class="page-title">
            <span class="btn-text">客户支持</span>
          </div>
          <div class="flex-grow-1"></div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>assignment</v-icon>
                <span class="btn-text">提交申请</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="goToPage()"
              > 
                <v-icon class="mr-2">mdi-heart</v-icon>
                <v-list-item-title>
                  <span class="menu-text">123</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            text
          >
            <v-icon>assignment</v-icon>
            <span class="btn-text" @click="$router.push(buttonLink)">456</span>
          </v-btn>
        </v-row>
      </div>
    </v-app-bar> -->
</template>

<script>
export default {
  data: () => ({
    app: false,
  }),
  props: {
    title: {
      type: String,
      default: '二级栏目'
    },
    barType: {
      type: String,
      default: null
    },
    barData: {
      type: Array,
      default: null
    },
    buttonLink: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '提交申请'
    },
  },
  methods: {
    onScroll () {
      this.app = window.scrollY > 64
    },
    goToPage(link) {
      this.$router.push(link)
    },
    goBackToKb() {
      let pId = ''
      let open = ''
      if (this.$route.query.pId) pId = this.$route.query.pId
      if (this.$route.query.open) open = this.$route.query.open
      this.$router.push('/knowledge?pId=' + pId + '&open=' + open)
    },
  }
}
</script>

<style scoped>
.sec-bar {
  width: 1600px;
  margin: 0 auto;
  padding: 0 30px;
}
.page-title {
  height: 36px;
  line-height: 36px;
  font-size: 0.925rem;
}
.page-title span {
  padding-left: 10px;
  border-left: 4px solid #fff;
}
.menu-text {
  font-size: 0.875rem;
}
</style>