<template>
  <v-card
    max-width="232"
    class="mt-4"
    elevation="12"
  >
    <v-navigation-drawer floating permanent  :mini-variant="$vuetify.breakpoint.xs">
      <v-list nav v-if="currentUser">
        <widgets-profile-dialogs :account="currentUser._id">
          <v-list-item link>
            <v-list-item-avatar>
              <v-img :src="avatarSrc ? ossURL +'/'+ avatarSrc : '/static/grey_silhouette.png'" aspect-ratio="1"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title font-weight-regular">{{ username }}</v-list-item-title>
              <v-list-item-subtitle>{{ company }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </widgets-profile-dialogs>

        <!-- <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">{{ username }}</v-list-item-title>
            <v-list-item-subtitle>{{ company }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <v-divider></v-divider>
      <v-list
        nav
      >
        <v-list-item-group v-model="navIndex" mandatory>
          <v-list-item
            v-for="(item, i) in navs"
            :key="i"
            @click="goToPage(item.link)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CHECK_AUTH
} from '@/store/actions.type'
export default {
  data: () => ({
    navIndex: 0,
    navs: [],
    defaultNavs: [
      { text: '仪表盘', icon: 'dashboard', link: '/account' },
      { text: '设备管理', icon: 'widgets', link: '/asset' },
      { text: '服务请求', icon: 'assignment', link: '/workbench' },
      // { text: '账号详情', icon: 'account_circle', link: '/info' },
      { text: '服务报告', icon: 'analytics', link: '/analysis' },
      { text: '人员管理', icon: 'supervisor_account', link: '/staff' },
    ],
    username: '',
    company: '',
    avatarSrc: '',
  }),
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.navIndex = this.index
    this.getShowList()
  },
  methods: {
    goToPage(link) {
      this.$router.push(link)
    },
    getShowList() {
      this.$store.dispatch(CHECK_AUTH).then(res=>{
        this.avatarSrc = res.personal.profile
        this.username = res.personal.name
        this.company = res.employer.ownerId.name
        let ads = res.hasAdmin
        let isAd = 0
        ads.forEach( item => {
          if (item == 'enterprise') {
            return isAd = 1
          }
        })
        if (isAd) {
          this.navs = this.defaultNavs
        }else {
          this.navs = this.defaultNavs.concat()
          this.navs.splice(this.defaultNavs.length-2,2)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
