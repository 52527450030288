<template>
  <v-row v-if="caseData && repairPhaseStep">
    <v-col cols="2" class="text-right">维修费用</v-col>
    <v-col cols="4" class="text--secondary" v-if="caseData.repair_price">{{caseData.repair_price | formatMoney}}  元</v-col>
    <v-col cols="4" class="text--secondary" v-else>价格估算中...</v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep === 2">确认时间</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep === 2"><span v-if="caseData.confirm_repair_time">{{caseData.confirm_repair_time | formatTime}}</span><span v-else>-</span></v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep === 2">备件订购日期</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep === 2"><span v-if="caseData.gsx_create_time">{{caseData.gsx_create_time | formatTime}}</span><span v-else>-</span></v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4">故障描述</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4"><pre v-html="caseData.detail_fault"></pre></v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4">解决方案</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4">{{caseData.detail_solve || '-'}}</v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4">测试问题</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep !== 1 && repairPhaseStep !== 4">{{caseData.detail_problem || '-'}}</v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep === 4">使用备件</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep === 4">{{caseData.part_name ? caseData.part_name.join(',') : '-'}}</v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep === 4">备件描述</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep === 4">
      <div v-if="caseData.part_describe && caseData.part_describe.length"><span v-for="(part, index) in caseData.part_describe" :key="index+'_part'">{{part | partCNText}}<span v-if="index < caseData.part_describe.length - 1">，</span></span></div>
      <div v-else><span>-</span></div>
    </v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep >= 5">取回方式</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep >= 5">
      <span v-if="caseData.returns && caseData.returns.express_corp">{{ expressCorp.find(item => item.code === caseData.returns.express_corp).name }}</span>
      <span v-else>未填写</span>
    </v-col>
    <v-col cols="2" class="text-right" v-if="repairPhaseStep >= 5">快递单号</v-col>
    <v-col cols="4" class="text--secondary" v-if="repairPhaseStep >= 5">
      <span v-if="caseData.returns && caseData.returns.express_number">{{ caseData.returns.express_number }}</span>
      <span v-else>未填写</span>
    </v-col>
  </v-row>
</template>
<script>
import {mapGetters} from "vuex";
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      objCurrentStep: {},
      phaseStatus: 1,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['repairPhaseStep']),
    expressCorp () {
      return Util.categories('expressRepair')
    },
    diagnosisState () {
      return Util.categories('diagnosisState')
    },
    diagnosisClass () {
      return Util.categories('diagnosisClass')
    },
    diagnosisRepair () {
      return Util.categories('diagnosisRepair')
    },
  },
  created() {
    this.setPhaseStatus()
  },
  
  methods: {
    setPhaseStatus() {
      this.objCurrentStep = this.caseData.steps.find(o => o.isCurrent)
      this.phaseStatus = parseInt(this.objCurrentStep.step_num) + 1
    },
  }
}
</script>

<style scoped>
pre {
  white-space: pre-line;

  white-space: -moz-pre-line;

  white-space: -pre-line;

  white-space: -o-pre-line;

  word-wrap: break-word;

  word-break: break-all;

  overflow: hidden;

  font-family: inherit;

}
</style>
