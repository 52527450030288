<template>
  <div class="con1600 oGrey">
    
    
    <v-app-bar
      color="#424242"
      dense
      dark
      elevation="0"
      height="64px"
      max-width="1600px"
      class="appBar-mg"
      style="z-index: 200;"
    >
      <v-img :src="require('@/assets/logo.png')" height="40px" max-width="40px" class="logo" @click="$router.push('/')" style="cursor: pointer;"></v-img>
      <v-btn text large @click="expandCard">
        <span class="nav-btn">服务</span>
        <v-icon>expand_more</v-icon>
      </v-btn>
      <!-- <v-menu
        transition="slide-y-transition"
        bottom
        min-width="100%"
        nudge-bottom="55"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            text
            v-bind="attrs"
            v-on="on"
          >
            <span class="nav-btn">服务</span>
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-card v-if="showGameCard" color="#f3f3f4" width="100%" min-height="100px" rounded="0" flat class="gameCard">
          <div class="w1600">
            <v-card color="#f3f3f4" width="100%" rounded="0" flat>
              <div class="w980">
                <v-slide-group
                  class="pa-4"
                  :show-arrows="true"
                >
                  <v-slide-item
                    v-for="(item,i) in card"
                    :key="i"
                  >
                    <v-card
                      class="ma-4 icon-card"
                      height="100"
                      width="100"
                      flat
                      color="#f3f3f4"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                        @click="goToEvent"
                      >
                        <v-col :cols="12">
                          <v-row justify="center">
                            <v-icon class="cardIcons">{{item.icon}}</v-icon>
                          </v-row>
                        </v-col>
                        <v-col :cols="12">
                          <v-row justify="center">
                            <span class="cardText">服务</span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-menu> -->
      <v-spacer></v-spacer>

      <v-btn text large @click="$router.push('/register')">
        <span class="nav-btn">注册</span>
      </v-btn>

      <v-btn text large @click="$router.push('/support')">
        <span class="nav-btn">支持</span>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text large v-bind="attrs" v-on="on">
            <span class="nav-btn">企业中心</span>
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300px">
          <div style="padding: 10px 0;">
            <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img src="https://cdn.vuetifyjs.com/images/john.png" alt="John" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>尼古拉斯赵四</v-list-item-title>
                <v-list-item-subtitle
                  >倍升互联（北京）科技有限公司</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </div>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item @click="$router.push('/account')">
                <v-list-item-icon>
                  <v-icon>account_box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>账户</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>settings</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>企业</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>注销</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-card v-if="showGameCard" color="#f3f3f4" width="100%" min-height="100px" rounded="0" flat class="gameCard">
      <div class="w1600">
        <v-card color="#f3f3f4" width="100%" rounded="0" flat>
          <div class="w980">
            <v-slide-group
              class="pa-4"
              :show-arrows="true"
            >
              <v-slide-item
                v-for="(item,i) in card"
                :key="i"
              >
                <v-card
                  class="ma-4 icon-card"
                  height="100"
                  width="100"
                  flat
                  color="#f3f3f4"
                >
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                    @click="goToEvent"
                  >
                    <v-col :cols="12">
                      <v-row justify="center">
                        <v-icon class="cardIcons">{{item.icon}}</v-icon>
                      </v-row>
                    </v-col>
                    <v-col :cols="12">
                      <v-row justify="center">
                        <span class="cardText">{{item.text}}</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-card>
      </div>
    </v-card>
    <div class="shadowbg" v-show="showGameCard" @click="hideCard"></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    switch_off: false,
    switch_on: true,
    showGameCard: false,
    card: [
      {color: 'primary', icon: 'settings_remote',text:'诊断'},
      {color: 'secondary', icon: 'find_replace',text:'维修'},
      {color: 'accent', icon: 'backup',text:'回收'},
      {color: 'error', icon: 'open_with',text:'激活'},
      {color: 'info', icon: 'perm_data_setting',text:'发放'},
      {color: 'success', icon: 'settings',text:'部署'},
      {color: 'warning', icon: 'settings_phone',text:'运维'},
      {color: 'primary', icon: 'explore',text:'其它'},
      {color: 'secondary', icon: 'shopping_basket',text:'其它'},
    ],
    items: [
      { text: '账户', icon: 'account_box' },
      { text: '其他', icon: 'settings' },
      { text: '注销', icon: 'logout' },
    ],
    serviceGroup: null,
  }),
  methods: {
    expandCard() {
      this.showGameCard = !this.showGameCard
      console.log(this.showGameCard)
    },
    hideCard() {
      this.showGameCard = false
    },
    hideCardtwo() {
      if (this.showGameCard) {
        this.showGameCard = false
        console.log(this.showGameCard)
      }
    },
    goTo() {
      this.showGameCard = false
      this.$router.push('/apply')
    },
    goToEvent() {
      this.showGameCard = false
      this.$router.push('/event')
    },
  }
}
</script>
<style scoped>
.v-list {
  padding: 0 !important;
}
ul {
  padding: 0;
  overflow: hidden;
}
ul li {
  float: left;
  list-style: none;
}
.posterNav {
  width: 157px;
  height: 180px;
  margin: 15px 20px;
  margin-left: 0;
  border: 1px solid #ccc;
  transition: all .3s;
  -webkit-animation-name: Navbar-dropdown-drop-in-delayed;
          animation-name: Navbar-dropdown-drop-in-delayed;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.posterNav:hover {
  border-color: #000;
}
.logo {
  margin-right: 10px;
}
.logo-text {
  font-weight: 200;
  margin-left: 10px;
}
.con1600 {
  width: 100%;
  margin: 0 auto;
}
.oGrey {
  background-color: #424242;
}
.appBar-mg {
  margin: 0 auto;
}
.nav-btn {
  font-weight: 200;
  font-size: 1.2rem;
}
.con1600 .gameCard {
  position: absolute;
  left: 0;
  top: 64px;
  z-index: 200;
  background-color: #f3f3f4;
}
.w1600 {
  max-width: 1600px;
  margin: 0 auto;
}
.cardIcons {
  font-size: 50px !important;
  color: #000 !important;
}
.cardText {
  font-size: 0.85rem;
  color: #b4b4b4;
  transition: all 0.3s;
}
.icon-card {
  cursor: pointer;
}
.icon-card:hover .cardText {
  color: #000;
}
@keyframes Navbar-dropdown {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 164px;
    opacity: 1;
  } 
}
@keyframes Navbar-dropdown-drop-in-delayed {
  0% {
    opacity: 0.0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); 
  }
  100% {
    opacity: 1.0;
    -webkit-transform: translateY(0);
            transform: translateY(0); 
  } 
}
.posterNav {
  cursor: pointer;
}
.posterNav.delay-1 {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
}
.posterNav.delay-1 {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}
.posterNav.delay-2 {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.posterNav.delay-3 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.posterNav.delay-4 {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}
.posterNav.delay-5 {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
.posterNav.delay-6 {
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms;
}
.posterNav.delay-7 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.posterNav.delay-8 {
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms;
}
.posterNav.delay-9 {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}
.card-btn {
  margin: 10px 5px;
  text-align: center;
}
.card-btn .tc {
  color: #000;
  margin-left: 5px;
}
.shadowbg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.7);
  z-index: 100;
}
.w980 {
  max-width: 1060px;
  margin: 0 auto;
}
</style>