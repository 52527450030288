import ApiService from "@/common/http";
import JwtService from "@/common/jwt";
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
} from "./actions.type";
import { SET_AUTH, SET_USER, PURGE_AUTH, SET_ERROR, SET_ROUTERS, SET_LOGS } from "./mutations.type";

const state = {
  user: {},
  employer: {},
  isAuthenticated: !!JwtService.getToken(),
  routes: [],
};

const getters = {
  currentUser: state => state.user,
  currentEmployer: state => state.employer,
  isAuthenticated: state => state.isAuthenticated,
  currentRoutes: state => state.routes,
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('username', credentials.username);
      form.append('password', credentials.password);
      let param = new URLSearchParams(form);
      ApiService.post("/auth/local", param)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/auth/mine")
          .then(({ data }) => {
            context.commit(SET_USER, data);
            resolve(data)
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.message);
            context.commit(PURGE_AUTH);
            reject(response.data.message)
          });
      } else {
        context.commit(PURGE_AUTH);
        // reject({message:'token is null'})
      }
    })
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      context.commit(SET_LOGS, { operator: state.user._id, action: 'Logout', objType: 'Account', objId: [state.user._id] });
      context.commit(PURGE_AUTH)
      resolve()
    })
  }
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    JwtService.saveToken(token);
  },
  [SET_USER](state, data) {
    let arrPermission = [], arrService = [];
    data.employer.roles.forEach(item => {
      arrPermission.push(item.permission)
      arrService.push(item.service)
    })
    state.user = Object.assign(data, { permission: [...new Set(arrPermission.flat())], service: [...new Set(arrService.flat())] });
    state.employer = data.employer;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.employer = null;
    JwtService.destroyToken();
  },
  [SET_ROUTERS](state, routers) {
    state.routes = routers;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
