<template>
  <v-card-text class="text-center">
    <v-data-table
      :headers="headerProduct"
      :items="editedItem"
      :server-items-length="editedItem.length"
      :loading="loadingList"
      :footer-props="{'items-per-page-options': [5, 10, 15]}"
      item-key="_id"
      return-object="false"
      dense
    >
      <template v-slot:item.asset="{ item }">
        <td nowrap="true">{{item.asset ? item.asset : 0}}</td>
      </template>
    </v-data-table>
  </v-card-text>
</template>
<script>
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    },
  },
  data() {
    return {
      currentTask: {},
      headerProduct: [
        { text: '产品名称', value: 'product_name', width: "100px"},
        { text: '产品型号', value: 'part_number', width: "100px" },
        { text: '产品类型', value: 'product_type.name', width: "100px" },
        { text: '产品品牌', value: 'product_vendor.name', width: "100px" },
        { text: '需交付数量', value: 'quantity', width: "100px" },
        { text: '已导入数量', value: 'asset', width: "100px" },
      ],
      loadingList: true,
      editedItem: [],
    }
  },
  watch: {
    dlgInfo (val) {
      val || this.closeInfo()
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.loadingList = true
      if (this.caseData.tasks_list) this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
      if (this.caseData.product_list) {
        this.editedItem = this.caseData.product_list.concat()
        this.loadingList = false
      }
    },
    closeInfo() {
      this.dlgInfo = false
    },
  }
}

</script>
