<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="openItem(getId)">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition" :retain-focus="false" eager>
      
        <v-card v-if="itemData && itemData.enterprise" :loading="monthlyLoading ? 'secondary' : false">
          <v-card-title>
            {{itemData.enterprise.name}}【{{itemData.month ? itemData.year : itemData.year - 1}}年{{itemData.month ? itemData.month : 12}}月】统计报告
            <!-- <v-chip class="ma-2" label small v-if="itemData.isRelease" color="primary">已发布</v-chip>
            <v-chip class="ma-2" label small v-else color="secondary">未发布</v-chip> -->
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div ref="chartDialog">
            <v-expansion-panels v-model="openPanel" multiple accordion flat hover>
              <v-expansion-panel>
                <v-expansion-panel-header>统计图表</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-for="(report, index) in itemData.data_item">
                    <report-bar-simple
                      v-if="report.graph === 'bar'"
                      :width="windowSize.x - 75 +'px'"
                      height="300px"
                      :label="report.label"
                      :data="report.data"
                      :title="report.name"
                      :key="index">
                    </report-bar-simple>
                    <report-line-stack
                      v-if="report.graph === 'line'"
                      :width="windowSize.x - 75 +'px'"
                      :title="report.name"
                      :label="report.label"
                      :data="JSON.parse(report.data)"
                      height="300px"
                      :key="index">
                    </report-line-stack>
                    <report-bar-polar
                        v-if="report.graph === 'barpolar'"
                        :width="windowSize.x - 75 +'px'"
                        height="600px"
                        :label="report.label"
                        :data="JSON.parse(report.data)"
                        :title="report.name"
                        :key="index">
                      >
                    </report-bar-polar>
                    <report-bar-stack
                        v-if="report.graph === 'stack'"
                        :width="windowSize.x - 75 +'px'"
                        height="300px"
                        :label="report.label"
                        :data="JSON.parse(report.data)"
                        :title="report.name"
                        :legend="report.legend"
                        :key="index">
                    </report-bar-stack>
                    <v-alert
                      border="top"
                      color="grey lighten-3"
                      class="font-weight-light body-2"
                      v-if="report.analyse"
                      :key="index+'analyse'"
                      >
                      <span class="font-weight-regular secondary--text">图表分析：</span>
                      <br>
                      <pre>{{ report.analyse }}</pre>
                    </v-alert>
                    <v-alert
                        border="top"
                        color="grey lighten-3"
                        class="font-weight-light body-2"
                        v-if="report.propose"
                        :key="index+'propose'"
                    >
                      <span class="font-weight-regular secondary--text">计划建议：</span>
                      <br>
                      <pre>{{ report.propose }}</pre>
                    </v-alert>
                    <v-divider class="my-4" :key="index +'_hr'"></v-divider>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="itemData.propose">
                <v-divider></v-divider>
                <v-expansion-panel-header>附加内容</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- <editor v-if="activeEdit" v-model="editedItem.propose" :init="editorInit" :id="'report_'+ getId"></editor> -->
                  <div v-html="itemData.propose"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="dlChartPdf('pdf')" color="secondary">下载图表pdf</v-btn>
            <v-btn text @click="dlChartPdf('pic')" color="secondary">下载图表图片</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialog">关闭</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_MONTHLY_REPORT,
  EDIT_MONTHLY_REPORT,
  PUBLISH_MONTHLY_REPORT,
} from "@/store/actions.type";
import PDF from "@/common/pdf";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      itemData: {},
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      editedItem: {
        propose: '',
      },
      defaultItem: {
        propose: '',
      },
      valid: true,
      openPanel:[],
      windowSize: {
        x: 0,
        y: 0,
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      activeEdit: false,
    }
  },
  computed: {
    ...mapGetters(['currentEmployer', 'currentUser', 'monthlyLoading', 'uploadSign']),
  },
  mounted() {
    this.onResize()
    this.activeEdit = true
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    dlChartPdf(type) {
      let dlgEl = this.$refs.chartDialog
      let pdfName = this.itemData.enterprise.name + '【' + this.itemData.year + '年' + this.itemData.month + '月】统计报告'
      PDF.downloadPDF(dlgEl, pdfName, type)
    },
    closeDialog() {
      this.dialog = false
      this.$emit('Close')
      this.$nextTick(() => {
        this.itemData = Object.assign({}, {})
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    openItem(id) {
      store.dispatch(FETCH_MONTHLY_REPORT, id)
        .then((data) => {
          this.itemData = Object.assign({}, data)
          this.editedItem.propose = data.propose
          this.dialog = true
          this.openPanel.push(0)
          this.openPanel.push(1)
        })
    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    saveReport() {
      let strData = {
        _id: this.getId,
        data_item: this.itemData.data_item,
        propose: this.editedItem.propose,
      }

      this.$store.dispatch(EDIT_MONTHLY_REPORT, strData)
        .then(({data}) => {
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          this.itemData = Object.assign({}, data)
          this.editedItem.propose = data.propose
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    publishReport() {
      let strData = {
        _id: this.getId,
        data_item: this.itemData.data_item,
        propose: this.editedItem.propose,
      }

      this.$store.dispatch(EDIT_MONTHLY_REPORT, strData)
        .then(() => {
          store.dispatch(PUBLISH_MONTHLY_REPORT, { _id: this.getId, reply: 1 })
            .then(({data}) => {
              store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'})
              this.itemData = Object.assign({}, data)
              this.editedItem.propose = data.propose
            })
            .catch((err) => {
              store.commit(SET_ERROR, {msg: err})
            });
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  destroyed () {
    this.activeEdit = false
  }
}
</script>