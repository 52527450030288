<template>
  <div>
    <v-card-actions>
      <v-subheader>更新时间：{{ caseData.updateTime | formatTime }}</v-subheader>
      <v-subheader v-if="caseData.status < 2 && caseData.accept_sla">
        当前环节：<widgets-baseTimer :endTime="caseData.accept_sla.expectTime" :alertThreshold="caseData.accept_sla.sla_limit / 2" :warningThreshold="caseData.accept_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-subheader v-if="caseData.status < 2 && caseData.total_sla">
        整体流程：<widgets-baseTimer :endTime="caseData.total_sla.expectTime" :alertThreshold="caseData.total_sla.sla_limit / 2" :warningThreshold="caseData.total_sla.sla_limit" type="text"/>
      </v-subheader>
      <v-subheader v-if="caseData.express_code && caseData.express_number">
        <case-express-info :id="caseData._id" from="case">物流信息</case-express-info>
      </v-subheader>

      <v-spacer></v-spacer>

      <v-btn text v-if="caseData.isMark" @click="markCase(caseData._id, false)"><v-icon left>star</v-icon>取消关注</v-btn>
      <v-btn text v-else @click="markCase(caseData._id, true)"><v-icon left>star_outline</v-icon>特别关注</v-btn>
      <v-btn text v-if="caseData.order_id && caseData.link_asset && caseData.link_asset.length" @click.stop="exportAsset(caseData)"><v-icon left>save_alt</v-icon>导出设备</v-btn>

      <div v-if="caseData.isEdit">
        <div v-if="caseData.status === 0">
          <v-btn text @click="doAction('cancel', caseData._id)" :loading="exeing"><v-icon left>remove_circle</v-icon>取消</v-btn>
          <v-btn text color="secondary" :disabled="!editedTask.valid" @click="doAction('submit', caseData._id)" :loading="exeing"><v-icon left>check_circle</v-icon>提交</v-btn>
        </div>
        <div v-if="caseData.status === 1">
          <v-btn text v-if="currentTask.task.actions.includes('hangup')" @click="closeDialog()" :loading="exeing"><v-icon left>pause_circle_filled</v-icon>挂起</v-btn>
          <v-btn text v-if="currentTask.task.actions.includes('cancel')" @click="doAction('cancel', caseData._id)" :loading="exeing"><v-icon left>remove_circle</v-icon>取消</v-btn>
          <v-btn text v-if="currentTask.task.actions.includes('assist')" @click="doAction('assist', caseData._id)" :loading="exeing"><v-icon left>assistant_direction</v-icon>协助</v-btn>
          <v-btn text @click="doAction('reject', caseData._id)" :loading="exeing"><v-icon left>replay_circle_filled</v-icon>驳回</v-btn>
          <v-btn text v-if="currentTask.task.actions.includes('save')" @click="doAction('save', caseData._id)" :loading="exeing"><v-icon left>save</v-icon>保存</v-btn>
          <v-btn text v-if="currentTask.task.actions.includes('complete')" color="secondary" @click="doAction('submit', caseData._id)" :disabled="!editedTask.valid" :loading="exeing"><v-icon left>check_circle</v-icon>提交</v-btn>
        </div>
      </div>
      <div v-else>
        <v-btn text @click="closeDialog()">关闭</v-btn>
      </div>
    </v-card-actions>
    <v-dialog v-model="dlgAction" persistent max-width="350px">
      <v-card>
        <v-card-title>确认<span class="font-weight-medium">{{ actionTitle }}</span>该服务请求？</v-card-title>
        <v-card-text>
          {{actionLabel}}
          <v-autocomplete
            class="mt-4"
            v-if="actionKey === 'goto_assist'"
            v-model="department"
            :items="getDeptList(departments[0])"
            @change="doAction('set_employee_list')"
            item-text="name"
            item-value="value"
            autocomplete="off"
            outlined
            dense
            label="请选择协助执行部门"
          ></v-autocomplete>
          <v-autocomplete
            v-if="actionKey === 'goto_assist'"
            v-model="editor"
            :items="accountList"
            ref="selectAccount"
            item-text="personal.name"
            item-value="_id"
            autocomplete="off"
            outlined
            dense
            label="协助人员"
            hint="请选择协助执行人员"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea
            v-if="caseData.accept_sla && (new Date() > new Date(caseData.accept_sla.expectTime))"
            outlined
            v-model="action_remarks"
            class="mt-4"
            label="超时原因说明"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('cancel_action')">取消</v-btn>
          <v-btn text color="secondary" @click="doAction(actionKey, actionID)">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  ASSIST_CASE,
  CANCEL_CASE,
  FETCH_ACCOUNT_LIST,
  FETCH_DEPT_LIST,
  PUBLISH_CASE,
  REMOVE_CASE_ITEM,
  SAVE_CASE,
  SUBMIT_CASE,
  REJECT_CASE
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import { mapGetters } from "vuex";
import ApiService from "@/common/http";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    editedTask: {
      type: Object,
    }
  },
  data() {
    return {
      currentTask: {},
      dlgAction: false,
      actionKey: '',
      actionTitle: '',
      actionID: '',
      actionLabel: '',
      action_remarks: '',
      department: '',
      editor: '',
      exeing: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'departments', 'accountList']),
  },
  mounted() {},
  watch: {},
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.currentTask = this.caseData.tasks_list.find(item => item.isActive)
    },
    closeDialog() {
      this.$emit('Close')
    },
    doAction(action, caseId) {
      this.exeing = true
      switch (action) {
        case 'cancel': {
          this.dlgAction = true;
          this.actionTitle = '取消';
          this.actionLabel = '取消后的服务请求将被关闭，不能再次执行。同时释放该服务请求关联的资产设备。';
          this.actionID = caseId
          this.actionKey = 'goto_cancel'
          this.exeing = false
          break
        }
        case 'cancel_action': {
          this.dlgAction = false;
          this.exeing = false
          break
        }
        case 'goto_cancel': {
          store.dispatch(CANCEL_CASE, {form: 'service', caseId: this.actionID, remarks: this.action_remarks})
            .then(() => {
              store.commit(SET_ERROR, {msg: '工单已被取消', color: 'primary'});
              this.dlgAction = false;
              this.$emit('Update', this.actionID)
              this.exeing = false
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
              this.exeing = false
            })
          break
        }
        case 'reject': {
          this.dlgAction = true;
          this.actionTitle = '驳回';
          this.actionLabel = '驳回工单后，工单将返回上一个处理任务，并清楚当前环节任务及保存信息。';
          this.actionID = caseId
          this.actionKey = 'goto_reject'
          this.exeing = false
          break
        }
        case 'goto_reject': {
          let strUpdate = Object.assign({}, this.editedTask)
          strUpdate.form = 'service'
          strUpdate.caseId = this.caseData._id
          strUpdate.remark = this.action_remarks
          strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
          store.dispatch(REJECT_CASE, strUpdate)
              .then(() => {
                store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
                this.dlgAction = false;
                this.exeing = false
                this.closeDialog();
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
                this.exeing = false
              })
          break
        }
        case 'submit': {
          this.dlgAction = true;
          this.actionTitle = '提交';
          this.actionLabel = '提交后的服务请求将进入下一环节处理。';
          this.actionID = caseId
          this.actionKey = 'goto_submit'
          this.exeing = false
          break
        }
        case 'goto_submit': {
          let strUpdate = Object.assign({}, this.editedTask)
          strUpdate.form = 'service'
          strUpdate.caseId = this.caseData._id
          strUpdate.remark = this.action_remarks
          strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''
          store.dispatch(SUBMIT_CASE, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
              this.dlgAction = false;
              this.exeing = false
              this.closeDialog();
              // this.$emit('Update', this.actionID)
            })
            .catch((error) => {
              this.exeing = false
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'assist': {
          store.dispatch(FETCH_DEPT_LIST).then(() =>{
            this.actionTitle = '协助';
            this.actionLabel = '选择协助处理人，确认后该工单将指派到协助人处理。';
            this.actionID = caseId
            this.actionKey = 'goto_assist'
            this.dlgAction = true;
            this.exeing = false
          })
          break
        }
        case 'set_employee_list': {
          this.editor = null
          this.$refs.selectAccount.internalSearch = null;
          if (this.department) {
            store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.department)
          }
          this.exeing = false
          break
        }
        case 'goto_assist': {
          if (!this.editor) {
            store.commit(SET_ERROR, {msg: '请选择协助人'});
            this.exeing = false
            return
          }
          let strUpdate = Object.assign({}, this.editedTask)
          strUpdate.form = 'service'
          strUpdate.caseId = this.caseData._id
          strUpdate.editor = this.editor
          strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''

          store.dispatch(ASSIST_CASE, strUpdate)
            .then(() => {
              this.dlgAction = false;
              this.$emit('Update', this.actionID)
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
              this.exeing = false
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
              this.exeing = false
            })

          break
        }
        case 'save': {
          this.dlgAction = true;
          this.actionTitle = '保存';
          this.actionLabel = '保存工单后，您将成为该工单现阶段的处理人，在提交到下一阶段前，该环节的任务字段是可以编辑的。';
          this.actionID = caseId
          this.actionKey = 'goto_save'
          this.exeing = false
          break
        }
        case 'goto_save': {
          let strUpdate = Object.assign({}, this.editedTask)
          strUpdate.form = 'service'
          strUpdate.caseId = this.caseData._id
          strUpdate.remark = this.action_remarks
          strUpdate.taskId = this.caseData.current_task && this.caseData.current_task._id || ''

          store.dispatch(SAVE_CASE, strUpdate)
            .then(() => {
              this.dlgAction = false;
              this.$emit('Update', this.actionID)
              this.exeing = false
              store.commit(SET_ERROR, {msg: '操作成功。', color: 'primary'});
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
              this.exeing = false
            })

          break
        }
      }
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
    markCase(caseId, result) {
      let actCode = PUBLISH_CASE
      if (!result) actCode = REMOVE_CASE_ITEM
      store.dispatch(actCode, {form: 'service/bookmake/'+ caseId, data: {}})
          .then(() => {
            store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'});
            this.$emit('Update', caseId)
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
    },
    exportAsset(caseInfo) {
      ApiService.download('/asset/export?page=1&limit=1000&enterprise='+ caseInfo.enterprise._id +'&caseId='+ caseInfo._id, {
        responseType:'blob'
      })
          .then(resp => {
            let fileName = caseInfo.case_number +'_资产设备清单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            store.commit(SET_ERROR, {msg: err});
          });
    },
  },
}
</script>
<style scoped>
</style>
