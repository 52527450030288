<template>
  <v-snackbar multi-line :color="color" v-model="show" :timeout="timeout" bottom>
    {{message}}
    <v-spacer></v-spacer>
    <template v-slot:action="{ attrs }">
      <v-btn text icon v-bind="attrs" @click="show = false">
        <v-icon>highlight_off</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data () {
    return {
      color: 'secondary',
      show: false,
      message: '',
      timeout: 6000,
    }
  },
  created: function () {
    this.$store.watch(state => state.error.msg, () => {
      const msg = this.$store.state.error.msg
      if (msg !== '') {
        this.show = true
        this.message = this.$store.state.error.msg
        this.color = this.$store.state.error.color
        this.$store.commit('setError', {msg:'', color:''})
      }
    })
  }
}
</script>
