<template>
  <v-row v-if="caseData && caseData.rent_info">
    <v-col cols="2" class="text-right">租赁方式</v-col>
    <v-col cols="4" class="text--secondary">{{ caseData.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁' }}</v-col>
<!--    <v-col cols="2" class="text-right">起租日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ caseData.rent_info.lease_start | formatDate }}</v-col>-->
    <v-col cols="2" class="text-right">租期</v-col>
    <v-col cols="4" class="text--secondary">{{ caseData.rent_info.lease_term / 365 + '年' }}</v-col>
<!--    <v-col cols="2" class="text-right">到期日期</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ caseData.rent_info.expiry_date | formatDate }}</v-col>-->
    <v-col cols="2" class="text-right">支付周期</v-col>
    <v-col cols="4" class="text--secondary">
      <span v-if="caseData.rent_info.payment_cycle === 0">月付</span>
      <span v-else-if="caseData.rent_info.payment_cycle === 1">季付</span>
      <span v-else>半年付</span>
    </v-col>
    <v-col cols="2" class="text-right">账期</v-col>
    <v-col cols="4" class="text--secondary">{{ caseData.rent_info.payment_period + '天' }}</v-col>
    <v-col cols="2" class="text-right">总期数</v-col>
    <v-col cols="4" class="text--secondary">{{ caseData.rent_info.total_period + '期' }}</v-col>
<!--    <v-col cols="2" class="text-right">客户付款日</v-col>-->
<!--    <v-col cols="4" class="text&#45;&#45;secondary">{{ caseData.rent_info.payment_day }}</v-col>-->
  </v-row>
</template>
<script>
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {}
}
</script>
