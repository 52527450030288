<template>
  <v-card
    v-bind="$attrs"
    class="v-card--material mt-4"
  >
    <v-card-title class="align-start">
      <v-sheet
        :color="color"
        :width="fullHeader ? '100%' : undefined"
        class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
        elevation="6"
        max-width="100%"
        rounded
      >
        <v-theme-provider dark>
          <div
            v-if="icon"
            :class="iconSmall ? 'pa-5' : 'pa-6'"
          >
            <v-icon
              :large="!iconSmall"
              v-text="icon"
            />
          </div>
          <div v-if="$slots.heading">
            <slot name="heading" />
          </div>
          <div
            v-if="!icon && !$slots.title && title"
            class="text-h4 white--text pa-4 v-card--material__title"
          >
            {{ title }}
          </div>
        </v-theme-provider>
      </v-sheet>

      <div
        v-if="hasTitle"
        class="pl-4 v-card--material__title mt-2"
      >
        <slot name="title" />

        <template v-if="(icon || $slots.title) && title">
          {{ title }}
        </template>

        <div class="text-subtitle-1">
          <slot
            v-if="$slots.subtitle"
            name="subtitle"
          />

          <div
            v-else-if="subtitle"

            v-text="subtitle"
          />
        </div>
      </div>
    </v-card-title>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialCard',

    props: {
      color: String,
      fullHeader: Boolean,
      icon: String,
      iconSmall: Boolean,
      reveal: Boolean,
      subtitle: String,
      title: String,
    },

    computed: {
      hasTitle () {
        return (
          this.title ||
          this.subtitle ||
          this.$slots.title ||
          this.$slots.subtitle
        )
      },
    },
  }
</script>

<style lang="sass">
.v-card--material__title
  font-size: 1.2rem
  font-weight: 300
</style>
