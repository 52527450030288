<template>
  <v-row>
    <v-col cols="2" class="text-right">诊断结论</v-col>
    <v-col cols="10" class="text--secondary"><pre class="body-1">{{caseData.diagnosis && caseData.diagnosis.verdict}}</pre></v-col>
    <v-col cols="2" class="text-right">设备状态</v-col>
    <v-col cols="4" class="text--secondary">
      {{caseData.diagnosis && caseData.diagnosis.state && diagnosisState.find(item => item.code === caseData.diagnosis.state).name }}
    </v-col>
    <v-col cols="2" class="text-right">设备种类</v-col>
    <v-col cols="4" class="text--secondary">
      {{caseData.diagnosis && caseData.diagnosis.class && diagnosisClass.find(item => item.code === caseData.diagnosis.class).name }}
    </v-col>
    <v-col cols="2" class="text-right">异常原因</v-col>
    <v-col cols="4" class="text--secondary">{{caseData.diagnosis && caseData.diagnosis.reason}}</v-col>
    <v-col cols="2" class="text-right">维修类型</v-col>
    <v-col cols="4" class="text--secondary">
      {{caseData.diagnosis && caseData.diagnosis.repair && diagnosisRepair.find(item => item.code === caseData.diagnosis.repair).name }}
    </v-col>
    <v-col cols="12" v-if="caseData.diagnosis && caseData.diagnosis.report">
      <v-card outlined tile class="text-center">
        <v-img max-height="275px" contain :src="ossURL + '/'+ caseData.diagnosis.report" >
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Util from "@/common/util";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      objCurrentStep: {},
      phaseStatus: 1,
    }
  },
  mounted() {},
  computed: {
    expressCorp () {
      return Util.categories('expressRepair')
    },
    diagnosisState () {
      return Util.categories('diagnosisState')
    },
    diagnosisClass () {
      return Util.categories('diagnosisClass')
    },
    diagnosisRepair () {
      return Util.categories('diagnosisRepair')
    },
  },
  created() {
    this.setPhaseStatus()
  },
  
  methods: {
    setPhaseStatus() {
      this.objCurrentStep = this.caseData.steps.find(o => o.isCurrent)
      this.phaseStatus = parseInt(this.objCurrentStep.step_num) + 1
    },
  }
}
</script>
