<template>
  <v-row id="RepairPhase">
    <v-col align="center">
      <case-repair-phase-progress :phaseSteps="caseData.steps"></case-repair-phase-progress>
      <case-repair-phase-step :phaseSteps="caseData.steps"></case-repair-phase-step>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
}
</script>