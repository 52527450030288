<template>
  <div>
    <span :color="color" dark text class="v-list-item--link" @click="doAction('open_case')">
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    <v-dialog v-model="dialog" persistent scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-form v-model="valid" @submit.prevent="submitCase()">
        <v-card v-if="caseData && caseData.catalog && caseData.creator && caseData.enterprise && caseData.account" :loading="caseLoading ? 'secondary' : false">
          <case-card-title :caseData="caseData" caseType="repair" v-on:Close="closeDialog"></case-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <case-repair-phase :caseData="caseData" v-if="caseData.catalog.name === Global.REPAIR_CATALOG_TEXT"></case-repair-phase>
            <v-divider v-if="caseData.catalog.name === Global.REPAIR_CATALOG_TEXT"></v-divider>
            <v-expansion-panels v-model="openPanel" multiple accordion flat hover>
              <v-expansion-panel>
                <v-expansion-panel-header>基本信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-repair-phase-info-basic :caseData="caseData" v-if="caseData.catalog.name === Global.REPAIR_CATALOG_TEXT"></case-repair-phase-info-basic>
                   <case-repair-basic-info :caseData="caseData" v-else></case-repair-basic-info>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>处理信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <case-repair-phase-info-processing :caseData="caseData" v-if="caseData.catalog.name === Global.REPAIR_CATALOG_TEXT"></case-repair-phase-info-processing>
                  <case-repair-info-processing :caseData="caseData" v-else></case-repair-info-processing>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-divider></v-divider>
                <v-expansion-panel-header>详细信息</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-tabs>
                        <v-tab>关联设备</v-tab>
                        <v-tab>附件</v-tab>
                        <v-tab>备注</v-tab>
                        <v-tab-item>
                          <case-link-asset :caseData="caseData" :maxLimit=1 caseType="repair" assetKey="link_asset"></case-link-asset>
                        </v-tab-item>
                        <v-tab-item>
                          <widgets-files-list entityType="CaseRepair" :entityId="getId" :type=1></widgets-files-list>
                        </v-tab-item>
                        <v-tab-item>
                          <widgets-remarks-list entityType="CaseRepair" :entityId="getId" :type=0></widgets-remarks-list>
                        </v-tab-item>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-divider></v-divider>
        <case-repair-actions :caseData="caseData" :editedTask="editedItem" v-on:Close="closeDialog" @Update="updateDialog"></case-repair-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import {
  FETCH_CASE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import store from "@/store";
import Util from "@/common/util";
export default {
  props: {
    getId: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      caseData: {},
      openPanel: [],
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      editedItem: {
        service_branch: '',
        out_req_id: '',
        repair_price: '',
        returns: {},
        express_number: '',
        express_corp: '',
        diagnosis: {},
      },
      defaultItem: {
        service_branch: '',
        out_req_id: '',
        repair_price: '',
        returns: {},
        express_number: '',
        express_corp: '',
        diagnosis: {}
      },
      valid: true,
      diagnosisReason: [],
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['currentEmployer',  'caseLoading', 'uploadSign', 'repairPhaseStep']),
    expressCorp () {
      return Util.categories('expressRepair')
    },
    diagnosisState () {
      return Util.categories('diagnosisState')
    },
    diagnosisClass () {
      return Util.categories('diagnosisClass')
    },
    diagnosisRepair () {
      return Util.categories('diagnosisRepair')
    },
  },
  mounted() { },
  created() {
    if (this.isOpen) {
      this.doAction('open_case')
    }
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
    repairPhaseStep(val) {
      if (val === 1) {
        this.openPanel = [0]
      } else if (val >= 5) {
        this.openPanel = [0,1]
      } else {
        this.openPanel = [1]
      }
    },
  },
  methods: {
    updateEditTask(info) {
      this.editedTask = info
    },
    closeDialog () {
      this.dialog = false
      this.$emit('Close')
      this.$nextTick(() => {
        this.caseData = Object.assign({}, {})
        this.editedItem = Object.assign({}, this.defaultItem)
        this.openPanel = []
      })
    },
    updateDialog() {
      this.getCase(this.getId)
    },
    getCase(id) {
      store.dispatch(FETCH_CASE, {form: 'repair', query: id})
        .then((data) => {
          this.caseData = Object.assign({}, data)
          this.caseData['imgLogo'] = this.caseData.link_asset[0].product.vendor.imgLogo || ''
          this.caseData['asset_sn'] = this.caseData.link_asset.map(a => a.serial_number).join(',')
          this.caseData['asset_product'] = this.caseData.link_asset.map(a => a.product.name).join(',')
          this.caseData['asset_imei'] = this.caseData.link_asset.map(a => a.imei_number).join(',')
          this.caseData['link_asset'] = this.caseData.link_asset.map(a => a._id)

          if (this.repairPhaseStep === 1) {
            this.openPanel.push(0)
          } else if (this.repairPhaseStep >= 5) {
            this.openPanel.push(0)
            this.openPanel.push(1)
          }else {
            this.openPanel.push(1)
          }
        })
    },
    doAction(action) {
      switch (action) {
        case 'open_case': {
          this.getCase(this.getId)
          this.dialog = true
          break
        }
      }
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-dialog__content {z-index: 203 !important;}
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
