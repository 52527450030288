import Vue from "vue"
import Vuex from "vuex"
import auth from "./auth.module"
import account from "./account.module"
import dealer from "./dealer.module"
import enterprise from "./enterprise.module"
import error from "./error.module"
import page from "./page.module"
import catalog from "./catalog.module"
import material from "./material.module"
import employee from "./employee.module"
import group from "./group.module"
import role from "./role.module"
import service from "./service.module"
import branch from "./branch.module"
import department from "./department.module"
import settings from "./settings.module"
import tools from "./tools.module"
import product from "./product.module"
import asset from "./asset.module"
import logs from "./logs.module"
import report from "./report.module"
import cases from "./case.module"
import workflow from "./workflow.module"
import knowledge from "./knowledge.module"
import knowledge_catalog from "./knowledge_catalog.module"
import monthly from "./monthly.module"
import order from "./order.module"
import notes from './notes.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth, 
    dealer, 
    error, 
    account, 
    enterprise, 
    page, catalog, 
    material, 
    employee, 
    group, 
    role, 
    service, 
    branch, 
    settings, 
    tools, 
    department, 
    product, 
    asset, 
    logs, 
    report, 
    cases, 
    workflow,
    knowledge,
    knowledge_catalog,
    monthly,
    order,
    notes
  }
});
