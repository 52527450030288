import Vue from "vue";
import m from 'moment'
m.locale('zh-cn'); // 使用中文
// Filter - FormatTime
// DateTime > '2019/1/31 00:00:00'
Vue.filter('formatTime', function(val) {
  let time = val;
  if (isNaN(Date.parse(val))) {
    if (val) time = val.replace(/-/g, '/').replace(/T/g, ' ');
  }
  let date = new Date(time);
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('/')
  const t2 = [hour, minute, second].map(formatNumber).join(':')

  return `${t1} ${t2}`
});
// Filter - fromDate
// String > 4 年前
Vue.filter('fromDate', function (val) {
  let date = new Date(val);
  return m(date).fromNow();
});

// Filter - FormatDate
// Date > 'YYYY-MM-DD'
Vue.filter('formatDate', function (val) {
  if (val) {
    let value = new Date(val);
    let year = value.getFullYear();
    let month = padDate(value.getMonth() + 1);
    let day = padDate(value.getDate());
    return year +'-'+ month +'-'+ day;
  }
});

// Filter - Repair Code to Name
Vue.filter('repairStatus', function (val) {
  if (val === 0) return '待受理';
  if (val === 1) return '检测中/维修中';
  if (val === 2) return '维修完成';
  if (val === 3) return '待返回/待取机';
  if (val === 4) return '服务结束';
  if (val === 8) return '取消服务';
});

Vue.filter('repairReturn', function (val) {
  if (val === 'self') return '自取';
  if (val === 'express') return '快递';
});

// Filter - Status Code to Name
Vue.filter('caseStatus', function (val) {
  if (val === 0) return '待受理';
  if (val === 1) return '处理中';
  if (val === 2) return '已取消';
  if (val === 3) return '已取消';
  if (val === 4) return '已完成';
});

Vue.filter('expressState', function (val) {
  if (val === 0) return '在途';
  if (val === 1) return '揽收';
  if (val === 2) return '疑难';
  if (val === 3) return '签收';
  if (val === 4) return '退签';
  if (val === 5) return '派件';
  if (val === 6) return '退回';
  if (val === 7) return '转投';
  if (val === 10) return '待清关';
  if (val === 11) return '清关中';
  if (val === 12) return '已清关';
  if (val === 13) return '清关异常';
  if (val === 14) return '拒签';
})

// Filter - toCNText
// String > 账户
Vue.filter('stringModule', function (val) {
  if (val === 'Account') return '人员';
  if (val === 'Asset') return '资产';
  if (val === 'Produce') return '产品';
  if (val === 'Workflow') return '流程';
  if (val === 'white') return '纯白色';
  if (val === 'primary') return '倍升灰';
  if (val === 'transparent') return '透明色';
  if (val === 'display-4') return '4号标题';
  if (val === 'display-3') return '3号标题';
  if (val === 'display-2') return '2号标题';
  if (val === 'display-1') return '1号标题';
  if (val === 'headline') return '大标题';
  if (val === 'title') return '标题';
  if (val === 'subheading') return '子标题';
  if (val === 'red') return '标准红';
  if (val === 'info') return '标准黑';
  if (val === 'secondary') return '倍升红';
  if (val === 'purple') return '标准紫';
  if (val === 'deep-purple') return '深紫色';
  if (val === 'indigo') return '靛蓝色';
  if (val === 'blue') return '标准蓝';
  if (val === 'light-blue') return '浅蓝色';
  if (val === 'cyan') return '青蓝色';
  if (val === 'teal') return '水绿色';
  if (val === 'green') return '标准绿';
  if (val === 'light-green') return '浅绿色';
  if (val === 'lime') return '柠檬色';
  if (val === 'yellow') return '标准黄';
  if (val === 'amber') return '琥珀色';
  if (val === 'orange') return '橘橙色';
  if (val === 'deep-orange') return '深橙色';
  if (val === 'brown') return '棕褐色';
  if (val === 'blue-grey') return '青灰色';
  if (val === 'grey') return '标准灰';
  if (val === 'primary') return '倍升灰';
  if (val === 'white') return '纯白色';
  if (val === '#f5f5f5') return '浅灰色';
  if (val === 'start') return '开始位置';
  if (val === 'center') return '中间位置';
  if (val === 'end') return '结尾位置';
  if (val === 'baseline') return '起点位置';
  if (val === 'stretch') return '拉伸';
  if (val === 'space-around') return '居中分散';
  if (val === 'space-between') return '两端分散';
  if (val === 'Create') return '创建';
  if (val === 'Update') return '修改';
  if (val === 'Delete') return '删除';
  if (val === 'Sequence') return '串行';
  if (val === 'Synchronization') return '并行';
  if (val === 'Choice') return '执行其一';
})


function padDate (va) {
  va = va < 10 ? '0'+va : va;
  return va;
}
function formatNumber(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
Vue.filter('formatMoney', function (value) {
  if (!value) return '0.00';
  let intPart = Number(value).toFixed(0); // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断
  let floatPart = '.00'; // 预定义小数部分
  let value2Array = value.split('.');
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString(); // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return intPartFormat + '.' + floatPart + '0';
    } else {
      return intPartFormat + '.' + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
})

// Filter - partCNText
// String > partEn
Vue.filter('partCNText', function (val) {
  if (val.includes('Logic Board') || val.includes('MLB')) return '主板';
  if (val.includes('Battery')) {
    if (val.includes('Top Case, with Battery')) {
      return '键盘组件（含电池）';
    } else if (val.includes('Bottom Case with Battery')) {
      return '底壳组件（带电池）';
    } else if (val.includes('Battery Board Cable')) {
      return '电池板电缆';
    } else {
      return '电池';
    }
  }
  if (val.includes('Trackpad')) {
    if (val.includes('Trackpad and Keyboard Flex Cable') || val.includes('Trackpad Cable, Flex')) {
      return '触控板线缆';
    } else if (val.includes('Trackpad Flex Cable')) {
      return '触控板线缆';
    } else if (val.includes('Magic Trackpad')) {
      return '外置触控板';
    } else {
      return '触控板';
    }
  }
  if (val.includes('Touch ID')) return '触控ID';
  if (val.includes('Display')) return '屏幕组件';
  if (val.includes('Fan')) return '风扇';
  if (val.includes('LAS')) return '角度传感器';
  if (val.includes('Bottom Cover')) return '底壳';
  if (val.includes('Intel Xeon')) return 'Intel 处理器';
  if (val.includes('MAGIC KYBDW')) return '外置键盘';
  if (val.includes('MAGIC MOUSE')) return '妙控鼠标';
  if (val.includes('Graphics Board')) return '显卡';
  if (val.includes('Power Adapter')) return '适配器';
  if (val.includes('Microphone Cable')) return '麦克风线缆';
  if (val.includes('BMU Flex Cable')) return 'BMU线缆';
  if (val.includes('USB-C Board')) return 'USB-C板';
  if (val.includes('iPad')) return 'iPad';
  if (val.includes('SSD') || val.includes('Flash Storage') || val.includes('TB')) return '存储器';
  if (val.includes('I/O') || val.includes('IO Board') || val.includes('Board, Right Input/Output')) return 'I/O板';
  if (val.includes('Speaker Pair') || val.includes('Speaker Short Grommet')) return '扬声器';
  if (val.includes('Memory') || val.includes('MEM')) return '内存';
  if (val.includes('Power Supply') || val.includes('POWER SUPPLY')) return '电源';
  if (val.includes('Audio Board') || val.includes('Audio Jack')) return '音频板';
  if (val.includes('eDP Flex Cable') || val.includes('Cowling, eDP')) return 'EDP线缆';
  if (val.includes('Housing') || val.includes('Enclosure')) return '外壳';

  return val;
})
