import { ToolsService } from "@/common/http";
import {
  FETCH_UPLOAD,
  CHECK_ADDR,
  FETCH_URL,
  SEND_SMS_CODE,
  CHECK_SMS_CODE,
  SEND_EMAIL_CODE,
  FETCH_EXPRESS,
} from "./actions.type";

import {
  SET_ERROR,
  SET_SIGN,
  SET_URL,
} from "./mutations.type";

const state = {
  uploadSign: "",
  urlSign: "",
};

const getters = {
  uploadSign: state => state.uploadSign,
  urlSign: state => state.urlSign,
};

const actions = {
  [FETCH_UPLOAD]({ commit }, type = 'public') {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sign/upload", "?type="+ type)
        .then(({ data }) => {
          commit(SET_SIGN, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, {message: error});
          reject(error);
        });
    });
  },
  [CHECK_ADDR](context, credentials) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/check/address", "?address="+ encodeURI(credentials))
        .then(({ data }) => {
          resolve(data);
        }).catch(({ response }) => {
          reject(response);
        })
    });
  },
  [FETCH_URL]({ commit }, name) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sign/download", "?name="+ encodeURIComponent(name))
        .then(({ data }) => {
          commit(SET_URL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, {message: error});
          reject(error);
        });
    });
  },
  [SEND_SMS_CODE](context, sms) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sms/"+ sms.phone, "?template="+ sms.type)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [SEND_EMAIL_CODE](context, email) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/email/"+ email.to, "?template="+ email.type)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [CHECK_SMS_CODE](context, sms) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('code', sms.code);
      let param = new URLSearchParams(form);
      ToolsService.put("/sms/"+ sms.phone +"?template="+ sms.type, param)
        .then(({ data }) => {
          resolve(data);
        }).catch(({ response }) => {
          reject(response);
        })
    });
  },
  [FETCH_EXPRESS](context, slug) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/express/"+ slug.id, slug.query)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
};

const mutations = {
  [SET_SIGN](state, sign) {
    state.uploadSign = sign;
  },
  [SET_URL](state, sign) {
    state.urlSign = sign;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
