<template>
  <v-tabs>
    <v-tab>产品</v-tab>
    <v-tab>附件</v-tab>
    <v-tab>备注</v-tab>
    <v-tab-item>
      <v-card-text class="px-0">
        <v-data-table
          :headers="productHeaders"
          :items="linkedProduct"
          class="elevation-0 my-4"
          :items-per-page="100"
          hide-default-footer
          item-key="index"
          dense
        >
          <template
            v-slot:body.append
            v-if="orderData.product_list && orderData.product_list.length"
          >
            <tr class="grey lighten-3 secondary--text">
              <td>合计</td>
              <!-- <td></td> -->
              <td></td>
              <td>
                <span>
                  {{ orderData.total_quantity }}
                </span>
              </td>
              <td></td>
              <td></td>
              <!-- <td></td>
              <td>
                ¥{{
                  Number(orderData.total_price.$numberDecimal).toFixed(2)
                    | formatMoney
                }}
              </td>
              <td></td> -->
              <td></td>
              <!-- <td v-if="orderData.status === 0"></td> -->
            </tr>
          </template>
          <template v-slot:item.product_name="{ item, index }">
            <td
              :nowrap="open[index]?false:true"
              :class="!open[index]?'text-truncate':''"
              style="max-width: 200px; cursor: pointer;"
              @click="showText(index)"
            >
              {{ item.product_name }}
            </td>
          </template>
          <template v-slot:item.product_text="{ item, index }">
            <td
              v-if="item.product_text"
              style="max-width: 150px; cursor: pointer;"
              :nowrap="open[index]?false:true"
              :class="!open[index]?'text-truncate text-decoration-underline':'text-decoration-underline'"
              @click="showText(index)"
            >
              {{ item.product_text }}
            </td>
            <td v-else>
              无
            </td>
          </template>
          <template v-slot:item.quantity="{ item }">
            <span v-if="item.count">
              {{ item.count.order }}
            </span>
          </template>
          <template v-slot:item.quantityInCase="{ item }">
            <span v-if="item.count">
              {{ item.count.cases }}
            </span>
          </template>
          <template v-slot:item.quantityCancel="{ item }">
            <span v-if="item.count">
              {{ item.count.cancel }}
            </span>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-if="orderData.type === 0">
              ¥{{ Number(item.sales_price) }}
            </span>
            <span v-else>
              ¥{{ Number(item.rent_price) }}
            </span>
          </template>
          <template v-slot:item.total_period v-if="orderData.type === 1">
            <span>
              {{ orderData.rent_info.total_period }}
            </span>
          </template>
          <template v-slot:item.sum_price="{ item }">
            ¥{{ Number(item.sum_price) }}
          </template>
          <template v-slot:item.part_number="{ item }">
            <td
              v-if="isEdit && orderData.status <= 1 && item.count.cases === 0"
              nowrap="true"
              style="cursor: pointer"
              @click="editProInfo(item)"
            >
              <v-icon small>edit</v-icon>
              {{ item.part_number || "无" }}
            </td>
            <td v-else nowrap="true">
              {{ item.part_number || "无" }}
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom v-if="isEdit && orderData.status === 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  @click="removeLinked(item)"
                >
                  <v-icon>remove</v-icon>
                </v-btn>
              </template>
              <span>移除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-row v-if="isEdit && orderData.status === 0">
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn block text color="secondary" @click="newProDlg = true"
              ><v-icon left>link</v-icon>关联产品</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-tab-item>
    <v-tab-item>
      <widgets-files-list entityType="PurchaseOrder" :entityId="orderId" :type=1></widgets-files-list>
    </v-tab-item>
    <v-tab-item>
      <widgets-remarks-list entityType="PurchaseOrder" :entityId="orderId" :type=0></widgets-remarks-list>
    </v-tab-item>
    <v-dialog v-model="newProDlg" scrollable persistent max-width="600px">
      <v-form v-model="validNewPro" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span>增加产品</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeEditPro"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mb-4">
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  ref="myBox"
                  v-model="newProInfo.part_number"
                  :items="partNoBox"
                  :search-input.sync="checkItem"
                  @change="changeProInfo"
                  type="text"
                  return-object
                  item-text="part_number"
                  item-value="part_number"
                  label="产品型号"
                  :error-messages="errPartNo"
                  hint="请输入产品型号 如：MGXC2CH/A"
                  outlined
                  :loading="isCheckProLoading"
                  dense
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.product_name"
                  label="产品名称"
                  :rules="[rules.required]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.quantity"
                  label="总数量"
                  :rules="[rules.required]"
                  type="number"
                  outlined
                  dense
                  :disabled="disableNum"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="orderData.type === 0">
                <v-text-field
                  v-model="newProInfo.sales_price"
                  type="number"
                  label="销售价格"
                  :rules="[rules.required]"
                  outlined
                  dense
                  :disabled="disablePrice"
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="orderData.type === 1">
                <v-text-field
                  v-model="newProInfo.rent_price"
                  type="number"
                  label="租赁价格"
                  :rules="[rules.required]"
                  outlined
                  dense
                  :disabled="disablePrice"
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.product_vendor"
                  :items="vendorList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品品牌"
                  hint="请选择产品品牌"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.product_type"
                  :items="typeList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品类型"
                  hint="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="newProInfo.product_text"
                  label="产品描述"
                  no-resize
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="grey darken-1" text @click="closeEditPro">
              取消
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary darken-1"
              text
              @click="saveEditPro"
              :disabled="!validNewPro"
            >
              提交
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="proInfoDlg" persistent scrollable width="700">
      <widgets-product-dialogs
        :id="activeProId"
        :key="activeProId"
        v-on:Close="proInfoDlg = false"
      ></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgConflict" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5"
          >产品型号{{
            editedItem.part_number
          }}已经存在，请重新填写产品型号或关联现有型号产品。</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="clearPartNo"
            >重新填写型号</v-btn
          >
          <v-btn color="secondary" text @click="linkPresent"
            >关联现有产品</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-tabs>
</template>

<script>
import axios from "axios";
import {
  EDIT_ORDER_PURCHASE,
  FETCH_PRODUCT_LIST,
  FETCH_UPLOAD,
  FETCH_URL,
  CHECK_PRODUCT,
} from "@/store/actions.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
      },
      productHeaders: [
        { text: "产品型号", value: "part_number" },
        { text: "产品名称", value: "product_name" },
        { text: "总数量", value: "quantity" },
        { text: "已开单数量", value: "quantityInCase" },
        { text: "品牌", value: "product_vendor.name" },
        { text: "产品类型", value: "product_type.name" },
      ],
    //   productHeaders: [
    //     { text: "产品型号", value: "part_number" },
    //     { text: "产品名称", value: "product_name" },
    //     { text: "产品描述", value: "product_text" },
    //     { text: "总数", value: "quantity" },
    //     { text: "已开单数", value: "quantityInCase" },
    //     { text: "已取消数", value: "quantityCancel" },
    //     { text: "单价", value: "price" },
    //     { text: "小计", value: "sum_price" },
    //     { text: "品牌", value: "product_vendor.name" },
    //     { text: "产品类型", value: "product_type.name" },
    //     { text: "操作", value: "actions", sortable: false },
    //   ],
      linkedProduct: [],
      editedItem: {
        files: [],
        remarks: [],
      },
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
      activeImage: {},
      dlgImage: false,
      activeProId: "",
      proInfoDlg: false,
      editedRemarks: {
        title: "",
        context: "",
      },
      defaultRemarks: {
        title: "",
        context: "",
      },
      dlgRemarks: false,
      editIndex: -1,
      editProduct: {},
      newProDlg: false,
      newProInfo: {
        product_name: "",
        quantity: "",
        sales_price: "",
        rent_price: "",
        product_vendor: "",
        part_number: "",
        product_type: "",
        product_text: "",
      },
      defaultProInfo: {
        product_name: "",
        quantity: "",
        sales_price: "",
        rent_price: "",
        product_vendor: "",
        part_number: "",
        product_type: "",
        product_text: "",
      },
      dlgConflict: false,
      validNewPro: true,
      checkItem: null,
      errPartNo: '',
      partNoBox: [],
      isCheckProLoading: false,
      open: {},
      disableNum: false,
      disablePrice: false,
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "uploadSign",
      "productCount",
      "vendorList",
      "typeList",
      "processList",
    ]),
  },
  watch: {
    orderData() {
      this.initialize()
    },
    checkItem(val) {
      this.errPartNo = "";
      if (val == "" || !val) {
        clearTimeout(this.countDownPro);
        return;
      } else {
        if (this.countDownPro) {
          clearTimeout(this.countDownPro);
        }
        this.countDownPro = setTimeout(() => {
          if (this.isCheckProLoading) return;
          this.isCheckProLoading = true;
          let type = ''
          this.orderData.type === 0 ? type = 'order' : type = 'rent'
          store
            .dispatch(CHECK_PRODUCT, "selected="+ type +"&limit=100&partno=" + val)
            .then((data) => {
              this.partNoBox = data.product;
              this.isCheckProLoading = false;
            })
            .catch((error) => {
              this.isCheckProLoading = false;
              store.commit(SET_ERROR, { msg: error });
            });
        }, 500);
      }
    },
  },
  methods: {
    showText(i) {
      this.open[i]= !this.open[i]
      this.$forceUpdate()
    },
    changeProInfo() {
      let t = typeof this.newProInfo.part_number;
      if (t === "string") {
        return
      } else if (t === "object" && this.newProInfo.part_number) {
        let pro = this.newProInfo.part_number;
        this.newProInfo.product_name = pro.name;
        this.newProInfo.quantity = this.newProInfo.quantity || 1;
        if (pro.vendor) this.newProInfo.product_vendor = pro.vendor._id;
        if (pro.product_type) this.newProInfo.product_type = pro.product_type._id;
        if (pro.product_text) this.newProInfo.product_text = pro.product_text;
        this.newProInfo.part_number = pro.part_number;
      }
    },
    clearPartNo() {
      this.newProInfo.part_number = "";
      this.dlgConflict = false;
    },
    linkPresent() {
      let num = -1;
      this.linkedProduct.map((item) => {
        if (item.part_number === this.newProInfo.part_number) num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {
          msg: "关联列表中已包含此产品",
          color: "primary",
        });
      } else {
        store
          .dispatch(
            FETCH_PRODUCT_LIST,
            "key=" + encodeURIComponent(this.newProInfo.part_number)
          )
          .then((data) => {
            let pro = data.product.map((item) => {
              let p = Object.assign({}, item);
              p.quantity = 0;
              p.product_name = p.name;
              p.product_vendor = p.vendor;
              p.sales_price = item.sales_price.$numberDecimal || 0;
              p.sum_price = 0;
              p.ordered_quantity = 0;
              return p;
            });
            let pros = JSON.parse(JSON.stringify(this.linkedProduct));
            pros.push(pro[0]);
            this.setProductList(pros);
            this.dlgConflict = false;
            this.closeEditPro();
          });
      }
    },
    editProInfo(item) {
      if (item) {
        if(this.orderData.status === 1) {
          this.disableNum = true
          this.disablePrice = true
        }
        let type = ''
        this.orderData.type  === 0 ? type = 'order' : type = 'rent'
        store.dispatch(CHECK_PRODUCT, "selected="+ type +"&limit=100&partno=" + item.part_number).then((data) => {
          this.partNoBox = data.product;
          this.isCheckProLoading = false;
          this.newProInfo = JSON.parse(JSON.stringify(item));
          this.editedIndex = this.linkedProduct.indexOf(item);
          this.oldPartNo = item.part_number;
        }).catch((error) => {
          this.isCheckProLoading = false;
          store.commit(SET_ERROR, { msg: error });
        });
      }
      this.newProDlg = true;
    },
    saveEditPro() {
      this.$refs.myBox.blur();
      this.$nextTick(() => {
        if (this.editedIndex > -1) {
          let proList = JSON.parse(JSON.stringify(this.linkedProduct))
          let num = -1;
          this.linkedProduct.map((item) => {
            if (
              item.part_number &&
              item.part_number === this.newProInfo.part_number
            )
              num += 1;
          });
          if (num > -1) {
            if (this.oldPartNo === this.newProInfo.part_number) {
              Object.assign(
                proList[this.editedIndex],
                this.newProInfo
              );
              this.setProductList(proList)
            } else {
              this.errPartNo = "请重新填写";
              store.commit(SET_ERROR, {
                msg: "关联列表中已包含此型号产品",
                color: "primary",
              });
            }
          } else {
            Object.assign(proList[this.editedIndex], this.newProInfo);
            this.setProductList(proList)
          }
        } else {
          let num = -1;
          this.linkedProduct.map((item) => {
            if (
              item.part_number &&
              item.part_number === this.newProInfo.part_number
            )
              num += 1;
          });
          if (num > -1) {
            this.errPartNo = "重新填写";
            store.commit(SET_ERROR, {
              msg: "关联列表中已包含此型号产品",
              color: "primary",
            });
          } else {
            let proList = JSON.parse(JSON.stringify(this.linkedProduct))
            proList.push(this.newProInfo)
            this.setProductList(proList)
          }
        }
      });
    },
    closeEditPro() {
      this.newProDlg = false;
      this.$nextTick(() => {
        this.newProInfo = JSON.parse(JSON.stringify(this.defaultProInfo));
        this.editedIndex = -1;
        this.oldPartNo = "";
      });
    },
    setProductList(list) {
      let json = {};
      json.id = this.orderId;
      let obj = {};
      list.map(item => {
        if (this.orderData.type === 0) {
          delete item.rent_price
        }else {
          delete item.sales_price
        }
      })
      obj.product_list = list;
      json.item = obj;
      store
        .dispatch(EDIT_ORDER_PURCHASE, json)
        .then(() => {
          this.closeEditPro()
          store.commit(SET_ERROR, { msg: "修改成功。", color: "primary" });
          this.$emit("Update");
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    removeLinked(item) {
      if (this.linkedProduct.length === 1) {
        store.commit(SET_ERROR, {
          msg: "订单至少包含一种产品",
          color: "primary",
        });
      } else {
        let proIndex = this.linkedProduct.indexOf(item);
        if (proIndex > -1) {
          this.linkedProduct.splice(proIndex, 1);
          this.setProductList(this.linkedProduct);
        }
      }
    },
    openProInfo(id) {
      this.activeProId = id;
      this.proInfoDlg = true;
    },
    initialize() {
      if (this.orderData.product_list) {
        this.orderData.product_list.forEach((item) => {
          if(this.orderData.type === 0)item.sales_price = item.sales_price.$numberDecimal;
          if(this.orderData.type === 1)item.rent_price = item.rent_price.$numberDecimal;
          item.sum_price = item.sum_price.$numberDecimal;
        });
        this.linkedProduct = JSON.parse(
          JSON.stringify(this.orderData.product_list)
        );
        this.checkOrderStatus()
      }
      if (this.orderData.files && this.orderData.files.length) {
        this.orderData.files.forEach((file) => {
          if (
            file.suffix.toLowerCase() === "jpeg" ||
            file.suffix.toLowerCase() === "jpg" ||
            file.suffix.toLowerCase() === "png" ||
            file.suffix.toLowerCase() === "gif"
          ) {
            store.dispatch(FETCH_URL, file.href).then((fileurl) => {
              file.preUrl = fileurl;
              this.$forceUpdate();
            });
          }
        });
      }
    },
    checkOrderStatus() {
      if (this.orderData.status !== 0) {
        this.productHeaders.find(item => {
          if (item.value === 'actions') {
            item.align = ' d-none'
          }
        })
      }
    },
    zoomFile(objImage) {
      this.activeImage = objImage;
      this.dlgImage = true;
    },
    closeImage() {
      this.activeImage = {};
      this.dlgImage = false;
    },
    downloadFile(additional) {
      store
        .dispatch(FETCH_URL, additional)
        .then((fileurl) => {
          window.location.href = fileurl;
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    deleteFile(file) {
      let strUpdate = {};
      this.orderData.files.splice(this.orderData.files.indexOf(file), 1);
      strUpdate.id = this.orderData._id;
      strUpdate.item = {};
      strUpdate.item["files"] = this.orderData.files.concat(
        this.editedItem.files
      );
      store
        .dispatch(EDIT_ORDER_PURCHASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, { msg: "删除成功。", color: "primary" });

        })
        .catch((err) => {
          this.editedItem["files"].push(file);
          this.$store.commit(SET_ERROR, { msg: err });
        });
    },
    async removeFile(file) {
      let strUpdate = {};
      await this.editedItem["files"].splice(
        this.editedItem["files"].indexOf(file),
        1
      );
      strUpdate.id = this.orderData._id;
      strUpdate.item = {};
      strUpdate.item["files"] = this.orderData.files.concat(
        this.editedItem.files
      );
      store
        .dispatch(EDIT_ORDER_PURCHASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, { msg: "删除成功。", color: "primary" });
        })
        .catch((err) => {
          this.editedItem["files"].push(file);
          this.$store.commit(SET_ERROR, { msg: err });
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },
    uploadFile(index) {
      this.Upload.fileIndex = index;
      store
        .dispatch(FETCH_UPLOAD, "private")
        .then(this.$refs.uploadFile.click())
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    async upload(file) {
      let fileFullName = file.name,
        fileSuffix = fileFullName.substring(fileFullName.lastIndexOf(".") + 1);
      fileFullName = fileFullName.replace(/[%#&]/g,"");
      this.Upload.uploading = true;
      let param = new FormData();
      param.append("name", fileFullName);
      param.append("key", "order/" + this.uploadSign.key + "/" + fileFullName);
      param.append("policy", this.uploadSign.policy);
      param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
      param.append("success_action_status", 200);
      param.append("signature", this.uploadSign.signature);
      param.append("file", file, fileFullName);

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.Upload.progressUpload = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };
      await axios
        .post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadFile.value = "";

          if (this.Upload.fileIndex > -1) {
            await this.editedItem.files.splice(this.Upload.fileIndex, 1, {
              creator: this.currentUser._id,
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get("key"),
            });
          } else {
            await this.editedItem.files.push({
              creator: this.currentUser._id,
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get("key"),
            });
          }
          let strUpdate = {}
          strUpdate.id = this.orderData._id
          strUpdate.item = {}
          strUpdate.item["files"] = this.orderData.files.concat(
            this.editedItem.files
          );
          await store.dispatch(EDIT_ORDER_PURCHASE, strUpdate).then(() => {
            this.editedItem = { files: [], remarks: [] }
            this.$emit("Update")
          }).catch((err) => {
            store.commit(SET_ERROR, { msg: err.message })
          });
        })
        .catch((error) => {
          this.Upload.uploading = false
          this.Upload.progressUpload = 0
          this.$refs.uploadInput.value = ""
          store.commit(SET_ERROR, { msg: error.message })
        });
    },
    blockRemark(item) {
      let [ ...arrRemarks ] = this.orderData.remarks
      let editIndex = arrRemarks.indexOf(item)
      if (editIndex > -1) {
        arrRemarks[editIndex].isBlock = true
        arrRemarks[editIndex].context = arrRemarks[editIndex].context + ' - ' +this.$options.filters['formatTime'](new Date())
      }
      let strUpdate = {}
      strUpdate.id = this.orderData._id
      strUpdate.item = {};
      strUpdate.item["remarks"] = arrRemarks

      store.dispatch(EDIT_ORDER_PURCHASE, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '取消成功。', color: 'primary'});
          this.$emit("Update")
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err});
        })
    },
    async removeRemark(item) {
      let strUpdate = {};

      await this.editedItem.remarks.splice(
        this.editedItem.remarks.indexOf(item),
        2
      );
      strUpdate.id = this.orderData._id;
      strUpdate.item = {};
      strUpdate.item["remarks"] = this.orderData.remarks.concat(
        this.editedItem.remarks
      );
      store
        .dispatch(EDIT_ORDER_PURCHASE, strUpdate)
        .then(() => {
          this.$emit("Update")
          store.commit(SET_ERROR, { msg: "删除成功。", color: "primary" });
        })
        .catch((err) => {
          this.editedItem["remarks"].push(item);
          this.$store.commit(SET_ERROR, { msg: err });
        });
    },
    editRemarks() {
      this.dlgRemarks = true;
    },
    async createRemarks(objRemarks) {
      objRemarks["account"] = this.currentUser._id;
      objRemarks["avatar"] = this.currentUser.personal.profile || "";
      objRemarks["createTime"] = new Date();
      await this.editedItem.remarks.push(Object.assign({}, objRemarks));
      await this.editedItem.remarks.push({ divider: true, inset: true });
      let strUpdate = {};
      strUpdate.id = this.orderData._id;
      strUpdate.item = {};
      strUpdate.item["remarks"] = this.orderData.remarks.concat(
        this.editedItem.remarks
      );
      store
        .dispatch(EDIT_ORDER_PURCHASE, strUpdate)
        .then(() => {
          this.closeRemarks();
          this.$emit("Update")
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err })
        });
    },
    closeRemarks() {
      this.dlgRemarks = false;
      this.$nextTick(() => {
        this.editedItem = { files: [], remarks: [] }
        this.editedRemarks = Object.assign({}, this.defaultRemarks)
      });
    },
  },
}
</script>

<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
