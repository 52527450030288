<template>
  <div>
    <v-progress-circular
      :value="intCurrentStep"
      :size="80"
      :rotate="-90"
      color="secondary"
    >
      <v-icon x-large color="secondary">{{ objCurrentStep.step_icon }}</v-icon>
    </v-progress-circular>
    <div class="black--text mt-6">
      <p>{{ objCurrentStep.step_des}}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    phaseSteps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      objCurrentStep: {},
      intCurrentStep: 1,
    }
  },
  created(){
    this.setProgressValue()
  },
  methods: {
    setProgressValue() {
      this.objCurrentStep = this.phaseSteps.find(o => o.isCurrent)
      let intStep = parseInt(this.objCurrentStep.step_num) + 1
      this.intCurrentStep = parseInt(intStep/this.phaseSteps.length*100)
    },
  },
}
</script>
