<template>
  <v-card outlined>
    <v-list v-if="account" class="grey lighten-3" dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>姓名</v-list-item-title>
          <v-list-item-subtitle>{{account.personal.name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>电话</v-list-item-title>
          <v-list-item-subtitle>{{account.phone}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>电子邮件</v-list-item-title>
          <v-list-item-subtitle>{{account.username}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>地址</v-list-item-title>
          <v-list-item-subtitle>{{account.personal.address}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <widgets-profile-dialogs :account="account._id" v-on:UpdateEmployee="reloadAccount">
            <v-btn icon>
              <v-icon color="grey">read_more</v-icon>
            </v-btn>
          </widgets-profile-dialogs>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  created() {},
  computed: {},
  methods: {
    reloadAccount (data) {
      this.$emit('Update', data)
    }
  }
}
</script>
