<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{product.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('Close')"><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-carousel v-if="product.pictures && product.pictures.length" delimiter-icon="remove" show-arrows-on-hover height="250">
      <v-carousel-item
        v-for="(item,i) in product.pictures"
        :key="i+'_'+item"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img :src="ossURL+'/'+item" contain max-height="250"></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-card-text>
      <v-row>
        <v-col cols="2" class="text-right">产品名称</v-col>
        <v-col cols="4" class="text--secondary">{{product.name}}</v-col>
        <v-col cols="2" class="text-right">产品型号</v-col>
        <v-col cols="4" class="text--secondary">{{product.part_number}}</v-col>

        <v-col cols="2" class="text-right">品牌</v-col>
        <v-col cols="4" class="text--secondary" v-if="product.vendor">{{product.vendor.name}}</v-col>
        <v-col cols="2" class="text-right">产品类型</v-col>
        <v-col cols="4" class="text--secondary" v-if="product.product_type">{{product.product_type.name}}</v-col>

        <v-col cols="12" class="my-n2" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Asset')">
          <v-row>
            <v-col cols="2" class="text-right">成本</v-col>
            <v-col cols="4" class="text--secondary" v-if="product.cost_price">{{product.cost_price.$numberDecimal}}</v-col>
            <v-col cols="2" class="text-right">保修期</v-col>
            <v-col cols="4" class="text--secondary">{{product.guarantee}} 个月</v-col>
          </v-row>
        </v-col>

        <v-col cols="2" class="text-right">内存大小</v-col>
        <v-col cols="4" class="text--secondary" v-if="product.it_info">{{product.it_info.ram}} G</v-col>
        <v-col cols="2" class="text-right">网络制式</v-col>
        <v-col cols="4" class="text--secondary" v-if="product.it_info">{{product.it_info.network}}</v-col>

        <template v-for="(item, i) in customFields">
          <v-col :key="i+'_'+item.name" cols="2" class="text-right">{{item.name}}</v-col>
          <v-col :key="i+'_'+item.value" cols="4" class="text--secondary">{{item.value}}</v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Asset')">
<!--      <v-btn text @click="jump('product='+ product._id)">相关设备-{{product.asset_count}}</v-btn>-->
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close">关闭</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  FETCH_PRODUCT,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customFields: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'product']),
  },
  mounted() {
    this.getItem(this.id)
  },
  methods: {
    getItem(id) {
      this.$store.dispatch(FETCH_PRODUCT, id)
        .then((data) => {
          this.customFields = data.product_type.customFields
          this.customFields.forEach(field => {
            let beforeField = data.customFields.find(item => item._id === field._id)
            if (beforeField) field['value'] = beforeField.value
          })
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        })

    },
    close() {
      this.$emit('Close')
    },
    jump(query) {
      this.$emit('Goto', query)
      this.$router.push({path:'/dealer/asset/list?'+query })
    }
  },
}
</script>
