import { render, staticRenderFns } from "./Task_BAK.vue?vue&type=template&id=0ef628ec&scoped=true&"
import script from "./Task_BAK.vue?vue&type=script&lang=js&"
export * from "./Task_BAK.vue?vue&type=script&lang=js&"
import style0 from "./Task_BAK.vue?vue&type=style&index=0&id=0ef628ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef628ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VAutocomplete,VCol,VDivider,VForm,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VRow,VSubheader,VTextField})
