import router from '../router'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { CHECK_AUTH } from '../store/actions.type'
import { SET_ERROR, SET_AUTH } from "../store/mutations.type";

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  const hasToken = store.getters.isAuthenticated;

  if (to.matched.some(res => res.meta.login_required)) {
    if (hasToken) {
      store.dispatch(CHECK_AUTH).then(() => {
        next()
        NProgress.done()
      }).catch(err => {
        store.commit(SET_ERROR, { msg: '登陆错误：' + err.message, color: 'error' })
        NProgress.done()
      })
    } else {
      // 接收第三发传入的token
      const urlToken = to.query.token;
      if (urlToken) {
        store.commit(SET_AUTH, urlToken)
        next()
        NProgress.done()
      } else {
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    next()
    NProgress.done()
  }
})


router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
