<template>
  <v-card-title>
    {{caseData.group ? caseData.group.name +'-' : ''}}{{caseData.catalog ? caseData.catalog.name : ''}}: {{caseData.case_number}}
    <case-chip-status :caseData="caseData" :caseType="caseType"></case-chip-status>
    <v-spacer></v-spacer>
    <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
  </v-card-title>
</template>
<script>
import {} from "@/store/actions.type";
import {} from "@/store/mutations.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
    caseType: {
      type: String,
      default: 'service'
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  watch: {},
  created() {},
  methods: {
    closeDialog() {
      this.$emit('Close')
    }
  },
}
</script>
<style scoped>
</style>
