<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        过滤条件
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="12" v-for="field in arrCustomFields" :key="field._id">
                <v-text-field
                    @click:prepend="removeField({ text: field.name, code: field._id })"
                    prepend-icon="highlight_off"
                    class="my-1"
                    @change="queryAsset"
                    v-if="field.format.type === 'text' && selectFields.find(o => o.code === field._id)"
                    v-model="query[field._id]"
                    type="text"
                    :label="field.name"
                    hide-details
                    outlined
                    dense
                    clearable
                >
                </v-text-field>
                <v-chip-group v-if="field.format.type === 'selects' && selectFields.find(o => o.code === field._id)" v-model="query[field._id]" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: field.name, code: field._id })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">{{field.name}}</h2>
                  <v-chip v-for="item in field.format.items" :key="item" :value="item" filter label outlined>{{item}}</v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" md="12" v-for="selectfield in selectFields" :key="'asset_'+ selectfield.code">
                <v-autocomplete
                    v-if="selectfield.code === 'branch'"
                    class="my-1"
                    autocomplete="off"
                    @change="queryAsset"
                    v-model="query.branch"
                    :items="arrBranch"
                    label="请选择企业的分支机构"
                    @click:prepend="removeField({ text: '分支机构', code: 'branch' })"
                    prepend-icon="highlight_off"
                    hide-details
                    outlined
                    dense
                    clearable
                ></v-autocomplete>

                <v-autocomplete
                    v-if="selectfield.code === 'account'"
                    class="my-1"
                    @change="queryAsset"
                    v-model="query.account"
                    :items="queryAccountList"
                    :loading="queryAccountListLoading"
                    :search-input.sync="searchQueryAccount"
                    @click:prepend="removeField({ text: '使用人员', code: 'account' })"
                    prepend-icon="highlight_off"
                    prepend-inner-icon="search"
                    cache-items
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    label="请选择设备使用人员"
                    hide-details
                    clearable
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-chip-group v-if="stateList.length && selectfield.code === 'state'" v-model="query.state" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '设备状态', code: 'state' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">设备状态</h2>
                  <v-chip v-for="state in stateList" :key="state._id" :value="state._id" filter label outlined>{{state.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="selectfield.code === 'warranty_deadline'" v-model="query.warranty_deadline" column @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '保修到期', code: 'warranty_deadline' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">保修到期</h2>
                  <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="guaranteedType.length && selectfield.code === 'guaranteed_type'" v-model="query.guaranteed_type" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '延保类型', code: 'guaranteed_type' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">延保类型</h2>
                  <v-chip v-for="guaranteed_type in guaranteedType" :key="guaranteed_type.code" :value="guaranteed_type.code" filter label outlined>{{guaranteed_type.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="selectfield.code === 'guaranteed_deadline'" v-model="query.guaranteed_deadline" column @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '延保到期', code: 'guaranteed_deadline' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">延保到期</h2>
                  <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="selectfield.code === 'ownerType'" v-model="query.ownerType" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '选择归属', code: 'ownerType' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">选择归属</h2>
                  <v-chip value="Dealer" filter label outlined>经销商服务</v-chip>
                  <v-chip value="Enterprise" filter label outlined>企业自有</v-chip>
                </v-chip-group>

                <v-chip-group v-if="selectfield.code === 'updateTime'" v-model="query.updateTime" column @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '更新时间', code: 'updateTime' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">更新时间</h2>
                  <v-chip v-for="datetiem in updateTime" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="assetType.length && selectfield.code === 'asset_type'" v-model="query.asset_type" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '设备属性', code: 'asset_type' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">设备属性</h2>
                  <v-chip v-for="asset_type in assetType" :key="asset_type.code" :value="asset_type.code" filter label outlined>{{asset_type.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="warehouseList.length && selectfield.code === 'warehouse'" v-model="query.warehouse" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '所在仓库', code: 'warehouse' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">所在仓库</h2>
                  <v-chip v-for="warehouse in warehouseList" :key="warehouse._id" :value="warehouse._id" filter label outlined>{{warehouse.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="vendorList.length && selectfield.code === 'vendor'" v-model="query.vendor" column multiple @change="setProduct">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '产品品牌', code: 'vendor' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">产品品牌</h2>
                  <v-chip v-for="vendor in vendorList" :key="vendor._id" :value="vendor._id" filter label outlined>{{vendor.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="typeList.length && selectfield.code === 'productType'" v-model="query.productType" column multiple @change="setProduct">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '产品类型', code: 'productType' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">产品类型</h2>
                  <v-chip v-for="prd_type in typeList" :key="prd_type._id" :value="prd_type._id" filter label outlined>{{prd_type.name}}</v-chip>
                </v-chip-group>

                <v-chip-group v-if="productList.length && selectfield.code === 'product'" v-model="query.product" column multiple @change="queryAsset">
                  <v-btn icon width="24" height="24" class="my-2" @click="removeField({ text: '产品名称', code: 'product' })"><v-icon>highlight_off</v-icon></v-btn>
                  <h2 class="subtitle-2 mt-2 mx-2">产品名称</h2>
                  <v-chip v-for="product in productList" :key="product._id" :value="product._id" filter label outlined>{{product.name}}</v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-menu max-height="250px" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on"><v-icon left>add</v-icon>增加过滤字段</v-btn>
              </template>
              <v-list dense>
                <v-list-item
                    v-for="(item, index) in defaultFields"
                    :key="index"
                    link
                    @click="addField(item)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn text @click="saveCustomSearch" class="mx-2"><v-icon left>save</v-icon>保存过滤字段</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import store from "@/store";
import {CHECK_AUTH, EDIT_ACCOUNT, FETCH_EMPLOYEE_LIST, FETCH_PRODUCT_LIST} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    enterprise: {
      type: Object,
      required: true
    },
    stateList: {
      type: Array
    },
    warehouseList: {
      type: Array
    },
    typeList: {
      type: Array
    },
    vendorList: {
      type: Array
    },
    productList: {
      type: Array
    }
  },
  data() {
    return {
      query: {
        enterprise: this.account.employer.ownerId._id,
      },
      queryAccountList: [],
      queryAccountListLoading: false,
      searchQueryAccount: null,
      assetFieldList: [],
      defaultFields: [
        { text: '分支机构', code: 'branch' },
        { text: '使用人员', code: 'account' },
        { text: '设备状态', code: 'state' },
        { text: '保修到期', code: 'warranty_deadline' },
        { text: '延保类型', code: 'guaranteed_type' },
        { text: '延保到期', code: 'guaranteed_deadline' },
        { text: '选择归属', code: 'ownerType' },
        { text: '更新时间', code: 'updateTime' },
        { text: '设备属性', code: 'asset_type' },
        { text: '所在仓库', code: 'warehouse' },
        { text: '产品品牌', code: 'vendor' },
        { text: '产品类型', code: 'productType' },
        { text: '产品名称', code: 'product' }
      ],
      selectFields: []
    }
  },
  computed: {
    arrBranch() {
      if (this.account.employer.ownerId && this.account.employer.ownerId.branch) {
        return [...this.account.employer.ownerId.branch]
      } else {
        return []
      }
    },
    arrCustomFields() {
      if (this.enterprise && this.enterprise.asset_field) {
        return [...this.enterprise.asset_field]
      } else {
        return []
      }
    },
    assetType() {
      return Util.categories("assetType");
    },
    expiryDate() {
      return Util.categories("expiry");
    },
    guaranteedType() {
      return Util.categories("guaranteedType");
    },
    updateTime() {
      return Util.categories("updateTime");
    }
  },
  watch: {
    searchQueryAccount(val) {
      this.queryAccountListLoading = true
      setTimeout(() => {
        store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=50&enterprise='+ this.query.enterprise + '&key='+ val)
            .then((data) => {
              if (data.employees) this.queryAccountList = [...data.employees];
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            })
            .finally(() => (this.queryAccountListLoading = false))
      }, 500)
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.arrCustomFields && this.arrCustomFields.length) {
        this.defaultFields.push( ...this.arrCustomFields.map(field => {
          return {
            text: field.name,
            code: field._id
          }
        }))
      }
      if (this.account.assetSearch && this.account.assetSearch.content) {
        this.defaultFields = this.defaultFields.filter(o => !this.account.assetSearch.content.find(i => i.code === o.code))
        this.selectFields = [...this.account.assetSearch.content]
      }
    },
    queryAsset() {
      this.$emit('change', this.query)
    },
    setProduct() {
      let strQuery = "isEnable=true&limit=100";
      if (this.query.vendor) strQuery += "&vendor=" + this.query.vendor;
      if (this.query.productType) strQuery += "&type=" + this.query.productType;
      store.dispatch(FETCH_PRODUCT_LIST, strQuery);
      this.queryAsset()
    },
    removeField(field) {
      delete this.query[field.code]
      this.selectFields.splice(this.selectFields.findIndex(o => o.code === field.code), 1)
      this.defaultFields.push(field)
      this.queryAsset()
    },
    addField(field) {
      this.defaultFields.splice(this.defaultFields.findIndex(o => o.code === field.code), 1)
      this.selectFields.push(field);
    },
    saveCustomSearch() {
      let strUpdate = {}
      strUpdate['assetSearch'] = {}
      strUpdate['assetSearch'].version = 0
      strUpdate['assetSearch'].content = this.selectFields
      store.dispatch(EDIT_ACCOUNT, strUpdate)
          .then(() => {
            store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
            store.dispatch(CHECK_AUTH)
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
    }
  }
}
</script>