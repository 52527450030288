var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editedItem[_vm.assetKey])?_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headerAsset,"items":_vm.editedItem[_vm.assetKey],"server-items-length":_vm.editedItem[_vm.assetKey].length,"loading":_vm.loadingList,"footer-props":{'items-per-page-options': [5, 10, 15]},"item-key":"_id","return-object":"false","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"v-list-item--link text-truncate",staticStyle:{"max-width":"150px"},attrs:{"nowrap":"true"}},[_c('widgets-asset-dialogs',{attrs:{"getId":item._id,"caseType":_vm.caseType}},[_c('div',[(item.isLock)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("lock")])]}}],null,true)},[_c('span',[_vm._v("设备锁定中")])]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" "),(item.ownerType === 'Enterprise')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("corporate_fare")])]}}],null,true)},[_c('span',[_vm._v("企业自有设备")])]):(item.ownerType === 'Account')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("person")])]}}],null,true)},[_c('span',[_vm._v("个人自有设备")])]):_vm._e()],1)])],1)]}},{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [(item.product && item.product.product_type && item.product.product_type.noSN)?_c('td',{attrs:{"nowrap":"true"}},[_vm._v("无序列号")]):_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.serial_number))])]}},{key:"item.product.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"v-list-item--link text-truncate",staticStyle:{"max-width":"150px"},attrs:{"nowrap":"true"},on:{"click":function($event){return _vm.openDialog('Product', item.product._id)}}},[_vm._v(_vm._s(item.product.name))])]}},{key:"item.product.part_number",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.product.part_number))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.state ? item.state.name : item.state))])]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"v-list-item--link text-truncate",staticStyle:{"max-width":"150px"},attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.branch))])]}},{key:"item.asset_state",fn:function(ref){
var item = ref.item;
return [(item.asset_state && Array.isArray(item.asset_state))?_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.asset_state.map(function (state) { return _vm.assetState.find(function (o) { return o.code === state; }).name; })))]):_vm._e()]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.label))])]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.account ? item.account.personal.name : ''))])]}},{key:"item.transport.delivery_user",fn:function(ref){
var item = ref.item;
return [(item.transport && item.transport.delivery_user)?_c('td',{attrs:{"nowrap":"true"}},[_vm._v(" "+_vm._s(item.transport.delivery_user)+" ")]):_vm._e()]}},{key:"item.transport.delivery_phone",fn:function(ref){
var item = ref.item;
return [(item.transport && item.transport.delivery_phone)?_c('td',{attrs:{"nowrap":"true"}},[_vm._v(" "+_vm._s(item.transport.delivery_phone)+" ")]):_vm._e()]}},{key:"item.transport.delivery_address",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.transport && item.transport.delivery_address)?_c('td',{class:!_vm.open[index]?'text-truncate text-decoration-underline':'text-decoration-underline',staticStyle:{"max-width":"150px","cursor":"pointer"},attrs:{"nowrap":_vm.open[index]?false:true},on:{"click":function($event){return _vm.showText(index)}}},[_vm._v(" "+_vm._s(item.transport.delivery_address)+" ")]):_vm._e()]}},{key:"item.transport.express_number",fn:function(ref){
var item = ref.item;
return [(item.transport && item.transport.express_number)?_c('td',{attrs:{"nowrap":"true"}},[_c('case-express-info',{attrs:{"id":item.transport._id,"from":"asset"}},[_vm._v(_vm._s(item.transport.express_number))])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.unlinkAsset(item)}}},[_vm._v("remove")])]}}],null,false,3071668091)})],1),_c('v-col',{attrs:{"cols":"12","align":"end"}},[(_vm.caseData.status === 0 && _vm.caseData.isEdit)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.linkAsset()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("link")]),_vm._v("关联设备")],1):(_vm.caseData.status === 1 && _vm.caseData.isEdit && _vm.currentTask.task && _vm.currentTask.task.powers.includes('relate_asset'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.linkAsset()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("link")]),_vm._v("关联设备")],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dlgActive),callback:function ($$v) {_vm.dlgActive=$$v},expression:"dlgActive"}},[(_vm.activeId)?_c('widgets-product-dialogs',{key:_vm.activeId,attrs:{"id":_vm.activeId},on:{"Close":function($event){return _vm.closeActive()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"750px"},model:{value:(_vm.dlgAsset),callback:function ($$v) {_vm.dlgAsset=$$v},expression:"dlgAsset"}},[(_vm.caseData.enterprise)?_c('widgets-select-asset',{key:"linkAsset",ref:"select_asset",attrs:{"enterprise":_vm.caseData.enterprise._id,"isInWarehouse":_vm.isWareHouse,"maxLimit":_vm.maxLimit,"selectItem":_vm.editedItem[_vm.assetKey]},on:{"Close":_vm.closeAsset,"Selected":_vm.setAssetList}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }